import { RecurrenceEnum } from '@tyro/api';
import type { ReturnTypeDisplayName } from '@tyro/core';
import dayjs from 'dayjs';
import type { ReturnTypeFromUseEventForEdit } from '../api/events-for-edit';
import { getPartyAvatarUrl, getPartyName } from './get-party-name';
import { sortParticipants } from './sort-participants';

export function getEventToEdit(
  event: ReturnTypeFromUseEventForEdit,
  displayName: ReturnTypeDisplayName,
) {
  const {
    type,
    eventId,
    calendarIds,
    name,
    description,
    colour,
    schedule,
    rooms,
    attendees,
  } = event;

  const {
    allDayEvent,
    startDate,
    startTime,
    endDate,
    endTime,
    recurrenceRule,
    recurrenceEnum,
  } = schedule;

  return {
    type,
    calendarId: calendarIds[0],
    eventId,
    name,
    description,
    colour,
    allDayEvent,
    startDate: dayjs(startDate),
    startTime: dayjs(`${startDate}T${startTime}`, 'YYYY-MM-DDTHH:mm'),
    endDate: endDate ? dayjs(endDate) : undefined,
    endTime: dayjs(`${startDate}T${endTime}`, 'YYYY-MM-DDTHH:mm'),
    recurrenceRule,
    recurrenceEnum: recurrenceEnum || RecurrenceEnum.NoRecurrence,
    locations: rooms,
    participants: sortParticipants(attendees).map(
      ({ partyId, partyInfo, type }) => ({
        partyId,
        attendeeType: type,
        avatarUrl: getPartyAvatarUrl(partyInfo),
        text: getPartyName(partyInfo, displayName),
      }),
    ),
  };
}
