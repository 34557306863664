import {
  type BoxProps,
  Card,
  type CardProps,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import {
  type Range,
  defaultRangeExtractor,
  useVirtualizer,
} from '@tanstack/react-virtual';
import { type Timeline_TimelineEventId, useAcademicNamespace } from '@tyro/api';
import {
  ActionMenu,
  LoadingPlaceholderContainer,
  useDebouncedValue,
  useExportReactToPdf,
} from '@tyro/core';
import { PrinterIcon } from '@tyro/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import groupByFunc from 'lodash/groupBy';
import type React from 'react';
import {
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure } from 'react-use';
import { TimelineItem, type TimelineItemProps } from './item';
import { TimelinePreviewer, type TimelinePreviewerProps } from './previewer';
import type { TimelinePreviewerHighlightCardContentProps } from './previewer/highlight-card';
import {
  type SectionHeaderProps,
  TimelineSectionHeader,
} from './section-header';
import { TimelineContainer } from './timeline-container';
import { getTimelineForPrinting } from './utils';

dayjs.extend(isBetween);

export * from './type-filter';
export * from './timeline-highlight-icon';
export { timelineIconMap } from './item-icon';
export interface TimelineProps {
  events: TimelineItemProps['event'][];
  groupBy?: SectionHeaderProps['groupBy'];
  loading?: boolean;
  paginationSettings?: {
    totalEvents: number;
    firstEventId: Timeline_TimelineEventId;
    lastEventId: Timeline_TimelineEventId;
    onLoadNextPage?: () => void;
    onLoadPreviousPage?: () => void;
  };
  sidePanelTabs?: {
    key: string;
    label: string;
    icon: React.ReactElement;
    tab: React.ReactElement;
  }[];
  activeHighlight?: TimelinePreviewerHighlightCardContentProps['data'];
  unsetActiveHighlight?: () => void;
  sortOrder?: 'asc' | 'desc';
  containerProps?: CardProps;
  timelineContainerProps?: BoxProps;
  showGroupLabels?: boolean;
  showActions?: boolean;
}

export function Timeline({
  events,
  groupBy = 'week',
  loading,
  paginationSettings,
  sidePanelTabs,
  activeHighlight,
  unsetActiveHighlight,
  sortOrder = 'asc',
  containerProps,
  timelineContainerProps,
  showGroupLabels = true,
  showActions = true,
}: TimelineProps) {
  const parentRef = useRef<HTMLDivElement>(null);
  const activeStickyIndexRef = useRef(0);
  const { t } = useTranslation(['common']);
  const [selectedSidePanelTab, setSelectedSidePanelTab] = useState(0);
  const [keyboardFocusedIndex, setKeyboardFocusedIndex] = useState<
    number | null
  >(null);
  const [cardRef, { width: cardWidth }] = useMeasure<HTMLDivElement>();
  const { exportReactToPdf } = useExportReactToPdf();

  const { allNamespaces } = useAcademicNamespace();
  const {
    value: previewerElements,
    debouncedValue: debouncedPreviewerElements,
    setValue: _setPreviewerElementsState,
  } = useDebouncedValue<TimelinePreviewerProps['data']>({
    defaultValue: [],
  });

  const eventIdsInPreviewer = useMemo(
    () =>
      new Set(
        previewerElements
          .filter(({ type }) => type === 'event')
          .map(({ data }) => JSON.stringify(data.id)),
      ),
    [previewerElements],
  );

  const rows = useMemo(() => {
    const sortConfig =
      sortOrder === 'asc'
        ? {
            before: -1,
            after: 1,
          }
        : {
            before: 1,
            after: -1,
          };
    const sortedEvents = events.sort((a, b) => {
      const dateA = dayjs(a.startDateTime ?? a.date);
      const dateB = dayjs(b.startDateTime ?? b.date);

      if (dateA.isSame(dateB, 'day')) {
        if (a.startDateTime && b.startDateTime) {
          return dayjs(a.startDateTime).isBefore(dayjs(b.startDateTime))
            ? sortConfig.before
            : sortConfig.after;
        }

        if (a.startDateTime) {
          return sortConfig.before;
        }

        if (b.startDateTime) {
          return sortConfig.after;
        }

        return 0;
      }

      return dateA.isBefore(dateB) ? sortConfig.before : sortConfig.after;
    });

    const groupedEvents = groupByFunc(sortedEvents, (event) => {
      return dayjs(event.startDateTime ?? event.date)
        .startOf(groupBy)
        .format('YYYY-MM-DD');
    });

    const groupedKeys = Object.keys(groupedEvents);

    if (showGroupLabels && allNamespaces) {
      const shownYearDates = allNamespaces
        .filter(({ startDate, endDate }) =>
          groupedKeys.some((key) =>
            dayjs(key).isBetween(startDate, endDate, null, '[]'),
          ),
        )
        .flatMap(({ startDate, endDate }) => [
          dayjs(startDate).startOf(groupBy),
          dayjs(endDate).startOf(groupBy),
        ]);

      for (const date of shownYearDates) {
        if (!groupedKeys.includes(date.format('YYYY-MM-DD'))) {
          groupedKeys.push(date.format('YYYY-MM-DD'));
        }
      }
    }

    return groupedKeys
      .sort((a, b) =>
        dayjs(a).isBefore(dayjs(b)) ? sortConfig.before : sortConfig.after,
      )
      .reduce<Array<string | TimelineProps['events'][number]>>((acc, key) => {
        const value = groupedEvents[key] ?? [];
        showGroupLabels ? acc.push(key, ...value) : acc.push(...value);
        return acc;
      }, []);
  }, [events, groupBy, sortOrder, allNamespaces, showGroupLabels]);

  const stickyIndexes = useMemo(() => {
    return rows.reduce<number[]>((acc, row, index) => {
      if (typeof row === 'string') {
        acc.push(index);
      }
      return acc;
    }, []);
  }, [rows]);

  const count = paginationSettings?.totalEvents ?? rows.length;
  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 68,
    paddingStart: 16,
    paddingEnd: 16,
    gap: 16,
    overscan: 10,
    rangeExtractor: useCallback(
      (range: Range) => {
        activeStickyIndexRef.current =
          [...stickyIndexes]
            .reverse()
            .find((index) => range.startIndex >= index) ?? 0;

        const next = new Set([
          activeStickyIndexRef.current,
          ...defaultRangeExtractor(range),
        ]);

        return [...next].sort((a, b) => a - b);
      },
      [stickyIndexes],
    ),
  });

  const setPreviewerElements = useCallback(
    (action: SetStateAction<TimelinePreviewerProps['data']>) => {
      return _setPreviewerElementsState((previousData) => {
        const data =
          typeof action === 'function' ? action(previousData) : action;
        if (data.length === 0 && previousData.length > 0) {
          const topElement = previousData[0];

          if (topElement.type === 'event') {
            const topElementIndex = rows.findIndex(
              (event) =>
                typeof event !== 'string' &&
                JSON.stringify(event.id) === JSON.stringify(topElement.data.id),
            );
            setKeyboardFocusedIndex(topElementIndex);
          }
        }
        return data;
      });
    },
    [_setPreviewerElementsState, rows],
  );

  const handleCardClick = useCallback(
    (row: (typeof events)[number]) => {
      if (eventIdsInPreviewer.has(JSON.stringify(row.id))) {
        setPreviewerElements((previousEvents) =>
          previousEvents.filter(
            (event) => JSON.stringify(event.data.id) !== JSON.stringify(row.id),
          ),
        );
      } else {
        setPreviewerElements((previousEvents) => [
          { type: 'event', data: row },
          ...previousEvents,
        ]);
      }
    },
    [setPreviewerElements, eventIdsInPreviewer],
  );

  const handleCardKeyPress = useCallback(
    (
      event: React.KeyboardEvent<HTMLDivElement>,
      key: number,
      row: (typeof events)[number],
    ) => {
      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          const nextRowIndex = rows
            .slice(key + 1)
            .findIndex((event) => typeof event !== 'string');
          if (nextRowIndex !== -1) {
            setKeyboardFocusedIndex(nextRowIndex + key + 1);
          }
          break;
        }
        case 'ArrowUp': {
          event.preventDefault();
          const previousRowIndex = rows
            .slice(0, key)
            .reverse()
            .findIndex((event) => typeof event !== 'string');
          if (previousRowIndex !== -1) {
            setKeyboardFocusedIndex(key - previousRowIndex - 1);
          }

          break;
        }
        case 'Home': {
          event.preventDefault();
          const nextRowIndex = rows.findIndex(
            (event) => typeof event !== 'string',
          );
          if (nextRowIndex !== -1) {
            setKeyboardFocusedIndex(key - nextRowIndex - 1);
          }
          break;
        }
        case 'End': {
          event.preventDefault();
          const previousRowIndex = rows
            .reverse()
            .findIndex((event) => typeof event !== 'string');
          if (previousRowIndex !== -1) {
            setKeyboardFocusedIndex(rows.length - previousRowIndex - 1);
          }
          break;
        }
        case 'Enter': {
          event.preventDefault();
          handleCardClick(row);
          break;
        }
      }
    },
    [rowVirtualizer, handleCardClick],
  );

  useEffect(() => {
    const focusFunction = () => {
      const firstKey = rowVirtualizer
        .getVirtualItems()
        .find((virtualRow) => typeof rows[virtualRow.index] !== 'string')?.key;
      if (!firstKey) return;
      setKeyboardFocusedIndex(firstKey as number);
    };

    parentRef.current?.addEventListener('focus', focusFunction);

    return () => {
      parentRef.current?.removeEventListener('focus', focusFunction);
    };
  }, [rowVirtualizer]);

  useEffect(() => {
    if (keyboardFocusedIndex !== null) {
      rowVirtualizer.scrollToIndex(keyboardFocusedIndex, { align: 'center' });
    }
  }, [keyboardFocusedIndex]);

  useEffect(() => {
    if (activeHighlight) {
      setPreviewerElements((previousEvents) => [
        { type: 'highlight', data: activeHighlight },
        ...previousEvents.filter(
          (event) =>
            event.type !== 'highlight' || event.data.id !== activeHighlight.id,
        ),
      ]);
      const highlightsTabIndex =
        sidePanelTabs?.findIndex((tab) => tab.key === 'highlights') ?? -1;

      if (highlightsTabIndex !== -1) {
        setSelectedSidePanelTab(highlightsTabIndex);
      }
    }
  }, [activeHighlight]);

  return (
    <>
      <Card
        {...containerProps}
        ref={cardRef}
        sx={{
          flex: 1,
          display: 'flex',
          position: 'relative',
          ...containerProps?.sx,
        }}
      >
        {sidePanelTabs && sidePanelTabs.length > 0 && cardWidth >= 700 && (
          <Stack
            sx={{
              width: 260,
              borderRight: '1px solid',
              borderRightColor: 'slate.100',
              backgroundColor: 'white',
            }}
          >
            <Tabs
              value={selectedSidePanelTab}
              onChange={(_, value) => setSelectedSidePanelTab(value)}
              aria-label={t('common:timelineSidePanelTabs')}
              sx={{
                px: 1.5,
                borderBottom: '1px solid',
                borderBottomColor: 'slate.100',
                '& button': {
                  fontSize: '0.75rem',

                  '& svg': {
                    fontSize: '1.125rem',
                    mr: '4px !important',
                  },

                  '&:not(:last-of-type)': {
                    mr: '18px !important',
                  },
                },
              }}
            >
              {sidePanelTabs.map((tab) => (
                <Tab key={tab.label} icon={tab.icon} label={tab.label} />
              ))}
            </Tabs>
            <Stack sx={{ flex: 1 }}>
              {sidePanelTabs[selectedSidePanelTab].tab}
            </Stack>
          </Stack>
        )}
        <TimelineContainer
          {...timelineContainerProps}
          ref={parentRef}
          tabIndex={0}
          role="grid"
          aria-rowcount={count}
          showActions={showActions}
          cardWidth={cardWidth}
        >
          <LoadingPlaceholderContainer isLoading={!!loading}>
            {count > 0 ? (
              <ul
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`,
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  padding: '16px 0',
                  marginTop: 0,
                }}
              >
                {showActions && (
                  <div className="top-item-mask" role="presentation" />
                )}
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const row = rows[virtualRow.index];
                  const isActiveSticky =
                    virtualRow.index === activeStickyIndexRef.current;
                  const style =
                    isActiveSticky && showGroupLabels
                      ? {
                          position: 'sticky' as const,
                        }
                      : { transform: `translateY(${virtualRow.start}px)` };

                  if (typeof row === 'string') {
                    return (
                      <TimelineSectionHeader
                        key={virtualRow.key}
                        data-index={virtualRow.index}
                        ref={rowVirtualizer.measureElement}
                        groupBy={groupBy}
                        headerValue={row}
                        style={style}
                      />
                    );
                  }

                  return (
                    <TimelineItem
                      key={virtualRow.key}
                      id={`timeline-item-${virtualRow.key}`}
                      data-index={virtualRow.index}
                      className={
                        eventIdsInPreviewer.has(JSON.stringify(row.id))
                          ? 'open-in-preview'
                          : ''
                      }
                      ref={(ref) => {
                        rowVirtualizer.measureElement(ref);
                        if (keyboardFocusedIndex === virtualRow.key) {
                          ref?.focus();
                        }
                      }}
                      aria-rowindex={virtualRow.key as number}
                      event={row}
                      showGroupLabels={showGroupLabels}
                      groupBy={groupBy}
                      style={style}
                      role="row"
                      tabIndex={
                        keyboardFocusedIndex === (virtualRow.key as number)
                          ? 0
                          : -1
                      }
                      onClick={() => handleCardClick(row)}
                      onMouseOver={() => {
                        setKeyboardFocusedIndex(null);
                      }}
                      onKeyDown={(event) =>
                        handleCardKeyPress(event, virtualRow.key as number, row)
                      }
                      itemHeight={
                        rowVirtualizer.measurementsCache[virtualRow.index].size
                      }
                    />
                  );
                })}
                <div className="timeline" role="presentation">
                  <div className="inner-timeline" />
                </div>
              </ul>
            ) : (
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {t('common:noEventsFound')}
              </Typography>
            )}
          </LoadingPlaceholderContainer>
        </TimelineContainer>
        {showActions && count > 0 && (
          <ActionMenu
            menuItems={[
              {
                label: t('common:actions.print'),
                onClick: () => {
                  exportReactToPdf(() =>
                    getTimelineForPrinting(rows, rowVirtualizer, groupBy),
                  );
                },
                icon: <PrinterIcon />,
              },
            ]}
            buttonProps={{
              size: 'small',
              sx: {
                position: 'absolute',
                top: 16,
                right: 16,
              },
            }}
          />
        )}
      </Card>
      <TimelinePreviewer
        open={previewerElements.length > 0}
        onClose={() => {
          setPreviewerElements([]);
          unsetActiveHighlight?.();
        }}
        data={previewerElements ?? debouncedPreviewerElements}
        onOpenEvent={(event) => handleCardClick(event)}
        closeCardByIndex={(index) =>
          setPreviewerElements((previousEvents) => {
            const element = previousEvents[index];
            if (
              element.type === 'highlight' &&
              element.data.id === activeHighlight?.id
            ) {
              unsetActiveHighlight?.();
            }
            return previousEvents.filter((_, i) => i !== index);
          })
        }
      />
    </>
  );
}
