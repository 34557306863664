import { Divider, Menu, type MenuProps } from '@mui/material';
import { usePermissions } from '@tyro/api';
import { type MenuItemConfig, getMenuItemList } from './menu-item-list';

export interface ContextMenuProps {
  id?: string;
  buttonRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
  onClose: () => void;
  menuProps?: Partial<MenuProps>;
  menuItems: MenuItemConfig[] | MenuItemConfig[][];
}

export function ContextMenu({
  id,
  buttonRef,
  isOpen,
  onClose,
  menuProps,
  menuItems,
}: ContextMenuProps) {
  const permissions = usePermissions();
  const sectionedMenuItems = (
    Array.isArray(menuItems[0]) ? menuItems : [menuItems]
  ) as MenuItemConfig[][];

  return (
    <Menu
      anchorEl={buttonRef.current}
      id={id}
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...menuProps}
      onClose={(...args) => {
        if (args[1] === 'backdropClick') {
          const event = args[0] as React.MouseEvent;
          event.stopPropagation();
          event.preventDefault();
        }
        onClose();
        menuProps?.onClose?.(...args);
      }}
      sx={{ ml: 1, ...menuProps?.sx }}
    >
      {sectionedMenuItems.reduce<React.ReactNode[]>((acc, section, index) => {
        const sectionItems = getMenuItemList({
          menuItems: section,
          permissions,
          onClose,
        });

        if (acc.length > 0 && sectionItems.length > 0) {
          // biome-ignore lint/suspicious/noArrayIndexKey: Divider is not a list item
          acc.push(<Divider key={`divider-${index}`} />);
        }

        if (sectionItems.length > 0) {
          acc.push(...sectionItems);
        }

        return acc;
      }, [])}
    </Menu>
  );
}
