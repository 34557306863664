export function extractDocumentCSS(): string {
  let css = '';
  const styleSheets = document.styleSheets;

  for (const sheet of Array.from(styleSheets)) {
    try {
      const rules = sheet.cssRules || sheet.rules;
      for (const rule of Array.from(rules)) {
        css += `${rule.cssText}\n`;
      }
    } catch (e) {
      // Will fail on some external stylesheets and can ignore
    }
  }

  return css;
}
