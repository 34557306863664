import { useQuery } from '@tanstack/react-query';

import {
  type TtSolve_ClassDataFilter,
  type Ttsolve_ClassDataViewQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { naturalSort } from '@tyro/core';
import { useCallback } from 'react';
import { timetableKeys } from '../../keys';

const viewClassData = graphql(/* GraphQL */ `
  query ttsolve_classDataView($filter: TtSolve_ClassDataFilter!) {
    ttsolve_classDataView(filter: $filter) {
      classGroups {
        classGroup {
          ttClassGroupId
          partyId
          name
          code
          groupTags {
            id
            name
          }
        }
        uiBlockLessonCountOnColumn
        uiCoreLessonCountOnColumn
        uiTotalLessonCountOnColumn
      }
      blocks {
        block {
          blockId
          name
          shortName
          classGroups {
            ttClassGroupId
            name
          }
        }
        timetableGroups {
          classGroupId
          blockGroup {
            groups {
              timetableGroupId
              name
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
                displayCode
              }
              allowAutoTeacherSwaps
              subject {
                id
                name
                colour
              }
              groupType
              studentMembershipType
              groupTagIds
              spread
              lessonCount
              blockIdx
              blockId
              classGroupId
              room {
                roomId
                name
              }
              roomPool {
                id
                name
              }
              lessons {
                id {
                  lessonIdx
                  lessonInstanceIdx
                  timetableGroupId
                }
                timeslotId {
                  gridIdx
                  dayIdx
                  periodIdx
                }
                timeslotInfo {
                  dayOfWeek
                  name
                  startTime
                  endTime
                }
                teachers {
                  person {
                    partyId
                    title {
                      id
                      nameTextId
                      name
                    }
                    firstName
                    lastName
                    avatarUrl
                    type
                    archived
                  }
                }
                room {
                  roomId
                  name
                }
                roomPool {
                  id
                  name
                }
                spread
              }
              maxClassSize
              coScheduleTtGroup {
                timetableGroupId
                name
              }
            }
            isStack
            classGroupIsPresentInBlock
          }
        }
        spillOverTimetableGroups {
          groups {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            allowAutoTeacherSwaps
            subject {
              id
              name
              colour
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                dayOfWeek
                name
                startTime
                endTime
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
          isStack
        }
        supportGroups {
          groups {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            allowAutoTeacherSwaps
            subject {
              id
              name
              colour
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                dayOfWeek
                name
                startTime
                endTime
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
          isStack
        }
        coScheduleGroups {
          groups {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            allowAutoTeacherSwaps
            subject {
              id
              name
              colour
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                dayOfWeek
                name
                startTime
                endTime
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
          isStack
        }
      }
      core {
        subjectId
        subject {
          id
          name
          colour
          nationalCode
        }
        timetableGroups {
          classGroupId
          timetableGroups {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            allowAutoTeacherSwaps
            subject {
              id
              name
              colour
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                dayOfWeek
                name
                startTime
                endTime
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
      }
    }
  }
`);

const viewClassDataQuery = (filter: TtSolve_ClassDataFilter) => ({
  queryKey: timetableKeys.viewClassData(filter),
  queryFn: async () => gqlClient.request(viewClassData, { filter }),
});

export async function getViewClassData(filter: TtSolve_ClassDataFilter) {
  return queryClient.fetchQuery(viewClassDataQuery(filter));
}

export function useViewClassData(filter: TtSolve_ClassDataFilter) {
  return useQuery({
    ...viewClassDataQuery(filter),
    select: useCallback(
      ({ ttsolve_classDataView }: Ttsolve_ClassDataViewQuery) => ({
        ...ttsolve_classDataView,
        blocks: ttsolve_classDataView.blocks.sort((a, b) =>
          naturalSort(a.block.shortName, b.block.shortName),
        ),
      }),
      [JSON.stringify(filter)],
    ),
    enabled: !!filter.timetableId && !!filter.groupTags.length,
  });
}

export type ReturnTypeFromUseViewClassData = UseQueryReturnType<
  typeof useViewClassData
>;
