import { useQuery } from '@tanstack/react-query';

import {
  type QueryAdditionalOptions,
  type Tt_TeacherAssignmentFilter,
  type Tt_TeacherAssignmentsQuery,
  type Tt_TeacherAssignmentsStatsQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { naturalSort } from '@tyro/core';
import { useCallback } from 'react';
import { timetableKeys } from '../../keys';

const teacherAssignmentStats = graphql(/* GraphQL */ `
  query tt_teacherAssignmentsStats($filter: Tt_TeacherAssignmentFilter!) {
    tt_teacherAssignments(filter: $filter) {
      assignments {
        staffId
        maxLoadMinutes
        currentLoadMinutes
        lessonsAssigned
      }
    }
  }
`);

const teacherAssignments = graphql(/* GraphQL */ `
  query tt_teacherAssignments($filter: Tt_TeacherAssignmentFilter!) {
    tt_teacherAssignments(filter: $filter) {
      solverStatus {
        solverStatus
        score
      }
      stats {
        teacherCountTotal
        teachersOverAllocatedCount
        teachersFullyAllocatedCount
        teachersUnderAllocatedCount
        mainstreamCount
        mainstreamTeachersAssignedCount
        mainstreamTeachersUnassignedCount
        freeformCount
        freeformTeachersAssignedCount
        freeformTeachersUnassignedCount
      }
      assignments {
        staffId
        staff {
          person {
            partyId
            title {
              id
              nameTextId
              name
            }
            firstName
            lastName
            avatarUrl
            type
          }
          displayCode
        }
        maxLoadMinutes
        currentLoadMinutes
        lessonsAssigned
        mainstream {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        freeform {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        nonTeaching {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
      }
      unassigned {
        maxLoadMinutes
        currentLoadMinutes
        lessonsAssigned
        mainstream {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              timeslotInfo {
                dayOfWeek
                startTime
                endTime
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        freeform {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        nonTeaching {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
      }
    }
  }
`);

const teacherAssignmentStatsQuery = (filter: Tt_TeacherAssignmentFilter) => ({
  queryKey: timetableKeys.teacherAssignmentStats(filter),
  queryFn: async () => gqlClient.request(teacherAssignmentStats, { filter }),
});

export function useTeacherAssignmentStats(filter: Tt_TeacherAssignmentFilter) {
  return useQuery({
    ...teacherAssignmentStatsQuery(filter),
    select: useCallback(
      ({ tt_teacherAssignments }: Tt_TeacherAssignmentsStatsQuery) =>
        new Map(
          tt_teacherAssignments.assignments.map((assignment) => [
            assignment.staffId,
            assignment,
          ]),
        ),
      [],
    ),
  });
}

const teacherAssignmentsQuery = (filter: Tt_TeacherAssignmentFilter) => ({
  queryKey: timetableKeys.teacherAssignments(filter),
  queryFn: async () => gqlClient.request(teacherAssignments, { filter }),
});

export async function getTeacherAssignments(
  filter: Tt_TeacherAssignmentFilter,
) {
  return queryClient.fetchQuery(teacherAssignmentsQuery(filter));
}

export function useTeacherAssignments(
  filter: Tt_TeacherAssignmentFilter,
  { enabled = true }: Pick<QueryAdditionalOptions, 'enabled'> = {},
) {
  return useQuery({
    ...teacherAssignmentsQuery(filter),
    enabled,
    select: useCallback(
      ({ tt_teacherAssignments }: Tt_TeacherAssignmentsQuery) => ({
        ...tt_teacherAssignments,
        assignments: tt_teacherAssignments.assignments.map((assignment) => ({
          ...assignment,
          mainstream: assignment.mainstream.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          freeform: assignment.freeform.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          nonTeaching: assignment.nonTeaching.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
        })),
        unassigned: {
          ...tt_teacherAssignments.unassigned,
          mainstream: tt_teacherAssignments.unassigned.mainstream.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          freeform: tt_teacherAssignments.unassigned.freeform.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          nonTeaching: tt_teacherAssignments.unassigned.nonTeaching.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
        },
      }),
      [],
    ),
  });
}

export type ReturnTypeFromUseTeacherAssignments = UseQueryReturnType<
  typeof useTeacherAssignments
>;
