import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  type TtResourceTimetableViewFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { timetableKeys } from '../keys';

const timetableResourceView = graphql(/* GraphQL */ `
  query tt_resourceTimetableView($filter: TTResourceTimetableViewFilter!) {
    tt_resourceTimetableView(filter: $filter) {
      timeslots {
        timeslotIds {
          gridIdx
          dayIdx
          periodIdx
        }
        timeslots {
          dayOfWeek
          startTime
          endTime
          periodType
          name
        }
        lessons {
          id {
            lessonIdx
            lessonInstanceIdx
            timetableGroupId
          }
          timeslotId {
            gridIdx
            dayIdx
            periodIdx
          }
          timeslotInfo {
            startTime
            endTime
            dayOfWeek
            name
          }
          timetableGroup {
            __typename
            timetableGroupId
            name
            subject {
              name
              colour
            }
            groupTag {
              id
              type
              name
            }
          }
          room {
            roomId
            name
          }
          teachers {
            person {
              partyId
              title {
                id
                name
                nameTextId
              }
              firstName
              lastName
              avatarUrl
              type
            }
          }
          spread
        }
      }
    }
  }
`);

const timetableResourceViewQuery = (filter: TtResourceTimetableViewFilter) => ({
  queryKey: timetableKeys.resourceView(filter),
  queryFn: () => gqlClient.request(timetableResourceView, { filter }),
});

export function getTimetableResourceView(
  filter: TtResourceTimetableViewFilter,
) {
  return queryClient.fetchQuery(timetableResourceViewQuery(filter));
}

export function useTimetableResourceView(
  filter: TtResourceTimetableViewFilter,
  enabled = true,
) {
  return useQuery({
    ...timetableResourceViewQuery(filter),
    enabled,
    placeholderData: keepPreviousData,
    select: ({ tt_resourceTimetableView }) =>
      tt_resourceTimetableView.timeslots,
  });
}

export type ReturnTypeFromUseTimetableResourceView = UseQueryReturnType<
  typeof useTimetableResourceView
>;
