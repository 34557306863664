import {
  FileTransferFeature,
  getAcademicNamespace,
  getFileList,
  getPermissionUtils,
} from '@tyro/api';
import {
  getStudentAssessmentResults,
  getStudentDashboardAssessments,
} from '@tyro/assessments';
import {
  getPartyTimetable,
  getTimetableInfo,
  getTodayTimetableEvents,
} from '@tyro/calendar';
import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { getPayments, getStudentFees } from '@tyro/fees';
import { UserGroupIcon } from '@tyro/icons';
import { Report, getRunReports } from '@tyro/reporting';
import dayjs from 'dayjs';
import { redirect } from 'react-router-dom';
import { getContacts } from './api/contact/list';
import { getContactPersonal } from './api/contact/personal';
import { getContactStudents } from './api/contact/students';
import { getNotes } from './api/note/list';
import { getPersonStatus } from './api/person/status';
import { getStaff } from './api/staff';
import { getNonClassContactHours } from './api/staff/non-class-contact';
import { getStaffPersonal } from './api/staff/personal';
import { getStaffSubjectGroups } from './api/staff/subject-groups';
import { getMedicalConditionNamesQuery } from './api/student/medicals/medical-condition-lookup';
import { getPersonalTitlesQuery } from './api/student/medicals/personal-titles';
import { getStudentMedicalData } from './api/student/medicals/student-medical-data';
import {
  getStudentsContacts,
  getStudentsSubjectGroups,
} from './api/student/overview';
import { getStudentPersonal } from './api/student/personal';
import {
  getStudent,
  getStudentEnrolmentHistory,
  getStudents,
  getStudentsForSelect,
} from './api/student/students';
import {
  getStudentTimeline,
  getStudentTimelineHighlights,
} from './api/student/timeline';

const StudentsListPage = lazyWithRetry(() => import('./pages/students'));
// Student profile pages
const StudentProfileContainer = lazyWithRetry(
  () => import('./components/students/student-profile-container'),
);
const StudentProfileOverviewPage = lazyWithRetry(
  () => import('./pages/students/profile/overview'),
);
const StudentProfilePersonalPage = lazyWithRetry(
  () => import('./pages/students/profile/personal'),
);
const StudentProfileContactsPage = lazyWithRetry(
  () => import('./pages/students/profile/contacts'),
);
const StudentProfileAttendancePage = lazyWithRetry(
  () => import('./pages/students/profile/attendance'),
);
const StudentProfileFeesPage = lazyWithRetry(
  () => import('./pages/students/profile/fees'),
);
const StudentProfileAssessmentPage = lazyWithRetry(
  () => import('./pages/students/profile/assessment'),
);
const StudentProfileTimetablePage = lazyWithRetry(
  () => import('./pages/students/profile/timetable'),
);
const StudentProfileBehaviourPage = lazyWithRetry(
  () => import('./pages/students/profile/behaviour'),
);
const StudentProfileAenPage = lazyWithRetry(
  () => import('./pages/students/profile/aen'),
);
const StudentProfileClassesPage = lazyWithRetry(
  () => import('./pages/students/profile/classes'),
);
const StudentProfileSettingsPage = lazyWithRetry(
  () => import('./pages/students/profile/settings'),
);
const StudentProfileMedicalPage = lazyWithRetry(
  () => import('./pages/students/profile/medical'),
);
const StudentProfileNotesPage = lazyWithRetry(
  () => import('./pages/students/profile/notes'),
);
const StudentProfileDocumentsPage = lazyWithRetry(
  () => import('./pages/students/profile/documents'),
);
const StudentTimeline = lazyWithRetry(
  () => import('./pages/students/profile/timeline'),
);
const CreateStudentPage = lazyWithRetry(
  () => import('./pages/students/create'),
);

// Contact pages
const ContactsListPage = lazyWithRetry(() => import('./pages/contacts'));
const ContactsContainer = lazyWithRetry(
  () => import('./components/contact/contacts-container'),
);
const Relationships = lazyWithRetry(
  () => import('./pages/contacts/relationships'),
);
const ContactProfileContainer = lazyWithRetry(
  () => import('./components/contact/contact-profile-container'),
);
const ContactProfilePersonalPage = lazyWithRetry(
  () => import('./pages/contacts/profile/personal'),
);
const ContactProfileStudentsPage = lazyWithRetry(
  () => import('./pages/contacts/profile/students'),
);
const ContactFeesContainer = lazyWithRetry(
  () => import('./components/contacts/fees-container'),
);
const ContactProfileFeesPage = lazyWithRetry(
  () => import('./pages/contacts/profile/fees'),
);
const ContactProfileTransactionsPage = lazyWithRetry(
  () => import('./pages/contacts/profile/transactions'),
);
const ContactProfileAccessPage = lazyWithRetry(
  () => import('./pages/contacts/profile/access'),
);

const CreateContactPage = lazyWithRetry(
  () => import('./pages/contacts/create'),
);

// Staff pages

const StaffListPage = lazyWithRetry(() => import('./pages/staff'));

const StaffProfileContainer = lazyWithRetry(
  () => import('./components/staff/staff-profile-container'),
);
const StaffProfilePersonalPage = lazyWithRetry(
  () => import('./pages/staff/profile/personal'),
);
const StaffProfileTimetablePage = lazyWithRetry(
  () => import('./pages/staff/profile/timetable'),
);
const StaffProfileClassesPage = lazyWithRetry(
  () => import('./pages/staff/profile/classes'),
);
const StaffProfileNonClassContactPage = lazyWithRetry(
  () => import('./pages/staff/profile/non-class-contact'),
);
const StaffProfileDocumentsPage = lazyWithRetry(
  () => import('./pages/staff/profile/documents'),
);

const CreateStaffPage = lazyWithRetry(() => import('./pages/staff/create'));

// historic search

const HistoricSearchPage = lazyWithRetry(
  () => import('./pages/historic-search'),
);

const StudentsHistoricSearchPage = lazyWithRetry(
  () => import('./pages/historic-search/students'),
);

const StudentHistoricSearchContainer = lazyWithRetry(
  () => import('./components/historic-search/students/container'),
);

const StudentHistoricPersonalPage = lazyWithRetry(
  () => import('./pages/historic-search/students/profile/personal'),
);

const StudentHistoricContactsPage = lazyWithRetry(
  () => import('./pages/historic-search/students/profile/contacts'),
);

const StudentHistoricAttendancePage = lazyWithRetry(
  () => import('./pages/historic-search/students/profile/attendance'),
);

const StudentHistoricAssessmentPage = lazyWithRetry(
  () => import('./pages/historic-search/students/profile/assessment'),
);

const StudentHistoricEnrolmentPage = lazyWithRetry(
  () => import('./pages/historic-search/students/profile/enrolment'),
);

const StaffHistoricSearchPage = lazyWithRetry(
  () => import('./pages/historic-search/staff'),
);

const StaffHistoricSearchContainer = lazyWithRetry(
  () => import('./components/historic-search/staff/container'),
);

const StaffHistoricPersonalPage = lazyWithRetry(
  () => import('./pages/historic-search/staff/profile/personal'),
);

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:management.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'people',
        title: t('navigation:management.people.title'),
        icon: <UserGroupIcon />,
        children: [
          {
            type: NavObjectType.MenuLink,
            path: 'students',
            title: t('navigation:management.people.students'),
            loader: () => getStudents(),
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:people:view_student_list'),
            element: <StudentsListPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'students/create',
            element: <CreateStudentPage />,
            hasAccess: (permissions) =>
              permissions.hasPermission('api:core:write:student_create'),
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'students/:id',
            element: <StudentProfileContainer />,
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:people:view_student_profile'),
            loader: ({ params }) => {
              const studentId = getNumber(params.id);

              if (!studentId) {
                throw404Error();
              }

              return Promise.all([
                getStudent(studentId),
                getPersonStatus(studentId),
              ]);
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: async () => {
                  const {
                    isStaffUserWithPermission,
                    isStaffUser,
                    hasPermission,
                  } = await getPermissionUtils();

                  if (
                    isStaffUserWithPermission(
                      'ps:1:people:view_student_overview',
                    )
                  ) {
                    return redirect('./overview');
                  }

                  if (
                    isStaffUser ||
                    hasPermission('ps:1:groups:student_view_groups')
                  ) {
                    return redirect('./classes');
                  }

                  return redirect('./assessment');
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'overview',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission(
                    'ps:1:people:view_student_overview',
                  ),
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);
                  const formattedDate = dayjs().format('YYYY-MM-DD');

                  if (!studentId) {
                    throw404Error();
                  }

                  return Promise.all([
                    getStudentsContacts(studentId),
                    getStudentDashboardAssessments({
                      studentPartyId: studentId,
                      published: true,
                    }),
                    getPartyTimetable({
                      resources: {
                        partyIds: [studentId ?? 0],
                      },
                      startDate: formattedDate,
                      endDate: formattedDate,
                    }),
                    getTimetableInfo({
                      fromDate: formattedDate,
                      toDate: formattedDate,
                    }),
                  ]);
                },
                element: <StudentProfileOverviewPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'personal',
                // todo tab should be visible only if user has permission to view student personal information
                hasAccess: (permissions) =>
                  permissions.hasPermission(
                    'ps:1:people:view_student_personal_information',
                  ),
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);

                  if (!studentId) {
                    throw404Error();
                  }

                  return getStudentPersonal(studentId);
                },
                element: <StudentProfilePersonalPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'contacts',
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);
                  return getStudentsContacts(studentId);
                },
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission(
                    'ps:1:people:view_contacts_for_student',
                  ),
                element: <StudentProfileContactsPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'attendance',
                element: <StudentProfileAttendancePage />,
                hasAccess: ({ hasPermission }) =>
                  hasPermission(
                    'ps:1:attendance:read_session_attendance_student_calendar_view',
                  ),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'fees',
                element: <StudentProfileFeesPage />,
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('ps:1:fees:write_fees'),
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);

                  if (!studentId) {
                    throw404Error();
                  }

                  return getStudentFees({ studentPartyId: studentId });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'assessment',
                element: <StudentProfileAssessmentPage />,
                loader: async ({ params }) => {
                  const studentId = getNumber(params.id);
                  const { activeAcademicNamespace } =
                    await getAcademicNamespace();

                  if (!studentId) {
                    throw404Error();
                  }

                  const assessments = await getStudentDashboardAssessments({
                    studentPartyId: studentId,
                    published: true,
                  });

                  if (activeAcademicNamespace && assessments?.length) {
                    return getStudentAssessmentResults(
                      activeAcademicNamespace.academicNamespaceId,
                      {
                        studentPartyIds: [studentId],
                        assessmentId: assessments[0].id,
                      },
                    );
                  }

                  return assessments;
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'timetable',
                element: <StudentProfileTimetablePage />,
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);

                  if (!studentId) {
                    throw404Error();
                  }

                  return getTodayTimetableEvents(studentId);
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'behaviour',
                hasAccess: ({ hasPermission }) =>
                  hasPermission('ps:1:notes:read_behaviour'),
                element: <StudentProfileBehaviourPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'aen',
                hasAccess: ({ isStaffUserHasWithAtLeastOnePermission }) =>
                  isStaffUserHasWithAtLeastOnePermission([
                    'ps:1:wellbeing:read_student_aen',
                    'ps:1:documents:view_aen_docs',
                  ]),
                element: <StudentProfileAenPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'classes',
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);

                  if (!studentId) {
                    throw404Error();
                  }

                  return getStudentsSubjectGroups({
                    studentPartyIds: [studentId],
                    subjectGroupIds: [],
                  });
                },
                hasAccess: ({ isStaffUser, hasPermission }) =>
                  isStaffUser ||
                  hasPermission('ps:1:groups:student_view_groups'),
                element: <StudentProfileClassesPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'settings',
                element: <StudentProfileSettingsPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'medical',
                element: <StudentProfileMedicalPage />,
                loader: async ({ params }) => {
                  const studentId = getNumber(params.id);

                  if (!studentId) {
                    throw404Error();
                  }

                  return Promise.all([
                    getStudentMedicalData(studentId),
                    getMedicalConditionNamesQuery(),
                    getPersonalTitlesQuery(),
                  ]);
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'documents',
                hasAccess: ({ hasPermission }) =>
                  hasPermission('ps:1:documents:view_student_docs'),
                loader: ({ params }) => {
                  const studentId = params.id;

                  if (!studentId) {
                    throw404Error();
                  }

                  return getFileList({
                    referenceId: studentId,
                    feature: FileTransferFeature.StudentDocs,
                  });
                },
                element: <StudentProfileDocumentsPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'notes',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('ps:1:notes:read_notes'),
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);
                  return getNotes({ partyIds: [studentId ?? 0] });
                },
                element: <StudentProfileNotesPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'timeline',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission(
                    'ps:1:student_timeline:student_timeline',
                  ),
                loader: async ({ params, request }) => {
                  const studentId = getNumber(params.id) ?? 0;
                  const { searchParams } = new URL(request.url);
                  const { activeAcademicNamespace } =
                    await getAcademicNamespace();
                  const activeHighlightId = searchParams.get('highlightId');
                  const fromDate = searchParams.get('fromDate');
                  const toDate = searchParams.get('toDate');

                  const fromAndToDateFilter = {
                    fromDate: dayjs(
                      fromDate ?? activeAcademicNamespace?.startDate,
                    ).format('YYYY-MM-DD'),
                    toDate: dayjs(
                      toDate ?? activeAcademicNamespace?.endDate,
                    ).format('YYYY-MM-DD'),
                  };

                  const getHighlights = () =>
                    getStudentTimelineHighlights({
                      studentPartyIds: [studentId],
                      ...fromAndToDateFilter,
                    });

                  if (activeHighlightId) {
                    const highlightId = Number(activeHighlightId);
                    const { timeline_studentHighlights } =
                      await getHighlights();
                    const highlight = timeline_studentHighlights.find(
                      ({ id }) => id === highlightId,
                    );

                    if (highlight) {
                      const eventIds = highlight.events.map(
                        ({ timelineId }) => timelineId!,
                      );
                      return getStudentTimeline({
                        studentPartyIds: [studentId],
                        ...fromAndToDateFilter,
                        eventIds,
                      });
                    }
                  }

                  return Promise.all([
                    getStudentTimeline({
                      studentPartyIds: [studentId],
                      ...fromAndToDateFilter,
                    }),
                    getHighlights(),
                  ]);
                },
                element: <StudentTimeline />,
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'contacts',
            title: t('navigation:management.people.contacts'),
            loader: () => getContacts({}),
            hasAccess: (permissions) =>
              permissions.isStaffUserWithPermission(
                'ps:1:people:view_contact_list',
              ),
            element: <ContactsContainer />,
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./details'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'details',
                loader: () => getStudentsForSelect({}),
                element: <ContactsListPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'relationships',
                loader: () => getStudentsForSelect({}),
                element: <Relationships />,
              },
            ],
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'contacts/create',
            loader: () => getStudentsForSelect({}),
            element: <CreateContactPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'contacts/:id',
            element: <ContactProfileContainer />,
            // todo issue here where were user needs to
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:people:view_contact_profile'),
            loader: ({ params }) => {
              const contactId = getNumber(params.id);

              if (!contactId) {
                throw404Error();
              }

              return getContactPersonal(contactId ?? 0);
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./personal'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'personal',
                // todo tab should be visible only if user has permission to view student personal information
                hasAccess: (permissions) =>
                  permissions.hasPermission(
                    'ps:1:people:view_contact_personal_information',
                  ),
                loader: ({ params }) => {
                  const contactId = getNumber(params.id);

                  if (!contactId) {
                    throw404Error();
                  }

                  return getContactPersonal(contactId);
                },
                element: <ContactProfilePersonalPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'students',
                loader: ({ params }) => {
                  const contactId = getNumber(params.id);

                  if (!contactId) {
                    throw404Error();
                  }

                  return getContactStudents(contactId);
                },
                element: <ContactProfileStudentsPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'fees',
                element: <ContactFeesContainer />,
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('ps:1:fees:write_fees'),
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./list'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'list',
                    element: <ContactProfileFeesPage />,
                    loader: ({ params }) => {
                      const contactPartyId = getNumber(params.id);

                      if (!contactPartyId) {
                        throw404Error();
                      }

                      return getStudentFees({ contactPartyId });
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'transactions',
                    element: <ContactProfileTransactionsPage />,
                    loader: ({ params }) => {
                      const contactPartyId = getNumber(params.id);

                      if (!contactPartyId) {
                        throw404Error();
                      }

                      return getPayments({ payeePartyIds: [contactPartyId] });
                    },
                  },
                ],
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'access',
                element: <ContactProfileAccessPage />,
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'staff',
            title: t('navigation:management.people.staff'),
            loader: () => getStaff({}),
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:people:view_staff_list'),
            element: <StaffListPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'staff/create',
            loader: () => getStudentsForSelect({}),
            element: <CreateStaffPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'staff/:id',
            element: <StaffProfileContainer />,
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:people:view_staff_profile'),
            loader: ({ params }) => {
              const staffId = getNumber(params.id);

              if (!staffId) {
                throw404Error();
              }

              return Promise.all([
                getStaff({ partyIds: [staffId] }),
                getPersonStatus(staffId),
              ]);
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => {
                  return redirect('./timetable');
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'personal',
                hasAccess: (permissions) =>
                  permissions.hasPermission(
                    'ps:1:people:view_staff_personal_information',
                  ),
                loader: ({ params }) => {
                  const staffId = getNumber(params.id);

                  if (!staffId) {
                    throw404Error();
                  }

                  return getStaffPersonal({ partyIds: [staffId] });
                },
                element: <StaffProfilePersonalPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'timetable',
                element: <StaffProfileTimetablePage />,
                loader: ({ params }) => {
                  const staffId = getNumber(params.id);

                  if (!staffId) {
                    throw404Error();
                  }

                  return getTodayTimetableEvents(staffId);
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'classes',
                loader: ({ params }) => {
                  const staffId = getNumber(params.id);

                  if (!staffId) {
                    throw404Error();
                  }

                  return getStaffSubjectGroups(
                    { partyIds: [staffId] },
                    undefined,
                  );
                },
                element: <StaffProfileClassesPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'non-class-contact',
                element: <StaffProfileNonClassContactPage />,
                loader: async ({ params }) => {
                  const staffId = getNumber(params.id);
                  const { activeAcademicNamespace } =
                    await getAcademicNamespace();

                  if (!staffId) {
                    throw404Error();
                  }

                  return getNonClassContactHours({
                    academicNameSpaceId:
                      activeAcademicNamespace?.academicNamespaceId ?? 0,
                    staffPartyId: staffId,
                  });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'documents',
                hasAccess: ({ hasPermission }) =>
                  hasPermission('ps:1:documents:view_staff_docs'),
                loader: ({ params }) => {
                  const staffId = params.id;

                  if (!staffId) {
                    throw404Error();
                  }
                  return getFileList({
                    referenceId: staffId,
                    feature: FileTransferFeature.StaffDocs,
                  });
                },
                element: <StaffProfileDocumentsPage />,
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'historic-search',
            title: t('navigation:management.people.historic'),
            element: <HistoricSearchPage />,
            hasAccess: (permissions) =>
              permissions.isStaffUserHasWithAtLeastOnePermission([
                'ps:1:people:historic_student',
                'ps:1:people:historic_staff',
              ]),

            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: async () => {
                  const permissions = await getPermissionUtils();
                  const hasStudentPermission =
                    permissions.isStaffUserWithPermission(
                      'ps:1:people:historic_student',
                    );

                  if (hasStudentPermission) {
                    return redirect('./students');
                  }
                  return redirect('./staff');
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'students',
                element: <StudentsHistoricSearchPage />,
                loader: () =>
                  getRunReports({
                    topReportId: Report.STUDENT_HISTORIC_SEARCH,
                    filter: {
                      reportId: Report.STUDENT_HISTORIC_SEARCH,
                    },
                  }),
                hasAccess: (permissions) =>
                  permissions.isStaffUserWithPermission(
                    'ps:1:people:historic_student',
                  ),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'staff',
                element: <StaffHistoricSearchPage />,
                loader: () =>
                  getRunReports({
                    topReportId: Report.STAFF_HISTORIC_SEARCH,
                    filter: {
                      reportId: Report.STAFF_HISTORIC_SEARCH,
                    },
                  }),
                hasAccess: (permissions) =>
                  permissions.isStaffUserWithPermission(
                    'ps:1:people:historic_staff',
                  ),
              },
            ],
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'historic-search/students/:id',
            element: <StudentHistoricSearchContainer />,
            hasAccess: (permissions) =>
              permissions.isStaffUserWithPermission(
                'ps:1:people:historic_student',
              ),
            loader: ({ params }) => {
              const studentId = getNumber(params.id);

              if (!studentId) {
                throw404Error();
              }

              return Promise.all([
                getRunReports(
                  {
                    topReportId: Report.STUDENT_HISTORIC_SEARCH,
                    filter: {
                      reportId: Report.STUDENT_HISTORIC_SEARCH,
                      filters: [
                        {
                          filterId: 'tyro_id',
                          filterValue: studentId,
                        },
                      ],
                      showFields: [],
                    },
                  },
                  {
                    staleTime: 1000 * 60 * 2,
                  },
                ),
              ]);
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: async () => {
                  const { isStaffUserWithPermission } =
                    await getPermissionUtils();

                  const hasPersonalPermission = isStaffUserWithPermission(
                    'ps:1:people:view_student_personal_information',
                  );

                  const hasAttendancePermission = isStaffUserWithPermission(
                    'ps:1:people:read_session_attendance_individual',
                  );

                  if (hasPersonalPermission) {
                    return redirect('./personal');
                  }

                  return hasAttendancePermission
                    ? redirect('./attendance')
                    : redirect('assessment');
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'personal',
                hasAccess: (permissions) =>
                  permissions.isStaffUserWithPermission(
                    'ps:1:people:view_student_personal_information',
                  ),
                element: <StudentHistoricPersonalPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'contacts',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission(
                    'ps:1:people:view_contacts_for_student',
                  ),
                element: <StudentHistoricContactsPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'attendance',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission(
                    'ps:1:attendance:read_session_attendance_individual',
                  ),
                element: <StudentHistoricAttendancePage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'assessment',
                element: <StudentHistoricAssessmentPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'enrolment',
                loader: ({ params }) => {
                  const studentId = getNumber(params.id);

                  if (!studentId) {
                    throw404Error();
                  }
                  return getStudentEnrolmentHistory({ partyIds: [studentId] });
                },
                element: <StudentHistoricEnrolmentPage />,
              },
            ],
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'historic-search/staff/:id',
            element: <StaffHistoricSearchContainer />,
            hasAccess: (permissions) =>
              permissions.isStaffUserWithPermission(
                'ps:1:people:historic_staff',
              ),
            loader: ({ params }) => {
              const staffId = getNumber(params.id);

              if (!staffId) {
                throw404Error();
              }

              return Promise.all([
                getRunReports({
                  topReportId: Report.STAFF_HISTORIC_SEARCH,
                  filter: {
                    reportId: Report.STAFF_HISTORIC_SEARCH,
                    filters: [
                      {
                        filterId: 'tyro_id',
                        filterValue: staffId,
                      },
                    ],
                  },
                }),
              ]);
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./personal'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'personal',
                element: <StaffHistoricPersonalPage />,
              },
            ],
          },
        ],
      },
    ],
  },
];