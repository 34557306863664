import {
  Badge,
  Box,
  Fab,
  Grow,
  type BadgeProps as MuiBadgeProps,
  type FabProps as MuiFabProps,
  Stack,
} from '@mui/material';
import { type Colour, type PermissionUtils, usePermissions } from '@tyro/api';
import { CloseIcon, HamburgerMenuIcon } from '@tyro/icons';
import { AnimatePresence, m, useIsPresent } from 'motion/react';
import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import { useDisclosure } from '../../hooks';

export interface FabMenuItem {
  id?: string;
  label: string;
  icon: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  navigateTo?: LinkProps['to'];
  onBeforeNavigate?: () => void;
  disabled?: boolean;
  disabledTooltip?: string;
  hasAccess?: (permissions: PermissionUtils) => boolean;
  color?: Colour | 'indigo';
  invertColor?: boolean;
  badgeNumber?: number;
  badgeColor?: Colour;
  showBadgeDot?: boolean;
}

const smallFabSize = 44;
const largeFabSize = 56;
const fabSpacing = 20;

function MenuItem({
  onClick,
  navigateTo,
  icon,
  label,
  color = 'indigo',
  invertColor,
  badgeNumber,
  showBadgeDot,
  badgeColor,
}: FabMenuItem) {
  const isPresent = useIsPresent();
  const [textRef, setTextRef] = useState<HTMLSpanElement | null>(null);
  const isButton = !!onClick;
  const typeProps = isButton
    ? ({ onClick } as MuiFabProps)
    : { component: Link, to: navigateTo };

  const textWidth =
    isPresent === false ? 0 : (textRef?.getBoundingClientRect()?.width ?? 0);

  const colorStyles = invertColor
    ? ({
        color: `${color}.50`,
        backgroundColor: `${color}.500`,
        borderColor: `${color}.600`,
        '&:hover': {
          backgroundColor: `${color}.600`,
        },
      } as const)
    : ({
        color: `${color}.600`,
        backgroundColor: 'white',
        borderColor: `${color}.50`,
        '&:hover': {
          backgroundColor: `${color}.100`,
        },
      } as const);

  return (
    <Badge
      color={badgeColor ?? 'error'}
      variant={badgeNumber ? 'standard' : 'dot'}
      invisible={showBadgeDot !== true && !badgeNumber}
      badgeContent={badgeNumber}
      overlap="circular"
      sx={({ zIndex }) => ({
        '& .MuiBadge-badge': {
          zIndex: zIndex.fab + 1,
          backgroundColor: `${badgeColor ?? 'error'}.700`,
          pointerEvents: 'none',
        },
      })}
    >
      <Fab
        {...typeProps}
        sx={{
          height: 44,
          width: 'auto',
          minWidth: 44,
          border: '1px solid',
          borderRadius: '22px',
          px: 1.25,
          ...colorStyles,
          '& span': {
            pl: 1,
            textWrap: 'nowrap',
          },
          '& div': {
            maxWidth: '0px',
            overflow: 'hidden',
            transition: 'max-width 0.2s',
          },
          '.exiting & div': {
            maxWidth: '0px !important',
          },
          '&:hover, &:focus': {
            '& div': {
              maxWidth: `${textWidth}px`,
            },
          },
        }}
      >
        {icon}
        <div>
          <span key={label} ref={(ref) => setTextRef(ref)}>
            {label}
          </span>
        </div>
      </Fab>
    </Badge>
  );
}

export interface FabMenuProps {
  icon?: ReactNode;
  closeIcon?: React.ReactNode;
  label: string;
  FabProps?: MuiFabProps;
  menuItems: FabMenuItem[];
  showBadge?: boolean;
  openByDefault?: boolean;
  BadgeProps?: MuiBadgeProps;
}

export function FabMenu({
  icon,
  closeIcon,
  label,
  menuItems,
  FabProps,
  showBadge,
  openByDefault = false,
  BadgeProps,
}: Partial<FabMenuProps>) {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure({
    defaultIsOpen: openByDefault,
  });
  const fabTimedToHide = useRef(false);
  const [showFabButton, setShowFabButton] = useState(false);
  const permissions = usePermissions();

  const filteredMenuItems = useMemo(
    () =>
      menuItems?.filter(
        ({ hasAccess }) => !hasAccess || hasAccess(permissions),
      ) ?? [],
    [menuItems, permissions],
  );

  useEffect(() => {
    const show = Boolean(label && filteredMenuItems.length);
    if (show) {
      setShowFabButton(true);
    } else if (filteredMenuItems.length === 0 && isOpen) {
      onClose();
      fabTimedToHide.current = true;
      setTimeout(() => {
        setShowFabButton(false);
        fabTimedToHide.current = false;
      }, 200);
    } else if (fabTimedToHide.current === false) {
      setShowFabButton(false);
    }
  }, [isOpen, label, filteredMenuItems]);

  useEffect(() => {
    if (openByDefault) {
      setTimeout(() => {
        onOpen();
      }, 200);
    }
  }, [openByDefault]);

  return (
    <Grow in={showFabButton} unmountOnExit>
      <Box
        className="fab-menu"
        sx={({ zIndex }) => ({
          position: 'fixed',
          bottom: 16,
          right: 16,
          transition:
            'opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 0.2s cubic-bezier(0, 0, 0.2, 1) !important',
          zIndex: zIndex.fab,
        })}
      >
        <Badge
          color="error"
          variant="dot"
          invisible={!showBadge || isOpen}
          overlap="circular"
          {...BadgeProps}
          sx={({ zIndex }) => ({
            '& .MuiBadge-badge': {
              zIndex: zIndex.fab + 1,
              pointerEvents: 'none',
            },
          })}
        >
          <Fab
            color="primary"
            aria-label={label}
            onClick={onToggle}
            {...FabProps}
          >
            <AnimatePresence initial={false}>
              {isOpen ? (
                <m.div
                  key="close-icon"
                  initial={{ opacity: 0, rotate: -90, position: 'absolute' }}
                  animate={{
                    opacity: 1,
                    rotate: 0,
                    position: 'relative',
                    transition: { delay: 0.1 },
                  }}
                  exit={{ opacity: 0, rotate: -90, position: 'absolute' }}
                  style={{ display: 'flex' }}
                >
                  {closeIcon || <CloseIcon sx={{ width: 32, height: 32 }} />}
                </m.div>
              ) : (
                <m.div
                  key="icon"
                  initial={{ opacity: 0, position: 'absolute' }}
                  animate={{
                    opacity: 1,
                    position: 'relative',
                    transition: { delay: 0.1 },
                  }}
                  exit={{ opacity: 0, position: 'absolute' }}
                  style={{ display: 'flex' }}
                >
                  {icon || <HamburgerMenuIcon />}
                </m.div>
              )}
            </AnimatePresence>
          </Fab>
        </Badge>

        <Stack
          sx={({ zIndex }) => ({
            zIndex: zIndex.fab - 1,
            position: 'absolute',
            bottom: largeFabSize + fabSpacing,
            right: (largeFabSize - smallFabSize) / 2,
          })}
          spacing={2.5}
          alignItems="flex-end"
        >
          <AnimatePresence>
            {filteredMenuItems.map((item, index) => {
              if (!isOpen) return null;
              const fabPosition = filteredMenuItems.length - index;
              const startPosition = {
                translateY:
                  (smallFabSize + fabSpacing) * fabPosition +
                  (largeFabSize - smallFabSize) / 2,
              };

              return (
                <m.div
                  key={item?.id ?? item.label}
                  initial={startPosition}
                  animate={{ translateY: 0 }}
                  exit={startPosition}
                  transition={{
                    ease: 'easeOut',
                    duration: 0.2,
                  }}
                >
                  <MenuItem {...item} />
                </m.div>
              );
            })}
          </AnimatePresence>
        </Stack>
      </Box>
    </Grow>
  );
}
