import { Card, Divider, ToggleButtonGroup, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';

import type { FileTransferRiuType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { ToggleButtonTableIcon } from '@tyro/icons';
import { getMetaCharacter } from '../../../utils';
import { InsertLinkButton } from '../../insert-link-button';
import AddColumnsMenu from './add-columns-menu';
import { InsertImage } from './insert-image';
import { ListItemsToggle } from './list-items-toggle';
import TextAlignmentToggle from './paragraph-formatting/text-alignment-toggle';
import FontPicker from './text-formatting/font-picker';
import FontStyling from './text-formatting/font-style';
import HeadingPicker from './text-formatting/paragraph-formatter';
import { ToggleButton } from './toolbar-buttons/toggle-button';
import { UndoRedoToggle } from './undo-redo-toggle';

type BaseMenuTools =
  | 'undoRedo'
  | 'fontPicker'
  | 'fontStyling'
  | 'headings'
  | 'alignment'
  | 'listButtons'
  | 'tables'
  | 'columns'
  | 'strikethrough'
  | 'insertImage';

interface BaseMenuToolBarProps {
  editor: Editor | null;
  omittedTools?: BaseMenuTools[];
  riuType: FileTransferRiuType;
  customTool?: React.ReactNode;
}

export const BaseMenuToolBar = ({
  editor,
  omittedTools = [],
  riuType,
  customTool,
}: BaseMenuToolBarProps) => {
  const { t } = useTranslation(['templates', 'common']);
  const metaCharacter = getMetaCharacter();

  const isToolVisible = (tool: BaseMenuTools) => !omittedTools.includes(tool);

  const dividerStyles = {
    height: '30px',
    alignSelf: 'center',
    backgroundColor: 'lightgray',
  };

  if (!editor) return null;

  return (
    <Card
      aria-label={t('common:menuToolbarItems')}
      sx={{
        display: 'flex',
        position: 'sticky',
        top: '80px',
        zIndex: '2',
        overflow: 'visible',
        backgroundColor: 'slate.50',
        minHeight: '67px',
        paddingX: 0.5,
        '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderLeft: 'none',
        },
        width: 'auto',
        '& hr:last-of-type': {
          display: 'none',
        },
        alignItems: 'center',
        '& .MuiToggleButton-root': {
          margin: '4px',
          padding: '4px',
          border: 'none',
        },
      }}
    >
      {isToolVisible('undoRedo') && (
        <>
          <UndoRedoToggle editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {customTool && (
        <>
          {customTool}
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              height: '30px',
              alignSelf: 'center',
              backgroundColor: 'lightgray',
            }}
          />
        </>
      )}

      {isToolVisible('fontPicker') && (
        <>
          {/* Font Picker */}
          <FontPicker editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('headings') && (
        <>
          {/* Heading Picker */}
          <HeadingPicker editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('fontStyling') && (
        <>
          <FontStyling editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('alignment') && (
        <>
          {/* Text Alignment */}
          <TextAlignmentToggle editor={editor} />

          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('listButtons') && (
        <>
          <ListItemsToggle editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
          <InsertLinkButton editor={editor} />
          {isToolVisible('insertImage') && (
            <InsertImage editor={editor} riuType={riuType} />
          )}
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('strikethrough') && (
        <>
          <ToggleButtonGroup
            aria-label={t('common:ariaLabelTableAndColumns')}
            size="small"
            exclusive
            sx={{
              '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                borderLeft: 'none',
              },
              border: 'none',
              alignItems: 'center',
              backgroundColor: 'slate.50',
            }}
          >
            <ToggleButton
              title={t('common:tooltipTitles.horizontalRule')}
              onClick={() => editor.chain().setHorizontalRule().run()}
              value="unLink"
            >
              <Typography fontSize="14px" color="slate.800">
                {t('templates:horizontalRule')}
              </Typography>
            </ToggleButton>

            <Divider flexItem orientation="vertical" sx={dividerStyles} />

            <ToggleButton
              title={t('common:tooltipTitles.strikeThrough', {
                shortcut: `${metaCharacter} + Shift + X`,
              })}
              onClick={() => editor.chain().toggleStrike().run()}
              value="strikeThrough"
            >
              <Typography fontSize="14px" color="slate.800">
                {t('common:tooltipTitles.strikeThroughIconText')}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('tables') && (
        <>
          {/* Table Button */}
          <ToggleButton
            title={t('common:tooltipTitles.table')}
            onClick={() => editor.commands.insertTable({ rows: 3, cols: 3 })}
            value="table"
          >
            <ToggleButtonTableIcon />
          </ToggleButton>
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}

      {isToolVisible('columns') && (
        <>
          {/* Columns Menu */}
          <AddColumnsMenu editor={editor} />
          <Divider flexItem orientation="vertical" sx={dividerStyles} />
        </>
      )}
    </Card>
  );
};
