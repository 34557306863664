import {
  Box,
  CircularProgress,
  Collapse,
  FormLabel,
  IconButton,
  Input,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useUser } from '@tyro/api';
import {
  Avatar,
  VisuallyHidden,
  useDisclosure,
  usePreferredNameLayout,
  useRelativeDateFormat,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { ArrowUpIcon, ChevronLeftIcon, ThumbsUpIcon } from '@tyro/icons';
import { useEffect, useId, useState } from 'react';
import {
  type ReturnTypeFromUseStudentTimelineHighlights,
  useSaveTimelineHighlightComment,
} from '../../../../../api/student/timeline';

interface CommentsProps {
  highlightId: number;
  comments: ReturnTypeFromUseStudentTimelineHighlights['comments'];
}

function Comment({
  commentId,
  commenter,
  comment,
  createdOn,
  likedByParties,
  highlightId,
}: CommentsProps['comments'][number] & { highlightId: number }) {
  const { t } = useTranslation(['people']);
  const { displayName } = usePreferredNameLayout();
  const name = displayName(commenter);
  const { activeProfile } = useUser();
  const { getRelativeDateFormat } = useRelativeDateFormat();
  const { palette, customShadows } = useTheme();
  const [internalLikedByParties, setInternalLikedByParties] = useState(
    likedByParties ?? [],
  );
  const { mutateAsync: updateComment } = useSaveTimelineHighlightComment();

  const hasLikedComment = internalLikedByParties?.some(
    (party) => party.partyId === activeProfile?.partyId,
  );

  const toggleLikeComment = () => {
    let partyIds: number[] = [];

    setInternalLikedByParties((previousParties) => {
      const newParties = hasLikedComment
        ? previousParties.filter(
            ({ partyId }) => partyId !== activeProfile!.partyId,
          )
        : [
            ...previousParties,
            {
              partyId: activeProfile!.partyId!,
              firstName: '',
              lastName: '',
            },
          ];

      partyIds = newParties.map(({ partyId }) => partyId);

      return newParties;
    });

    updateComment({
      commentId,
      comment,
      highlightId,
      likedByPartyIds: partyIds,
    });
  };

  useEffect(() => {
    setInternalLikedByParties(likedByParties ?? []);
  }, [likedByParties]);

  return (
    <Stack direction="row" spacing={1}>
      <Avatar
        person={commenter}
        name={name}
        src={commenter?.avatarUrl}
        size={32}
      />
      <Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2" component="h4">
            {name}
          </Typography>
          <Typography variant="body2" component="h4" color="text.secondary">
            {getRelativeDateFormat(createdOn)}
          </Typography>
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {comment}
        </Typography>
        <Tooltip
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'white',
                color: 'text.primary',
                boxShadow: customShadows.dropdown,
              },
            },
          }}
          title={
            Array.isArray(likedByParties) && likedByParties.length > 0 ? (
              <Stack>
                <Typography
                  component="h4"
                  variant="subtitle2"
                  color="text.secondary"
                >
                  {t('people:likedBy')}:
                </Typography>
                <Stack component="ul" sx={{ p: 0, mt: 0.5, mb: 0 }}>
                  {likedByParties.map((party) => {
                    const name = displayName(party);
                    return (
                      <Stack key={party.partyId} direction="row" spacing={0.5}>
                        <Avatar
                          person={party}
                          name={name}
                          src={party?.avatarUrl}
                          size={18}
                        />
                        <Typography
                          variant="caption"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {name}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            ) : undefined
          }
        >
          <Stack
            component="button"
            type="button"
            onClick={toggleLikeComment}
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: 'none',
              backgroundColor: 'transparent',
              width: 18,
              height: 18,
              p: 0,
              color: hasLikedComment ? 'indigo.main' : 'text.secondary',
              cursor: 'pointer',

              '& span': {
                color: 'text.secondary',
              },

              '&:hover': {
                color: hasLikedComment ? 'indigo.dark' : 'text.primary',
                '& svg g': {
                  fill: palette.indigo.lighter,
                },
              },
              '& svg': {
                '& g': {
                  fill: hasLikedComment
                    ? palette.indigo.lighter
                    : 'transparent',
                },
                width: 18,
                height: 18,
              },
            }}
          >
            <ThumbsUpIcon />
            <span>{internalLikedByParties?.length}</span>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

export function Comments({ highlightId, comments }: CommentsProps) {
  const id = useId();
  const newCommentFieldId = useId();
  const { t } = useTranslation(['common']);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const { activeProfile } = useUser();
  const [newComment, setNewComment] = useState('');
  const {
    isOpen: isAdditionalCommentsViewable,
    onOpen: viewAdditionalComments,
  } = useDisclosure({ defaultIsOpen: false });

  const { mutateAsync: addNewComment, isPending: isAddingNewComment } =
    useSaveTimelineHighlightComment();

  const additionalComments = comments.slice(0, -1);
  const lastComment = comments[comments.length - 1];

  const submitNewComment = async () => {
    if (newComment.trim().length === 0) {
      return;
    }

    await addNewComment(
      {
        comment: newComment,
        highlightId: highlightId,
      },
      {
        onSuccess: () => {
          setNewComment('');
        },
      },
    );
  };

  return (
    <Stack
      sx={{
        '& > h3': {
          m: 0,
          '& > button': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',

            '& svg': {
              transition: 'transform 0.15s ease-in-out',
            },
          },
        },
      }}
    >
      <Box
        component="h3"
        sx={{ display: 'flex', '& *': { color: 'text.secondary' } }}
      >
        <Box
          component="button"
          type="button"
          aria-expanded={isOpen}
          aria-controls={`${id}-section`}
          id={id}
          onClick={onToggle}
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>{t('common:xComments', { count: comments.length })}</span>
          <ChevronLeftIcon sx={isOpen ? { transform: 'rotate(-90deg)' } : {}} />
        </Box>
      </Box>
      <Collapse in={isOpen}>
        <Stack
          role="region"
          id={`${id}-section`}
          aria-labelledby={id}
          spacing={1}
          sx={{
            mt: 1,
            mb: 0.5,
            mx: 1,
            transition: 'height 0.15s ease-in-out',
            transform: 'translateZ(0)',
          }}
        >
          {comments.length > 0 && (
            <Stack spacing={1.5}>
              {isAdditionalCommentsViewable &&
                additionalComments.length > 0 &&
                additionalComments.map((comment) => (
                  <Comment
                    key={comment.commentId}
                    {...comment}
                    highlightId={highlightId}
                  />
                ))}
              <Comment {...lastComment} highlightId={highlightId} />
              {!isAdditionalCommentsViewable &&
                additionalComments.length > 0 && (
                  <Link
                    component="button"
                    type="button"
                    underline="hover"
                    onClick={viewAdditionalComments}
                    alignSelf="flex-start"
                    sx={{
                      fontSize: '0.8rem',
                      color: 'text.secondary',
                      marginLeft: '42px !important',

                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    {t('common:viewMoreComments')}
                  </Link>
                )}
            </Stack>
          )}
          <Stack direction="row" spacing={2} pt={1} alignItems="center">
            <Avatar
              src={activeProfile?.avatarUrl}
              name={activeProfile?.nickName ?? undefined}
              size={32}
            />
            <Stack direction="row" spacing={1.25} flex={1}>
              <Stack flex={1}>
                <VisuallyHidden>
                  <FormLabel htmlFor={newCommentFieldId}>
                    {t('common:addAComment')}
                  </FormLabel>
                </VisuallyHidden>
                <Input
                  id={newCommentFieldId}
                  size="small"
                  fullWidth
                  multiline
                  placeholder={t('common:addAComment')}
                  value={newComment}
                  onChange={(event) => setNewComment(event.target.value)}
                />
              </Stack>
              {isAddingNewComment ? (
                <Box
                  sx={{
                    width: 28,
                    height: 28,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: 'indigo.main',
                    color: 'white',
                  }}
                >
                  <CircularProgress color="inherit" size={18} />
                </Box>
              ) : (
                <IconButton
                  size="small"
                  sx={{
                    backgroundColor: 'indigo.main',
                    color: 'white',
                    height: 28,
                    width: 28,
                    '&:hover': {
                      backgroundColor: 'indigo.dark',
                    },
                    '&:disabled': {
                      backgroundColor: 'slate.light',
                    },
                    '& svg': {
                      width: 18,
                      height: 18,
                    },
                  }}
                  disabled={newComment.trim().length === 0}
                  onClick={submitNewComment}
                >
                  <ArrowUpIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  );
}
