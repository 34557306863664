import { Box, Stack, Tab, Tabs, alpha, useTheme } from '@mui/material';
import { mixHexColors } from '@tyro/core';

export type BottomTabsProps = {
  componentId: string;
  tabs: {
    key: 'needToKnow' | 'timeline' | 'personalInfo';
    id: string;
    name: string;
    panelId: string;
  }[];
  value: string;
  setTab: (key: string) => void;
};

export function BottomTabs({ tabs, value, setTab }: BottomTabsProps) {
  const { palette } = useTheme();

  const bgColor = mixHexColors(palette.indigo[50], '#fff', 0.3);

  return (
    <Tabs
      value={value}
      onChange={(_, newValue) => setTab(newValue)}
      variant="fullWidth"
      sx={{
        px: 2,
        pb: 1.75,
        pt: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        background: `linear-gradient(180deg, ${alpha(bgColor, 0)} 0%, ${bgColor} 15%)`,
        width: '100%',
        '& .MuiTab-root': {
          fontSize: 13,
          fontWeight: 700,

          '&:not(:last-of-type)': {
            mr: 2,
          },

          '&.Mui-selected': {
            color: 'primary.main',
          },
        },
        '&:has(.MuiTabScrollButton-root)': {
          px: 0,
        },
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.key}
          id={tab.id}
          value={tab.key}
          label={tab.name}
          aria-controls={tab.panelId}
          sx={{
            minHeight: 40,
          }}
        />
      ))}
    </Tabs>
  );
}
