import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  type QueryAdditionalOptions,
  type Reporting_ReportFilter,
  Reporting_ReportOutputType,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useEffect } from 'react';
import { type InnerReportFilter, reportsKeys } from './keys';

const reportInfo = graphql(/* GraphQL */ `
  query reporting_reportInfo($filter: Reporting_ReportFilter) {
    reporting_runReport(filter: $filter) {
      id
      info {
        name
        supportsExpandRow
      }
      innerReports {
        id
        name
      }
    }
  }
`);

const reportsRun = graphql(/* GraphQL */ `
  query reporting_runReport($filter: Reporting_ReportFilter) {
    reporting_runReport(filter: $filter) {
      id
      info {
        name
        supportsExpandRow
        isInteractive
      }
      innerReports {
        id
        name
      }
      debug {
        sql
      }
      filters {
        id
        inputType
        label
        defaultValue
        required
        values {
          id
          value
        }
        minValue
        maxValue
      }
      fields {
        id
        label
        visibleByDefault
        checkExpandedRows
        hideMenu
        sortable
        maxWidth
        minWidth
        pinned
        rowGroup
        cellType
        type
        meta {
          chipSize
          chipVariant
          currency
          dateFormat
          showAvatar
          avatarSize
          enableLink
          numberFormat  {
            style
            maximumFractionDigits
            }
        }
      }
      metrics {
        defaultValue
        values {
          id
          name
        }
      }
      timeGroupBy {
        defaultValue
        values {
          id
          name
          description
        }
      }
      groupBy {
        defaultValue
        values {
          id
          name
          description
        }
      }
      data
      tableDisplayOptions {
        gridOptions
        tableContainerSx
      }
      statusPanels {
        statusPanel
        statusPanelParams {
          columns {
            id
            aggregationTypes
            onSelectOnly
          }
        }
      }
    }
  }
`);

const reportsRunCharts = graphql(/* GraphQL */ `
  query reporting_runChartQuery($filter: Reporting_ReportFilter) {
    reporting_runQuery(filter: $filter) {
      charts {
        type
        series {
          name
          series
          colour
        }
        debug {
          sql
        }
        categories
      }
    }
  }
`);

const reportsInfoQuery = ({ topReportId, filter }: InnerReportFilter) => ({
  queryKey: reportsKeys.reportInfo({ topReportId, filter }),
  queryFn: async () => gqlClient.request(reportInfo, { filter }),
});

export function getReportInfo(filter: InnerReportFilter) {
  return queryClient.fetchQuery(reportsInfoQuery(filter));
}

export function useReportInfo(filter: InnerReportFilter) {
  return useQuery({
    ...reportsInfoQuery(filter),
    select: ({ reporting_runReport }) => reporting_runReport,
  });
}

const runReportsQuery = ({ topReportId, filter }: InnerReportFilter) => ({
  queryKey: reportsKeys.report({ topReportId, filter }),
  staleTime: 0,
  queryFn: async () => gqlClient.request(reportsRun, { filter }),
});

export function getRunReports(
  filter: InnerReportFilter,
  additionalOptions?: Omit<QueryAdditionalOptions, 'academicNamespaceId'>,
) {
  return queryClient.fetchQuery({
    ...runReportsQuery(filter),
    ...additionalOptions,
  });
}

export function useRunReports(
  filter: InnerReportFilter,
  additionalOptions?: Omit<QueryAdditionalOptions, 'academicNamespaceId'> & {
    getExtraData?: boolean;
  },
) {
  const reportData = useQuery({
    ...runReportsQuery(filter),
    placeholderData: keepPreviousData,
    select: ({ reporting_runReport }) => reporting_runReport,
    ...additionalOptions,
  });

  useEffect(() => {
    if (reportData.data?.id && additionalOptions?.getExtraData !== false) {
      const firstRow = (reportData.data?.data?.[0] || {}) as Record<
        string,
        unknown
      >;
      const currentFields = Object.keys(firstRow);

      const showFields = reportData?.data?.fields?.map((field) => field.id);
      const missingFields = (showFields || [])?.filter(
        (field) => !currentFields.includes(field),
      );

      const getExtraData = async () => {
        const reportWithExtraData = await gqlClient.request(reportsRun, {
          filter: { ...filter.filter, showFields },
        });

        queryClient.setQueryData(
          reportsKeys.report(filter),
          reportWithExtraData,
        );
      };

      if (missingFields.length > 0) {
        getExtraData();
      }
    }
  }, [
    reportData.data?.id,
    JSON.stringify(filter),
    additionalOptions?.getExtraData,
  ]);

  return reportData;
}

const runReportChartsQuery = (filter: Reporting_ReportFilter) => {
  const filterWidthChartOutput = {
    ...filter,
    outputType: Reporting_ReportOutputType.Chart,
  };

  return {
    queryKey: reportsKeys.reportChart(filterWidthChartOutput),
    queryFn: async () => {
      const { reporting_runQuery } = await gqlClient.request(reportsRunCharts, {
        filter: filterWidthChartOutput,
      });
      return new Map(
        reporting_runQuery.charts.map((chart) => [chart.type, chart]),
      );
    },
  };
};

export function getRunReportCharts(filter: Reporting_ReportFilter) {
  return queryClient.fetchQuery(runReportChartsQuery(filter));
}

export function useRunReportCharts(filter: Reporting_ReportFilter) {
  return useQuery({
    ...runReportChartsQuery(filter),
  });
}

export type ReturnTypeFromUseRunReportCharts = NonNullable<
  ReturnType<UseQueryReturnType<typeof useRunReportCharts>['get']>
>;

export type ReturnTypeFromUseRunReports = UseQueryReturnType<
  typeof useRunReports
>;
