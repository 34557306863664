import { ToggleButtonGroup } from '@mui/material';
import type { Editor } from '@tiptap/react';

import { useTranslation } from '@tyro/i18n';
import { BulletListIcon, NumberListIcon } from '@tyro/icons';
import { getMetaCharacter } from '../../../../utils';
import { ToggleButton } from '../toolbar-buttons/toggle-button';

interface ListItemsToggleProps {
  editor: Editor | null;
}

export const ListItemsToggle = ({ editor }: ListItemsToggleProps) => {
  const { t } = useTranslation(['common']);
  const metaCharacter = getMetaCharacter();

  if (!editor) return null;

  return (
    <ToggleButtonGroup
      aria-label={t('common:ariaLabelListsAndLinks')}
      size="small"
      exclusive
      sx={{
        '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderLeft: 'none',
        },
        border: 'none',
        alignItems: 'center',
        backgroundColor: 'slate.50',
      }}
    >
      <ToggleButton
        title={t('common:tooltipTitles.bulletedList', {
          shortcut: `${metaCharacter} + 8`,
        })}
        onClick={() => editor?.chain().toggleBulletList().run()}
        selected={editor?.isActive('bulletList')}
        value="bulletList"
      >
        <BulletListIcon />
      </ToggleButton>
      <ToggleButton
        title={t('common:tooltipTitles.numberedList', {
          shortcut: `${metaCharacter} + ⇧ + 7`,
        })}
        onClick={() => editor?.chain().toggleOrderedList().run()}
        selected={editor?.isActive('orderedList')}
        value="orderedList"
      >
        <NumberListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
