import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { CalendarSchedulePlayIcon } from '@tyro/icons';

const TyroTuitionPage = lazyWithRetry(() => import('./pages/index'));
const Course = lazyWithRetry(() => import('./pages/course'));
import { getTyroTuition } from './api/courses';
import { tyroTuitionCoursesPathName } from './utils/api-config';

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    hasAccess: (permissions) =>
      permissions.hasPermission('ps:1:general_admin:tyro_tuition'),
    children: [
      {
        type: NavObjectType.RootLink,

        path: 'tyro-tuition',
        icon: <CalendarSchedulePlayIcon />,
        title: t('navigation:general.tyroTuition.title'),
        children: [
          {
            type: NavObjectType.NonMenuLink,
            index: true,
            element: <TyroTuitionPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'course/:id',
            element: <Course />,
            loader: ({ params }) => {
              const id = getNumber(params.id);

              if (!id) {
                throw404Error();
              }

              return getTyroTuition(tyroTuitionCoursesPathName);
            },
          },
        ],
      },
    ],
  },
];
