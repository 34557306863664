import { useQuery } from '@tanstack/react-query';

import {
  type Sa_SchoolActivityFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { activitiesKeys } from './keys';

const saStudentList = graphql(/* GraphQL */ `
  query saStudentList($filter: Sa_SchoolActivityFilter!) {
    sa_activities(filter: $filter) {
      customGroup {
        students {
          partyId
          person {
            partyId
            firstName
            lastName
            avatarUrl
            type
            title {
              id
              name
              nameTextId
            }
          }
          classGroup {
            name
          }
        }
      }
    }
  }
`);

const saStudentListQuery = (filter: Sa_SchoolActivityFilter) => ({
  queryKey: activitiesKeys.activitiesList(filter),
  queryFn: () =>
    gqlClient.request(saStudentList, {
      filter,
    }),
});

export function getSaStudentList(filter: Sa_SchoolActivityFilter) {
  return queryClient.fetchQuery(saStudentListQuery(filter));
}

export function useActivitiesStudentList(filter: Sa_SchoolActivityFilter) {
  return useQuery({
    ...saStudentListQuery(filter),
    select: ({ sa_activities }) => sa_activities[0]?.customGroup?.students,
  });
}

export type ReturnTypeFromUseSaStudentList = UseQueryReturnType<
  typeof useActivitiesStudentList
>[number];
