import '@tyro/i18n';
import * as Sentry from '@sentry/react';

import { msalInstance } from '@tyro/api';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { AppShell } from './app';

export { useAppShellConfig } from './components/shell/provider';
export * from './assets/illustration_404';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6f0dc905b3566329a24bf2f3ab3efc64@o4505675714658304.ingest.sentry.io/4505680217964544',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/app\.tyro\.school\/api/],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

msalInstance.initialize().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(<AppShell />);
});
