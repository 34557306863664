import { Box } from '@mui/material';
import DOMPurify from 'dompurify';
import { useMemo } from 'react';

interface LinkRenderProps {
  text: string | null | undefined;
}

export function LinkRender({ text }: LinkRenderProps) {
  const parsedText = useMemo(() => {
    if (!text) {
      return '';
    }

    if (typeof text !== 'string') {
      return text;
    }

    // Regular expression for detecting links in the format "[href](linkName)"
    const linkPattern = /\[(.*?)\]\((http[s]?:\/\/\S+)\)/g;
    const textWithCustomLinkParsed = text.replace(
      linkPattern,
      (_, linkName: string, href: string) =>
        `<a href="${href}" rel="noopener noreferrer">${linkName}</a>`,
    );

    // Regular expression for detecting links starting with http or https
    const httpLinkPattern = /(?:\s|^)(http[s]?:\/\/\S+)(?=\s|$|<)/g;
    const finalParsedText = textWithCustomLinkParsed.replace(
      httpLinkPattern,
      (url) => `<a href="${url}" rel="noopener noreferrer">${url}</a>`,
    );

    return DOMPurify.sanitize(finalParsedText, {
      USE_PROFILES: { html: true },
      ALLOWED_TAGS: ['a'],
      ALLOWED_ATTR: ['href', 'target', 'rel'],
    });
  }, [text]);

  const textWithFixedRel = useMemo(() => {
    if (typeof parsedText !== 'string' || parsedText === '') {
      return parsedText;
    }

    return parsedText.replace(
      /rel="noopener noreferrer/g,
      'target="_blank" rel="noopener noreferrer',
    );
  }, [parsedText]);

  return (
    <Box
      component="span"
      sx={{
        '& a': {
          color: 'primary.main',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
      // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
      dangerouslySetInnerHTML={{ __html: textWithFixedRel }}
    />
  );
}
