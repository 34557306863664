import './styles/shell.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  AuthProvider,
  DataProvider,
  PreferencesProvider,
  useAuth,
} from '@tyro/api';
import { LoadingScreen } from '@tyro/core';
import { ThemeProvider } from '@tyro/core';
import { useCurrentLanguage } from '@tyro/i18n';
import { LazyMotion, domMax } from 'motion/react';
import { IdleModal } from './components/idle-modal';
import { Router } from './router';

export * from './components/shell/provider';
export * from './assets/illustration_404';

function AppShellInner() {
  const { isTokenInitialized } = useAuth();

  if (!isTokenInitialized) return <LoadingScreen />;

  return (
    <>
      <Router />
      <IdleModal />
    </>
  );
}

export function AppShell() {
  const { dayjsLocale } = useCurrentLanguage();

  return (
    <DataProvider>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={dayjsLocale}
      >
        <AuthProvider>
          <PreferencesProvider>
            <ThemeProvider>
              <LazyMotion strict features={domMax}>
                <AppShellInner />
                <ReactQueryDevtools buttonPosition="bottom-left" />
              </LazyMotion>
            </ThemeProvider>
          </PreferencesProvider>
        </AuthProvider>
      </LocalizationProvider>
    </DataProvider>
  );
}
