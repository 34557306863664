import { AssessmentType, GradeType } from '@tyro/api';
import type { CellValueChangedEvent, ValueSetterParams } from '@tyro/core';
import set from 'lodash/set';
import type { ReturnTypeFromUseAssessmentResults } from '../api/assessment-results';
import type { ReturnTypeFromUseAssessmentById } from '../api/assessments';
import {
  type ReturnTypeFromUseGrades,
  getGrades,
} from '../api/grade-sets/grades';

function mimicCellValueChanged(
  params: ValueSetterParams<ReturnTypeFromUseAssessmentResults>,
  field: string,
  oldValue: unknown,
  newValue: unknown,
) {
  const column = params.api.getColumn(field);
  const event = {
    ...params,
    column,
    colDef: column?.getColDef(),
    oldValue,
    newValue,
    type: 'cellValueChanged',
  } as CellValueChangedEvent<ReturnTypeFromUseAssessmentResults>;
  params.api.dispatchEvent(event);
}

export async function checkAndSetGrades({
  changedField,
  assessmentData,
  gradeById,
  params,
}: {
  changedField:
    | 'result'
    | 'targetResult'
    | 'gradeId'
    | 'targetGradeId'
    | 'studentStudyLevel'
    | undefined;
  assessmentData: ReturnTypeFromUseAssessmentById;
  gradeById: Map<number, ReturnTypeFromUseGrades>;
  params: ValueSetterParams<ReturnTypeFromUseAssessmentResults>;
}) {
  if (!changedField || params.isEditCheckCall) {
    return;
  }

  const showGrade = [GradeType.GradeSet, GradeType.Both].includes(
    assessmentData.gradeType,
  );
  const showResult = [GradeType.Percentage, GradeType.Both].includes(
    assessmentData.gradeType,
  );
  const showTarget = assessmentData.captureTarget;

  if (
    showGrade &&
    ['result', 'targetResult', 'studentStudyLevel'].includes(changedField) &&
    params.data.studentProgramme?.shortName
  ) {
    const gradeSetId = assessmentData.gradeSets?.[0]?.id ?? 0;
    try {
      const [matchedGrades, matchedTargetGrades] = await Promise.all([
        getGrades({
          gradeSetId,
          studyLevel: params.data.studentStudyLevel,
          result: params.data.result,
          programmeShortName: params.data.studentProgramme.shortName,
        }),
        showTarget
          ? getGrades({
              gradeSetId,
              studyLevel: params.data.studentStudyLevel,
              result: params.data.targetResult,
              programmeShortName: params.data.studentProgramme.shortName,
            })
          : [],
      ]);

      if (
        matchedGrades.length === 1 &&
        matchedGrades[0].id !== params.data.gradeId
      ) {
        const oldValue = params.data.gradeId;
        set(params.data, 'gradeId', matchedGrades[0].id);
        mimicCellValueChanged(params, 'gradeId', oldValue, matchedGrades[0].id);
      }

      if (
        showTarget &&
        matchedTargetGrades.length === 1 &&
        matchedTargetGrades[0].id !== params.data.targetGradeId
      ) {
        const oldValue = params.data.targetGradeId;
        set(params.data, 'targetGradeId', matchedTargetGrades[0].id);
        mimicCellValueChanged(
          params,
          'targetGradeId',
          oldValue,
          matchedTargetGrades[0].id,
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }

  if (['gradeId', 'targetGradeId'].includes(changedField)) {
    const newValue = params.data[changedField as 'gradeId' | 'targetGradeId'];
    const resultKey = changedField === 'gradeId' ? 'result' : 'targetResult';

    if (typeof newValue !== 'number') {
      if (showResult) {
        const oldValue = params.data[resultKey];
        set(params.data, resultKey, undefined);
        mimicCellValueChanged(params, resultKey, oldValue, undefined);
      }
    } else if (gradeById.has(newValue)) {
      const grade = gradeById.get(newValue)!;
      const oldResult = params.data[resultKey];
      const needsResultUpdate =
        !oldResult || oldResult < grade.start || oldResult > grade.end;

      if (
        needsResultUpdate &&
        showResult &&
        (resultKey !== 'targetResult' || showTarget)
      ) {
        set(params.data, resultKey, grade?.end);
        mimicCellValueChanged(params, resultKey, oldResult, grade?.end);
      }

      if (
        assessmentData.assessmentType !== AssessmentType.StateCba &&
        grade?.studyLevels?.length === 1 &&
        grade.studyLevels[0] !== params.data.studentStudyLevel
      ) {
        const oldValue = params.data.studentStudyLevel;
        set(params.data, 'studentStudyLevel', grade.studyLevels[0]);
        mimicCellValueChanged(
          params,
          'studentStudyLevel',
          oldValue,
          grade.studyLevels[0],
        );
      }
    }

    params.api.applyTransaction({
      update: [params.data],
    });
  }
}
