import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, Stack } from '@mui/material';
import { type Catalogue_UpsertGroupTag, Colour } from '@tyro/api';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  RHFColorPicker,
  RHFTextField,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpsertCatalogueGroupTags } from '../../api/common/group-tags';

export interface UpsertGroupTagModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialState: Partial<Catalogue_UpsertGroupTag> | null;
}

interface UpsertGroupFormValues {
  name: string;
  description: string;
  colour: Colour;
}

export function UpsertGroupTagModal({
  isOpen,
  onClose,
  initialState,
}: UpsertGroupTagModalProps) {
  const { t } = useTranslation(['common', 'groups']);

  const { resolver, rules } = useFormValidator<UpsertGroupFormValues>();

  const { control, handleSubmit, reset } = useForm<UpsertGroupFormValues>({
    resolver: resolver({
      name: rules.required(),
      colour: rules.required(),
    }),
    defaultValues: {
      colour: Colour.Red,
    },
  });

  const { mutateAsync: upsertGroupTag, isPending: isSubmitting } =
    useUpsertCatalogueGroupTags();

  const onSubmit = handleSubmit(({ name, description, colour }) => {
    upsertGroupTag(
      [
        {
          ...(initialState as Catalogue_UpsertGroupTag),
          name: [
            {
              value: name,
              locale: 'en',
            },
          ],
          description: [
            {
              value: description,
              locale: 'en',
            },
          ],
          colour,
        },
      ],
      {
        onSuccess: onClose,
      },
    );
  });

  useEffect(() => {
    if (initialState) {
      const { name, description, colour } = initialState;
      const nameValue =
        Array.isArray(name) && name.length > 0 ? name[0]?.value ?? '' : '';
      const descriptionValue =
        Array.isArray(description) && description.length > 0
          ? description[0]?.value ?? ''
          : '';

      reset({
        name: nameValue,
        description: descriptionValue,
        colour: colour ?? Colour.Red,
      });
    }
  }, [initialState]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle onClose={onClose}>
        {initialState?.id
          ? t('groups:editGroupTag')
          : t('groups:createGroupTag')}
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Stack spacing={2} mt={1}>
            <RHFTextField
              label={t('common:name')}
              controlProps={{
                control,
                name: 'name',
              }}
            />
            <RHFTextField
              label={t('common:description')}
              controlProps={{
                control,
                name: 'description',
              }}
              textFieldProps={{
                fullWidth: true,
                multiline: true,
                minRows: 3,
              }}
            />
            <RHFColorPicker
              label={t('common:colour')}
              controlProps={{
                control,
                name: 'colour',
              }}
              pickerProps={{
                sx: { pb: 2 },
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack spacing={2} direction="row">
            <Button onClick={onClose} color="inherit" variant="outlined">
              {t('common:actions.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t('common:actions.save')}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
}
