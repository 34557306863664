import DOMPurify from 'dompurify';

function addSpacesToClosingTags(inputString: string) {
  const closingTagPattern = /<\/\w+>/g;
  return inputString.replace(closingTagPattern, (match) => `${match} `);
}

function removeThTags(inputString: string) {
  return inputString.replace(/<th\b[^>]*>.*?<\/th>/g, '');
}

function decodeHTMLEntities(inputString: string) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = inputString;
  return textarea.value;
}

export function createSummary(notice: string) {
  let summary = notice;

  summary = removeThTags(summary);
  summary = addSpacesToClosingTags(summary);
  summary = DOMPurify.sanitize(summary, {
    USE_PROFILES: {
      html: false,
    },
  });

  return decodeHTMLEntities(summary);
}
