import { Stack, Typography } from '@mui/material';
import { ConfirmDialog } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  type ReturnTypeOfUseCatalogueGroupTags,
  useDeleteCatalogueGroupTags,
} from '../../api/common/group-tags';

export interface DeleteGroupTagModalProps {
  isOpen: boolean;
  onClose: () => void;
  group: Pick<ReturnTypeOfUseCatalogueGroupTags, 'id' | 'name'> | null;
}

export function DeleteGroupTagModal({
  isOpen,
  onClose,
  group,
}: DeleteGroupTagModalProps) {
  const { t } = useTranslation(['common', 'timetable']);

  const { mutateAsync } = useDeleteCatalogueGroupTags();

  const onSubmit = () =>
    mutateAsync(
      {
        ids: group?.id ? [group.id] : [],
      },
      {
        onSuccess: onClose,
      },
    );

  return (
    <ConfirmDialog
      open={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
      isDelete
      title={t('timetable:deleteGroupCategory')}
      content={
        <Stack spacing={3}>
          <Typography color="text.secondary">
            {t('timetable:deleteGroupCategoryDescription', {
              name: group?.name,
            })}
          </Typography>
          <Typography color="text.secondary">
            {t('timetable:deleteGroupCategoryNote')}
          </Typography>
        </Stack>
      }
      confirmText={t('common:actions.delete')}
    />
  );
}
