import { Chip } from '@mui/material';
import type { Timeline_EventType } from '@tyro/api';
import { Autocomplete } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import { timelineIconMap } from './item-icon';

interface TimelineTypeFilterProps {
  value: Timeline_EventType[];
  onChange: (value: Timeline_EventType[]) => void;
}

export function TimelineTypeFilter({
  value,
  onChange,
}: TimelineTypeFilterProps) {
  const { t } = useTranslation(['common']);

  const options = useMemo(() => {
    return Array.from(timelineIconMap.entries())
      .map(([type, { color, icon }]) => ({
        type,
        color,
        icon,
        label: t(`common:timelineEventType.${type}`),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [t]);

  const mappedValue = useMemo(
    () =>
      value
        .map((type) => options.find((option) => option.type === type))
        .filter(Boolean) as typeof options,
    [options, value],
  );

  return (
    <Autocomplete
      label={t('common:typeOfEvent')}
      options={options}
      value={mappedValue}
      onChange={(_, newValue) => {
        const typedValue = newValue as typeof options;
        onChange(typedValue?.map(({ type }) => type) ?? []);
      }}
      multiple
      renderTags={(tags, getTagProps) =>
        tags.map((tag, index) => {
          const { key, ...rest } = getTagProps({ index });
          return (
            <Chip
              key={key}
              {...rest}
              size="small"
              variant="soft"
              color={tag.color}
              icon={<tag.icon />}
              label={tag.label}
            />
          );
        })
      }
    />
  );
}
