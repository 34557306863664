import { ToggleButtonGroup } from '@mui/material';
import type { Editor } from '@tiptap/react';

import { useTranslation } from '@tyro/i18n';
import { RoundRedoIcon, RoundUndoIcon } from '@tyro/icons';
import { getMetaCharacter } from '../../../../utils';
import { ToggleButton } from '../toolbar-buttons/toggle-button';

interface UndoRedoToggleProps {
  editor: Editor | null;
}

export const UndoRedoToggle = ({ editor }: UndoRedoToggleProps) => {
  const { t } = useTranslation(['templates', 'common']);
  const metaCharacter = getMetaCharacter();

  return (
    <ToggleButtonGroup
      aria-label={t('common:ariaLabelUndoRedo')}
      size="small"
      exclusive
      sx={{
        '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderLeft: 'none',
        },
        border: 'none',
        alignItems: 'center',
        backgroundColor: 'slate.50',
      }}
    >
      <ToggleButton
        title={t('common:tooltipTitles.undo', {
          shortcut: `${metaCharacter} + Z`,
        })}
        onClick={() => editor?.chain().undo().run()}
        value="undo"
      >
        <RoundUndoIcon />
      </ToggleButton>
      <ToggleButton
        title={t('common:tooltipTitles.redo', {
          shortcut: `${metaCharacter} + Y`,
        })}
        onClick={() => editor?.chain().redo().run()}
        value="redo"
      >
        <RoundRedoIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
