import { Chip, Typography } from '@mui/material';
import { useUser } from '@tyro/api';
import { Tab, Tabs } from '@tyro/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useMailSettings } from '../../store/mail-settings';

interface SchoolInboxSelectProps {
  value: number;
}

export function useMailInboxOptions() {
  const { partyUnreadCount, schoolUnreadCount } = useMailSettings();
  const { activeProfile, user } = useUser();

  return useMemo(
    () => [
      {
        value: activeProfile?.partyId ?? 0,
        name: user?.name ?? '',
        avatarUrl: activeProfile?.avatarUrl,
        unreadCount: partyUnreadCount,
      },
      {
        value: -1,
        name: activeProfile?.tenant?.name ?? '',
        avatarUrl: activeProfile?.tenant?.imgUrl,
        unreadCount: schoolUnreadCount,
      },
    ],
    [activeProfile, user, schoolUnreadCount, partyUnreadCount],
  );
}

export function SchoolInboxSwitcher({ value }: SchoolInboxSelectProps) {
  const options = useMailInboxOptions();

  const valueIndex = options?.findIndex((option) => option.value === value);

  return (
    <Tabs
      value={valueIndex}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="Select mailbox"
      sx={{
        '& .MuiTabs-flexContainer': {
          alignItems: 'center',
          marginLeft: 2,
        },
        '& .MuiTabs-flexContainer > .MuiButtonBase-root': {
          marginRight: 3.5,
        },
      }}
    >
      {options?.map((tab) => {
        const tabColour = tab.value === -1 ? 'indigo' : 'rose';
        return (
          <Tab
            key={tab.value}
            component={Link}
            to={tab.value === -1 ? '/mail/school/inbox' : '/mail/inbox'}
            label={
              <>
                <Chip
                  label={tab?.unreadCount}
                  variant="soft"
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: `${tabColour}.100`,
                    borderRadius: '6px',
                    height: '20px',
                    fontWeight: '700',
                    fontSize: '12px',
                    paddingX: '8px',
                    color: `${tabColour}.500`,
                    '& .MuiChip-icon': {
                      color: tabColour ? `${tabColour}.500` : undefined,
                    },
                    '& .MuiChip-label': {
                      padding: 0,
                    },
                  }}
                />
                <Typography
                  marginLeft={1}
                  sx={{
                    fontWeight: '600',
                    fontSize: '14px',
                  }}
                  noWrap
                >
                  {tab.name}
                </Typography>
              </>
            }
          />
        );
      })}
    </Tabs>
  );
}
