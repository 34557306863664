import { Box, Tooltip } from '@mui/material';
import { Colour, Timeline_EventType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import {
  ClockIcon,
  CloseIcon,
  DocEditIcon,
  FolderIcon,
  MessageResponseWarningIcon,
  NoteWithClipIcon,
  UserPenCircleIcon,
} from '@tyro/icons';

export interface TimelineItemIcon {
  type: Timeline_EventType;
}

export const timelineIconMap = new Map<
  Timeline_EventType,
  {
    icon: typeof DocEditIcon;
    color: Colour | 'indigo';
  }
>([
  [
    Timeline_EventType.BehaviourPositive,
    { icon: MessageResponseWarningIcon, color: Colour.Green },
  ],
  [
    Timeline_EventType.BehaviourNegative,
    { icon: MessageResponseWarningIcon, color: Colour.Rose },
  ],
  [
    Timeline_EventType.BehaviourNeutral,
    { icon: MessageResponseWarningIcon, color: Colour.Blue },
  ],
  [Timeline_EventType.Note, { icon: DocEditIcon, color: Colour.Blue }],
  [
    Timeline_EventType.PriorityNote,
    { icon: UserPenCircleIcon, color: 'indigo' },
  ],
  [Timeline_EventType.Doc, { icon: FolderIcon, color: Colour.Blue }],
  [Timeline_EventType.Form, { icon: NoteWithClipIcon, color: Colour.Amber }],
  [Timeline_EventType.Late, { icon: ClockIcon, color: Colour.Sky }],
  [Timeline_EventType.Absent, { icon: CloseIcon, color: Colour.Pink }],
]);

export function TimelineItemIcon({ type }: TimelineItemIcon) {
  const { t } = useTranslation(['common']);
  const { icon: Icon, color } = timelineIconMap.get(type) ?? {
    icon: DocEditIcon,
    color: Colour.Blue,
  };

  return (
    <Tooltip title={t(`common:timelineEventType.${type}`)}>
      <Box
        className="timeline-item-icon"
        sx={({ palette }) => ({
          backgroundColor: palette[color].main,
        })}
      >
        <Icon />
      </Box>
    </Tooltip>
  );
}
