import { useQuery } from '@tanstack/react-query';

import {
  type Tt_RoomAssignmentFilter,
  type Tt_RoomAssignmentStatsQuery,
  type Tt_RoomAssignmentsQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { naturalSort } from '@tyro/core';
import { useCallback } from 'react';
import { timetableKeys } from '../../keys';

const roomAssignmentStats = graphql(/* GraphQL */ `
  query tt_roomAssignmentStats($filter: Tt_RoomAssignmentFilter!) {
    tt_roomAssignments(filter: $filter) {
      assignments {
        roomId
        maxLoadPeriods
        currentLoadPeriods
      }
    }
  }
`);

const roomAssignments = graphql(/* GraphQL */ `
  query tt_roomAssignments($filter: Tt_RoomAssignmentFilter!) {
    tt_roomAssignments(filter: $filter) {
      solverStatus {
        solverStatus
        score
      }
      stats {
        roomCountTotal
        roomsOverAllocatedCount
        roomsFullyAllocatedCount
        roomsUnderAllocatedCount
        mainstreamCount
        mainstreamRoomsAssignedCount
        mainstreamRoomsUnassignedCount
        freeformCount
        freeformRoomsAssignedCount
        freeformRoomsUnassignedCount
        nonTeachingCount
        nonTeachingRoomsAssignedCount
        nonTeachingRoomsUnassignedCount
      }
      assignments {
        roomId
        room {
          name
          capacity
          pools {
            id
            name
            colour
          }
        }
        maxLoadPeriods
        currentLoadPeriods
        lessonsAssigned
        mainstream {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        freeform {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        nonTeaching {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
      }
      unassigned {
        maxLoadPeriods
        currentLoadPeriods
        lessonsAssigned
        mainstream {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        freeform {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
        nonTeaching {
          lessonsAssigned
          lessonsTotal
          splitIndicator
          isMultipleOTeachersOnLesson
          group {
            timetableGroupId
            name
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            subject {
              id
              name
              colour
              nationalCode
            }
            groupType
            studentMembershipType
            groupTagIds
            spread
            lessonCount
            blockIdx
            blockId
            classGroupId
            room {
              roomId
              name
              pools {
                id
                name
              }
            }
            roomPool {
              id
              name
            }
            lessons {
              id {
                lessonIdx
                lessonInstanceIdx
                timetableGroupId
              }
              timeslotId {
                gridIdx
                dayIdx
                periodIdx
              }
              timeslotInfo {
                startTime
                endTime
                name
                dayOfWeek
              }
              teachers {
                person {
                  partyId
                  title {
                    id
                    nameTextId
                    name
                  }
                  firstName
                  lastName
                  avatarUrl
                  type
                  archived
                }
              }
              room {
                roomId
                name
                pools {
                  id
                  name
                }
              }
              roomPool {
                id
                name
              }
              spread
            }
            maxClassSize
            coScheduleTtGroup {
              timetableGroupId
              name
            }
          }
        }
      }
    }
  }
`);

const roomAssignmentStatsQuery = (filter: Tt_RoomAssignmentFilter) => ({
  queryKey: timetableKeys.roomAssignmentStats(filter),
  queryFn: async () => gqlClient.request(roomAssignmentStats, { filter }),
});

export function useRoomAssignmentStats(filter: Tt_RoomAssignmentFilter) {
  return useQuery({
    ...roomAssignmentStatsQuery(filter),
    select: useCallback(
      ({ tt_roomAssignments }: Tt_RoomAssignmentStatsQuery) =>
        new Map(
          tt_roomAssignments.assignments.map((assignment) => [
            assignment.roomId,
            assignment,
          ]),
        ),
      [],
    ),
  });
}

const roomAssignmentsQuery = (filter: Tt_RoomAssignmentFilter) => ({
  queryKey: timetableKeys.roomAssignments(filter),
  queryFn: async () => gqlClient.request(roomAssignments, { filter }),
});

export async function getRoomAssignments(filter: Tt_RoomAssignmentFilter) {
  return queryClient.fetchQuery(roomAssignmentsQuery(filter));
}

export function useRoomAssignments(filter: Tt_RoomAssignmentFilter) {
  return useQuery({
    ...roomAssignmentsQuery(filter),
    select: useCallback(
      ({ tt_roomAssignments }: Tt_RoomAssignmentsQuery) => ({
        ...tt_roomAssignments,
        assignments: tt_roomAssignments.assignments.map((assignment) => ({
          ...assignment,
          subjectGroups: assignment.mainstream.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          supportGroups: assignment.freeform.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          nonTeachingGroups: assignment.nonTeaching.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
        })),
        unassigned: {
          ...tt_roomAssignments.unassigned,
          mainstream: tt_roomAssignments.unassigned.mainstream.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          freeform: tt_roomAssignments.unassigned.freeform.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
          nonTeaching: tt_roomAssignments.unassigned.nonTeaching.sort(
            ({ group: groupA }, { group: groupB }) =>
              naturalSort(groupA.name, groupB.name),
          ),
        },
      }),
      [],
    ),
  });
}

export type ReturnTypeFromUseRoomAssignments = UseQueryReturnType<
  typeof useRoomAssignments
>;
