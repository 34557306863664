import type {
  Catalogue_SystemSettingsFilter,
  FileTransferFilter,
} from '../gql/graphql';

export const coreApiKeys = {
  all: ['core'] as const,
  yearGroups: {
    all: () => [...coreApiKeys.all, 'yearGroups'] as const,
  },
  academicNamespaces: {
    all: () => [...coreApiKeys.all, 'academicNamespaces'] as const,
  },
  programmeStages: {
    all: () => [...coreApiKeys.all, 'programmeStages'] as const,
  },
  allFileTransferList: () => [...coreApiKeys.all, 'fileTransferList'] as const,
  fileTransferList: (filter: FileTransferFilter) =>
    [...coreApiKeys.allFileTransferList(), filter] as const,
  settingsValues: (filter: Catalogue_SystemSettingsFilter) =>
    [...coreApiKeys.all, 'settingsValues', filter] as const,
};
