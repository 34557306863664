export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
      },
    },
  };
}
