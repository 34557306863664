import { Catalogue_SystemSettingsViewType } from '@tyro/api';
import { getSettingsValues, useSettingsValues } from '../api/settings-values';

export async function getPersonalSettings() {
  const { catalogue_systemSettingsValues: settingsValues } =
    await getSettingsValues({
      type: Catalogue_SystemSettingsViewType.Personal,
      module: [],
    });

  return settingsValues;
}

export const usePersonalSettings = () => {
  const { data, ...restQueryData } = useSettingsValues({
    type: Catalogue_SystemSettingsViewType.Personal,
    module: [],
  });

  return { ...data, ...restQueryData };
};
