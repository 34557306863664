import { Box, Stack, Typography } from '@mui/material';
import { useAcademicNamespace } from '@tyro/api';
import { getClassesFromObject } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { SchoolBuildingIcon } from '@tyro/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { forwardRef, useMemo } from 'react';

dayjs.extend(LocalizedFormat);
dayjs.extend(isBetween);

interface SectionHeaderCommonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  headerValue: string;
  style: React.CSSProperties;
}

export type SectionHeaderProps = SectionHeaderCommonProps &
  (
    | {
        groupBy: 'week';
      }
    | {
        groupBy: 'month';
        yearStart?: string;
      }
  );

export const TimelineSectionHeader = forwardRef<
  HTMLDivElement,
  SectionHeaderProps
>(({ groupBy, headerValue, ...props }, ref) => {
  const { t } = useTranslation(['common']);
  const { allNamespaces } = useAcademicNamespace();

  const config = useMemo(() => {
    const sectionDate = dayjs(headerValue);

    if (groupBy === 'week') {
      const matchedYear = allNamespaces!.find((namespace) =>
        sectionDate.isBetween(
          dayjs(namespace.startDate).startOf('week'),
          dayjs(namespace.endDate).endOf('week'),
          null,
          '[]',
        ),
      );

      if (!matchedYear) {
        return {
          leftString: null,
          rightString: t('common:startsDate', {
            date: sectionDate.format('LL'),
          }),
          isStartOfYear: false,
          isEndOfYear: false,
          matchedYear: undefined,
        };
      }

      const diffFromYearStart =
        sectionDate
          .startOf('week')
          .diff(dayjs(matchedYear.startDate).startOf('week'), 'week') + 1;
      return {
        leftString: t('common:weekX', { week: diffFromYearStart }),
        rightString: t('common:startsDate', {
          date: sectionDate.format('LL'),
        }),
        isStartOfYear: diffFromYearStart === 1,
        isEndOfYear: dayjs(headerValue).isSame(matchedYear.endDate, 'week'),
        matchedYear,
      };
    }

    return {
      leftString: null,
      rightString: sectionDate.format('MMMM'),
      isStartOfYear: false,
      isEndOfYear: false,
      matchedYear: undefined,
    };
  }, [headerValue, allNamespaces, groupBy]);

  return (
    <div
      ref={ref}
      {...props}
      role="presentation"
      className={getClassesFromObject({
        'timeline-section-header': true,
        'start-of-year': config.isStartOfYear,
        'end-of-year': config.isEndOfYear,
      })}
      style={{
        justifyContent: config.leftString ? 'space-between' : 'flex-end',
        ...props.style,
      }}
    >
      {config.leftString && (
        <Typography variant="subtitle2" component="span">
          {config.leftString}
        </Typography>
      )}

      {config.matchedYear && (config.isStartOfYear || config.isEndOfYear) && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle2" component="span">
            {config.isStartOfYear
              ? t('common:schoolStartsDate', {
                  date: dayjs(config.matchedYear.startDate).format('LL'),
                })
              : t('common:schoolEndsDate', {
                  date: dayjs(config.matchedYear.endDate).format('LL'),
                })}
          </Typography>
          <Box
            sx={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: 'white',
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <SchoolBuildingIcon sx={{ width: 20, height: 20 }} />
          </Box>
        </Stack>
      )}

      {!config.matchedYear ||
        (!config.isStartOfYear && !config.isEndOfYear && (
          <Typography variant="subtitle2" component="span">
            {config.rightString}
          </Typography>
        ))}
    </div>
  );
});
