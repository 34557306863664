import { Box, Button, Card, Chip, Stack, Typography } from '@mui/material';
import {
  Avatar,
  DialogCloseButton,
  PreferredNameFormat,
  getBaseColorBasedOnString,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { DownloadArrowIcon } from '@tyro/icons';
import type { TimelineItemProps } from '../item';
import { TimelineItemIcon } from '../item-icon';
import { getEventDateTimes } from '../utils';

const behaviourColorByType = new Map<string, 'slate' | 'rose' | 'green'>([
  ['Positive', 'green'],
  ['Negative', 'rose'],
  ['Neutral', 'slate'],
]);

interface TimelinePreviewerCardProps {
  data: TimelineItemProps['event'];
  onClose: () => void;
}

function CreatedByUser({
  label,
  createdBy,
}: {
  label: string;
  createdBy: NonNullable<TimelineItemProps['event']['createdBy']>;
}) {
  const { displayName } = usePreferredNameLayout();
  const name = displayName(createdBy, {
    format: PreferredNameFormat.FirstnameSurname,
  });
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Typography variant="caption" component="dt">
        {label}
      </Typography>
      <Stack component="dd" direction="row" alignItems="center" spacing={0.5}>
        <Avatar
          name={name}
          src={createdBy.avatarUrl}
          person={createdBy}
          size={24}
        />
        <Typography variant="caption" fontWeight={600} color="text.primary">
          {name}
        </Typography>
      </Stack>
    </Stack>
  );
}

function EventDetails({ data }: Pick<TimelinePreviewerCardProps, 'data'>) {
  const { t } = useTranslation(['common', 'attendance', 'people', 'mail']);
  const { headline, details, meta, createdBy } = data;

  switch (meta.__typename) {
    case 'Timeline_EventMeta_Absent':
    case 'Timeline_EventMeta_Late': {
      return (
        <Stack
          component="dl"
          spacing={2}
          alignItems="flex-start"
          sx={{ mt: 2, mb: 0.5, mx: 1 }}
        >
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('attendance:attendanceCode')}
            </Typography>
            <Typography variant="body2" component="dd">
              {headline}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('attendance:bellTime')}
            </Typography>
            <Typography variant="body2" component="dd">
              {details}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('attendance:note')}
            </Typography>
            <Typography variant="body2" component="dd">
              {meta?.note ?? '-'}
            </Typography>
          </Box>
          {!!createdBy && (
            <CreatedByUser label={t('common:takenBy')} createdBy={createdBy} />
          )}
        </Stack>
      );
    }
    case 'Timeline_EventMeta_Behaviour': {
      return (
        <Stack
          component="dl"
          spacing={2}
          alignItems="flex-start"
          sx={{ mt: 2, mb: 0.5, mx: 1 }}
        >
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('people:behaviourType')}
            </Typography>
            <Typography variant="body2" component="dd">
              {headline}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('common:details')}
            </Typography>
            <Typography variant="body2" component="dd">
              {details ?? '-'}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('people:tags')}
            </Typography>
            <Typography variant="body2" component="dd">
              {meta?.tag ? (
                <Chip
                  size="small"
                  label={meta?.tag}
                  variant="soft"
                  color={behaviourColorByType.get(meta?.type!) ?? 'slate'}
                />
              ) : (
                '-'
              )}
            </Typography>
          </Box>
          {!!createdBy && (
            <CreatedByUser label={t('common:loggedBy')} createdBy={createdBy} />
          )}
        </Stack>
      );
    }
    case 'Timeline_EventMeta_Note': {
      return (
        <Stack
          component="dl"
          spacing={2}
          alignItems="flex-start"
          sx={{ mt: 2, mb: 0.5, mx: 1 }}
        >
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('people:note')}
            </Typography>
            <Typography variant="body2" component="dd">
              {details}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" component="dt">
              {t('common:label')}
            </Typography>
            <Typography variant="body2" component="dd">
              {meta?.tag ? (
                <Chip
                  size="small"
                  label={meta?.tag}
                  variant="soft"
                  color={getBaseColorBasedOnString(meta?.tag)}
                />
              ) : (
                '-'
              )}
            </Typography>
          </Box>
          {createdBy && (
            <CreatedByUser label={t('common:loggedBy')} createdBy={createdBy} />
          )}
        </Stack>
      );
    }
    case 'Timeline_EventMeta_StudentDocs': {
      return (
        <Stack
          component="dl"
          spacing={2}
          alignItems="flex-start"
          sx={{ mt: 2, mb: 0.5, mx: 1 }}
        >
          <Box>
            <Typography variant="subtitle1">{t('mail:fileName')}</Typography>
            <Typography variant="body2">{headline}</Typography>
          </Box>
          <Button
            component="a"
            endIcon={<DownloadArrowIcon />}
            href={meta.link}
            variant="soft"
          >
            {t('common:download')}
          </Button>
          {createdBy && (
            <CreatedByUser
              label={t('common:uploadedBy')}
              createdBy={createdBy}
            />
          )}
        </Stack>
      );
    }
  }

  return null;
}

export function TimelinePreviewerEventCardContent({
  data,
  onClose,
}: TimelinePreviewerCardProps) {
  const { t } = useTranslation(['common']);
  const { monthTimestamp } = getEventDateTimes(data);

  if (!data.id.entityType) return null;

  return (
    <Card sx={{ p: 1 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <TimelineItemIcon type={data.id.entityType} />
          <Typography variant="subtitle1" component="h3">
            {t(`common:timelineEventType.${data.id.entityType}`)}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption" color="text.secondary">
            {monthTimestamp}
          </Typography>
          <DialogCloseButton onClick={onClose} />
        </Stack>
      </Stack>
      <EventDetails data={data} />
    </Card>
  );
}
