import { FileTransferFeature, queryClient, useUploadFile } from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { mailKeys } from '../keys';

export function useUploadMailFile() {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  return useUploadFile({
    documentType: FileTransferFeature.MailAttachment,
    additionalFormValues: {
      overwrite: 'false',
    },
    mutationOptions: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: mailKeys.all });
      },
      onError: () => {
        toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
      },
    },
  });
}
