import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  type CardProps,
  Chip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import type { Colour } from '@tyro/api';
import { getBaseColorBasedOnString, useContrastClass } from '@tyro/core';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as m from 'motion/react-m';
import { useMemo } from 'react';
import type { ReturnTypeFromUseNoticeBoard } from '../../api/notice-board';
import { createSummary } from '../../utils/sanitize-html';
dayjs.extend(LocalizedFormat);

export interface NoticeCardProps extends Omit<CardProps, 'onClick'> {
  notice: ReturnTypeFromUseNoticeBoard;
  size: 'small' | 'large';
  className?: string;
  onClick: (notice: ReturnTypeFromUseNoticeBoard) => void;
  isNoticeboardOpen: boolean;
  collapsedTransforms:
    | {
        x: number;
        y: number;
      }
    | undefined;
}

function NoticeCardHeader({
  notice,
  noticeColor,
}: Pick<NoticeCardProps, 'notice'> & { noticeColor: Colour }) {
  const { palette } = useTheme();
  const noticeColorPalette = palette[noticeColor];
  // const { validFrom, publishedOn } = notice;
  const { validFrom } = notice;
  const date = dayjs(validFrom).format('L');

  const backgroundImageUrl = notice?.photoUrl ?? undefined;

  const noticeContrastClass = useContrastClass(
    noticeColorPalette[200],
    backgroundImageUrl,
  );

  const gradient = `linear-gradient(90deg, ${noticeColorPalette[100]} 0%, ${noticeColorPalette[300]} 100%)`;

  return (
    <Box
      className={`header ${noticeContrastClass}`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        background: backgroundImageUrl
          ? `url(${backgroundImageUrl}), ${gradient}`
          : gradient,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        borderRadius: 1.75,

        '& .date': {
          backdropFilter: 'blur(16px)',
        },

        '&.light': {
          '& .date': {
            backgroundColor: alpha(palette.common.white, 0.7),
            color: 'text.primary',
          },
        },
        '&.dark': {
          '& .date': {
            backgroundColor: alpha(palette.common.black, 0.7),
            color: 'white',
          },
        },
      }}
    >
      <Box
        sx={{
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          px: 0.75,
        }}
      >
        <Chip className="date" size="small" label={date} />
        {/* Hiding to figure out what tags make sense for users */}
        {/* <NoticeTags
          assignedToParties={notice.assignedToParties}
          color={noticeColor}
        /> */}
      </Box>
    </Box>
  );
}

const paragraphLineHeight = 22;

export function NoticeCard({
  notice,
  size,
  className,
  onClick,
  isNoticeboardOpen,
  collapsedTransforms,
}: NoticeCardProps) {
  const noticeBaseColor = getBaseColorBasedOnString(notice.title);

  const contentString = useMemo(
    () => createSummary(notice.notice),
    [notice.notice],
  );

  return (
    <Card
      component={m.div}
      animate={{
        width: isNoticeboardOpen ? 'auto' : 24,
        height: isNoticeboardOpen ? 'auto' : 24,
        ...(isNoticeboardOpen ? { x: 0, y: 0 } : (collapsedTransforms ?? {})),
      }}
      transition={{ duration: 0.6 }}
      className={`${size} ${className}`}
      sx={{
        borderColor: `${noticeBaseColor}.200`,

        '& .summary-container': {
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },

        '&.small': {
          '& .header': {
            height: 40,
          },
          '& .summary-container': {
            maxHeight: paragraphLineHeight * 2,
            WebkitLineClamp: '2',
          },
        },
        '&.large': {
          '& .header': {
            height: 160,
          },
          '& .summary-container': {
            maxHeight: paragraphLineHeight * 4,
            WebkitLineClamp: '4',
          },
        },
      }}
    >
      <CardActionArea
        onClick={() => onClick(notice)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'stretch',
          p: 0.5,
          minWidth: 200,
        }}
      >
        <NoticeCardHeader notice={notice} noticeColor={noticeBaseColor} />
        <CardContent sx={{ flex: 1, width: '100%', p: 1.5 }}>
          <Typography
            variant="subtitle1"
            component="h3"
            color={`${noticeBaseColor}.700`}
          >
            {notice.title}
          </Typography>
          <Box className="summary-container">
            <Typography variant="body2" color="textSecondary">
              {contentString}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
