import { useQuery } from '@tanstack/react-query';
import { queryClient } from '@tyro/api';
import { type ReturnOfUseToast, useToast } from '@tyro/core';
import { type TFunction, useTranslation } from '@tyro/i18n';
import type { CourseReturnType } from '../utils/types';
import { tyroTuitionConfigKeys } from './keys';
import {
  getTyroTuitionConfig,
  useTyroTuitionConfig,
} from './tyro-tuition-config';

const tyroTuitionQuery = (
  path: string,
  { url, token }: { url: string; token: string },
  uiOptions?: { t: TFunction<'common'[]>; toast: ReturnOfUseToast['toast'] },
) => ({
  queryKey: tyroTuitionConfigKeys.cmsRequest(path),
  queryFn: async () => {
    try {
      const response = await fetch(`${url}${path}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      return response.json();
    } catch (error) {
      if (uiOptions) {
        const { toast, t } = uiOptions;
        toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
      }
    }
  },
});

export async function getTyroTuition(path: string) {
  const { composite_cmsConfig: config } = await getTyroTuitionConfig();
  return queryClient.fetchQuery(tyroTuitionQuery(path, config!));
}

export function useTyroTuition(path: string) {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);
  const { data: config } = useTyroTuitionConfig();
  const { url, token } = config ?? {};

  return useQuery<CourseReturnType>({
    ...tyroTuitionQuery(
      path,
      {
        url: url ?? '',
        token: token ?? '',
      },
      { t, toast },
    ),
    enabled: Boolean(url && token),
  });
}
