import {
  Box,
  Card,
  Chip,
  type ChipProps,
  List,
  ListItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import {
  AssessmentType,
  StateCbaType,
  useAcademicNamespace,
  usePermissions,
} from '@tyro/api';
import {
  ActionMenu,
  type BasicListNavigatorMenuItemParams,
  type GridOptions,
  type ICellRendererParams,
  ListNavigatorType,
  PageContainer,
  PageHeading,
  type ReturnTypeDisplayName,
  RouterLink,
  Table,
  TableBooleanValue,
  type ValueGetterParams,
  commonActionMenuProps,
  useListNavigatorSettings,
  usePreferredNameLayout,
} from '@tyro/core';
import { type TFunction, useTranslation } from '@tyro/i18n';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  type ReturnTypeFromUseAssessments,
  useAssessments,
} from '../api/assessments';
import { AcademicYearDropdown } from '../components/common/academic-year-dropdown';
import { AssessmentActionMenu } from '../components/list-assessments/assessment-action-menu';
import { getAssessmentSubjectGroupsLink } from '../utils/get-assessment-subject-groups-link';

dayjs.extend(LocalizedFormat);

const stateCbaColourMap = {
  [StateCbaType.Cba_1]: 'sky',
  [StateCbaType.Cba_2]: 'violet',
  [StateCbaType.ShortCourse]: 'red',
} as const;

const getColumnDefs = (
  translate: TFunction<
    ('assessments' | 'common')[],
    undefined,
    ('assessments' | 'common')[]
  >,
  onBeforeNavigate: () => void,
  displayName: ReturnTypeDisplayName,
  assessmentTypeValue: AssessmentType,
): GridOptions<ReturnTypeFromUseAssessments>['columnDefs'] => [
  {
    field: 'name',
    headerName: translate('common:name'),
    lockVisible: true,
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessments>) =>
      data && (
        <RouterLink
          onClick={onBeforeNavigate}
          to={getAssessmentSubjectGroupsLink(
            data.id,
            data.assessmentType,
            data.academicNamespaceId,
          )}
        >
          {data.name}
        </RouterLink>
      ),
  },
  {
    colId: 'assessmentType',
    headerName: translate('common:type'),
    enableRowGroup: true,
    valueGetter: ({ data }) =>
      data?.assessmentType
        ? translate(`assessments:assessmentTypes.${data?.assessmentType}`)
        : '-',
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessments>) => {
      if (!data?.assessmentType) return null;

      if (
        data.assessmentType === AssessmentType.StateCba &&
        !data.stateCbaType
      ) {
        return null;
      }

      const chipConfig: Record<AssessmentType, ChipProps> = {
        [AssessmentType.Term]: {
          color: 'emerald',
          label: translate(
            `assessments:assessmentTypes.${AssessmentType.Term}`,
          ),
        },
        [AssessmentType.InClass]: {
          color: 'amber',
          label: translate(
            `assessments:assessmentTypes.${AssessmentType.InClass}`,
          ),
        },
        [AssessmentType.StateCba]: {
          color: stateCbaColourMap[data.stateCbaType!],
          label: translate(`assessments:${data.stateCbaType!}`),
        },
      };

      const { color: chipColor, label: chipLabel } =
        chipConfig[data.assessmentType];

      return (
        <Chip
          size="small"
          variant="soft"
          color={chipColor}
          sx={{
            color: `${chipColor}.500`,
            bgcolor: `${chipColor}.100`,
          }}
          label={chipLabel}
        />
      );
    },
    hide: assessmentTypeValue !== AssessmentType.StateCba,
  },
  {
    colId: 'years',
    headerName: translate('common:year'),
    valueGetter: ({ data }) => {
      if (!data) return '-';

      const isInClassAssessment =
        data?.assessmentType === AssessmentType.InClass;

      const years = isInClassAssessment
        ? data?.subjectGroups?.flatMap((subjectGroup) =>
            subjectGroup?.yearGroups?.map((year) => year?.name),
          )
        : data?.years?.map((year) => year?.name);

      return [...new Set(years?.filter(Boolean))].join(', ');
    },
  },
  {
    field: 'subjectGroups',
    headerName: translate('common:subjectGroups'),
    valueFormatter: ({ data }) => {
      if (!data) return '-';
      return data?.subjectGroups?.length
        ? data.subjectGroups.map((group) => group.name).join(', ')
        : '-';
    },
    hide: assessmentTypeValue === AssessmentType.Term,
  },
  ...(assessmentTypeValue === AssessmentType.Term
    ? [
        {
          field: 'programmeStages',
          headerName: translate('assessments:labels.programmeStages'),
          valueGetter: ({
            data,
          }: ValueGetterParams<ReturnTypeFromUseAssessments>) =>
            data?.programmeStages?.length && data?.programmeStages?.length > 0
              ? data?.programmeStages?.length
              : '-',
          cellRenderer: ({
            data,
            value,
          }: ICellRendererParams<ReturnTypeFromUseAssessments, number>) => {
            const programmeStages = data?.programmeStages;
            const hasProgrammeStages =
              programmeStages && programmeStages?.length > 0;

            return (
              <Tooltip
                title={
                  hasProgrammeStages ? (
                    <List
                      sx={{
                        '& > li': {
                          py: 0,
                          px: 0.5,
                        },
                      }}
                    >
                      {programmeStages?.map(({ name, id }) => (
                        <ListItem key={id}>{name}</ListItem>
                      ))}
                    </List>
                  ) : undefined
                }
                followCursor
                placement="top"
                arrow
              >
                <Box
                  component="span"
                  sx={
                    hasProgrammeStages
                      ? { color: 'primary.main', fontWeight: 600 }
                      : undefined
                  }
                >
                  {value ?? 0}
                </Box>
              </Tooltip>
            );
          },
        } as const,
      ]
    : []),
  {
    field: 'startDate',
    headerName:
      assessmentTypeValue === AssessmentType.InClass
        ? translate('common:date')
        : translate('common:startDate'),
    valueGetter: ({ data }) =>
      data ? dayjs(data.startDate).format('LL') : null,
    sort: 'desc',
    comparator: (dateA: string, dateB: string) =>
      dayjs(dateA).unix() - dayjs(dateB).unix(),
  },
  ...(assessmentTypeValue !== AssessmentType.InClass
    ? [
        {
          field: 'endDate',
          headerName: translate('common:endDate'),
          valueGetter: ({
            data,
          }: ValueGetterParams<ReturnTypeFromUseAssessments>) =>
            data ? dayjs(data.endDate).format('LL') : null,
          comparator: (dateA: string, dateB: string) =>
            dayjs(dateA).unix() - dayjs(dateB).unix(),
        } as const,
      ]
    : []),
  {
    field: 'createdBy',
    headerName: translate('common:createdBy'),
    valueGetter: ({ data }) => (data ? displayName(data.createdBy) : null),
  },
  {
    colId: 'publishedFrom',
    headerName: translate('assessments:publishedOnline'),
    valueGetter: ({ data }) => {
      if (!data) return null;

      if (
        data.publishedFrom &&
        dayjs(data.publishedFrom).isAfter(dayjs(), 'day')
      ) {
        return translate('common:fromDate', {
          date: dayjs(data.publishedFrom).format('LL'),
        });
      }

      return data.publishedFrom
        ? translate('common:yes')
        : translate('common:no');
    },
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessments>) => {
      if (!data) return null;

      if (
        data.publishedFrom &&
        dayjs(data.publishedFrom).isAfter(dayjs(), 'day')
      ) {
        return translate('common:fromDate', {
          date: dayjs(data.publishedFrom).format('LL'),
        });
      }

      return <TableBooleanValue value={!!data.publishedFrom} />;
    },
  },
  {
    ...commonActionMenuProps,
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessments>) =>
      data && <AssessmentActionMenu {...data} />,
  },
];

const assessmentPaths = {
  'term-assessments': AssessmentType.Term,
  'state-cba': AssessmentType.StateCba,
  'class-assessments': AssessmentType.InClass,
} as const;

export enum AssessmentPaths {
  Term = 'term-assessments',
  StateCba = 'state-cba',
  InClass = 'class-assessments',
}

export default function AssessmentsPage() {
  const { t } = useTranslation(['assessments', 'common']);
  const { typeId: typeIdString } = useParams();
  const typeId = typeIdString as keyof typeof assessmentPaths;
  const assessmentTypeValue = assessmentPaths[typeId || AssessmentPaths.Term];

  const { isStaffUserWithPermission } = usePermissions();
  const { activeAcademicNamespace } = useAcademicNamespace();
  const { displayName } = usePreferredNameLayout();

  const currentActiveYear =
    activeAcademicNamespace?.academicNamespaceId ?? null;

  const [academicNameSpaceId, setAcademicNameSpaceId] = useState<number | null>(
    currentActiveYear,
  );

  const { data: assessmentsData = [], isLoading } = useAssessments({
    academicNameSpaceId: academicNameSpaceId ?? 0,
    assessmentType: assessmentTypeValue,
  });

  const visibleDataRef = useRef<() => ReturnTypeFromUseAssessments[]>(null);

  const { storeList } = useListNavigatorSettings<
    BasicListNavigatorMenuItemParams & { type: AssessmentType }
  >({
    type: ListNavigatorType.Assessment,
  });

  const onBeforeNavigateProfile = useCallback(() => {
    storeList(
      t('assessments:pageHeading.assessments'),
      visibleDataRef.current?.().map(({ id, name, assessmentType }) => ({
        id,
        name,
        type: assessmentType,
        caption: assessmentType
          ? t(`assessments:assessmentTypes.${assessmentType}`)
          : undefined,
      })),
    );
  }, []);

  const columnDefs = useMemo(() => {
    return getColumnDefs(
      t,
      onBeforeNavigateProfile,
      displayName,
      assessmentTypeValue,
    );
  }, [t, onBeforeNavigateProfile, displayName, assessmentTypeValue]);

  const hasPermissionToCreate = isStaffUserWithPermission(
    'ps:1:assessment:write_assessments',
  );
  const hasPermissionToCreateClassAssessment = isStaffUserWithPermission(
    'ps:1:assessment:write_class_assessments',
  );
  const canCreateAssessment =
    (hasPermissionToCreate || hasPermissionToCreateClassAssessment) &&
    academicNameSpaceId === currentActiveYear;

  return (
    <PageContainer title={t('assessments:pageTitle.assessments')}>
      <PageHeading
        title={t('assessments:pageHeading.assessments')}
        titleProps={{ variant: 'h3' }}
        rightAdornment={
          canCreateAssessment && (
            <Box display="flex" alignItems="center">
              <Box>
                <ActionMenu
                  buttonLabel={t('assessments:createAssessment')}
                  menuItems={[
                    {
                      label: t('assessments:term'),
                      navigateTo: './../term-assessments/create',
                      hasAccess: () => hasPermissionToCreate,
                    },
                    {
                      label: t('assessments:assessmentTypes.STATE_CBA'),
                      navigateTo: './../state-cba/create',
                      hasAccess: () => hasPermissionToCreate,
                    },
                    {
                      label: t('assessments:assessmentTypes.IN_CLASS'),
                      navigateTo: './../class-assessments/create',
                      hasAccess: () => hasPermissionToCreateClassAssessment,
                    },
                  ]}
                />
              </Box>
            </Box>
          )
        }
      />
      {academicNameSpaceId && (
        <AcademicYearDropdown
          academicNamespaceId={academicNameSpaceId}
          onChangeAcademicNamespace={setAcademicNameSpaceId}
        />
      )}
      <Card
        variant="soft"
        sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}
      >
        <Tabs
          value={typeId}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={t('assessments:ariaLabelForAssessmentTabs')}
          sx={{
            mx: 2,
          }}
        >
          {Object.entries(assessmentPaths).map(([value, key]) => (
            <Tab
              component={Link}
              to={`./../${value}`}
              key={key}
              value={value}
              label={t(`assessments:assessmentTypes.${key}`)}
            />
          ))}
        </Tabs>
        <Table
          tableId={assessmentTypeValue}
          visibleDataRef={visibleDataRef}
          rowData={assessmentsData}
          columnDefs={columnDefs}
          isLoading={isLoading}
          getRowId={({ data }) => String(data?.id)}
        />
      </Card>
    </PageContainer>
  );
}
