import { DialogTitle, Stack, alpha } from '@mui/material';
import {
  DialogCloseButton,
  FloatCardDraggableContainer,
  FloatingCard,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { LineChartIcon } from '@tyro/icons';
import { AnimatePresence, m } from 'motion/react';
import { useEffect, useMemo, useRef } from 'react';
import type { TimelineItemProps } from '../item';
import { TimelinePreviewerEventCardContent } from './event-card';
import {
  TimelinePreviewerHighlightCardContent,
  type TimelinePreviewerHighlightCardContentProps,
} from './highlight-card';

export interface TimelinePreviewerProps {
  open: boolean;
  onClose: () => void;
  data: (
    | { type: 'event'; data: TimelineItemProps['event'] }
    | {
        type: 'highlight';
        data: TimelinePreviewerHighlightCardContentProps['data'];
      }
  )[];
  onOpenEvent: (event: TimelineItemProps['event']) => void;
  closeCardByIndex: (index: number) => void;
}

export function TimelinePreviewer({
  open,
  onClose,
  data,
  onOpenEvent,
  closeCardByIndex,
}: TimelinePreviewerProps) {
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['common']);

  const selectedEventIds = useMemo(() => {
    return new Set(
      data
        .filter((item) => item.type === 'event')
        .map((item) => JSON.stringify(item.data.id)),
    );
  }, [data]);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [data?.[0]?.data?.id]);

  return (
    <FloatingCard
      open={open}
      onClose={onClose}
      initialHeight={540}
      initialPosition={{
        bottom: 16,
        right: 16,
      }}
      CardProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'indigo.50',
        },
      }}
    >
      <FloatCardDraggableContainer>
        <Stack
          direction="row"
          sx={({ palette }) => ({
            p: 1.5,
            pr: 6.5,
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            position: 'relative',
            backgroundColor: 'white',
            boxShadow: `0px 2px 8px ${palette.blue[100]}`,
          })}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <LineChartIcon
              sx={{ width: 20, height: 20, color: 'violet.600' }}
            />
            <DialogTitle p="0 !important" width="100%">
              {t('common:timelinePreviewer')}
            </DialogTitle>
          </Stack>
          <DialogCloseButton
            onClick={onClose}
            autoFocus
            sx={{
              position: 'absolute',
              right: 12,
            }}
          />
        </Stack>
      </FloatCardDraggableContainer>
      <Stack
        ref={scrollAreaRef}
        sx={({ palette }) => ({
          flex: 1,
          backgroundColor: alpha(palette.blue[50], 0.4),
          p: 2,
          overflowY: 'auto',

          '& > div > .MuiPaper-root': {
            mb: 2,
          },

          '& .timeline-item-icon': {
            width: 26,
            height: 26,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            borderRadius: '50%',

            '& svg': {
              width: 18,
              height: 18,
            },
          },
        })}
      >
        <AnimatePresence>
          {data.map((item, index) => {
            return (
              <m.div
                key={JSON.stringify(item.data.id)}
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
              >
                {item.type === 'highlight' ? (
                  <TimelinePreviewerHighlightCardContent
                    data={item.data}
                    onOpenEvent={onOpenEvent}
                    selectedEventIds={selectedEventIds}
                    onClose={() => closeCardByIndex(index)}
                  />
                ) : (
                  <TimelinePreviewerEventCardContent
                    data={item.data}
                    onClose={() => closeCardByIndex(index)}
                  />
                )}
              </m.div>
            );
          })}
        </AnimatePresence>
      </Stack>
    </FloatingCard>
  );
}
