import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type NoticeAction,
  type NoticeboardFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { noticeBoardKeys } from './keys';

const noticeBoard = graphql(/* GraphQL */ `
  query communications_noticeboard($filter: NoticeboardFilter) {
    communications_noticeboard(filter: $filter) {
      id
      title
      notice
      priority
      validFrom
      validUntil
      photoUrl
      recipients {
        partyId
        acknowledged
        acknowledgedOn
      }
      pinned
    }
  }
`);

const noticeBoardDetails = graphql(/* GraphQL */ `
  query communications_noticeboardDetails($filter: NoticeboardFilter) {
    communications_noticeboard(filter: $filter) {
      id
      title
      notice
      priority
      validFrom
      validUntil
      photoUrl
      allStaff
      allContacts
      allStudents
      assignedToParties {
        id {
          noticeId
          partyId
        },
        staff
        contact
        student
        party {
          __typename
          ... on GeneralGroup {
            name
            partyId
            avatarUrl
          }
          ... on ProgrammeStageEnrollment {
            partyId
            name
          }
          ... on SubjectGroup {
            name
            partyId
            avatarUrl
          }
          ... on YearGroupEnrollment {
            name
            partyId
          }
          ... on Staff {
            partyId
            person {
              partyId
              firstName
              lastName
              avatarUrl
              type
              title {
                id
                name
                nameTextId
              }
            }
          }
          ... on Student {
            partyId
            person {
              partyId
              firstName
              lastName
              avatarUrl
              type
              title {
                id
                name
                nameTextId
              }
            }
          }
          ... on StudentContact {
            partyId
            person {
              partyId
              firstName
              lastName
              avatarUrl
              type
              title {
                id
                name
                nameTextId
              }
            }
          }
        }
      }
      recipients {
        partyId
        acknowledged
        acknowledgedOn
      }
      pinned
      archived
      archivedByPartyId
      archivedBy {
        partyId
        title {
          id
          name
        }
        firstName
        lastName
        avatarUrl
        type
        archived
      }
    }
  }
`);

const acknowledgeNotice = graphql(/* GraphQL */ `
  mutation communications_acknowledgeNotice($input: NoticeAction) {
    communications_acknowledgeNotice(input: $input) {
      success
    }
  }
`);

const noticeBoardQuery = (filter: NoticeboardFilter) => ({
  queryKey: noticeBoardKeys.noticeBoard(filter),
  queryFn: () =>
    gqlClient.request(noticeBoard, {
      filter,
    }),
});

export function getNoticeBoard(filter: NoticeboardFilter) {
  return queryClient.fetchQuery(noticeBoardQuery(filter));
}
export function useNoticeBoard(filter: NoticeboardFilter) {
  return useQuery({
    ...noticeBoardQuery(filter),
    select: ({ communications_noticeboard }) =>
      communications_noticeboard.sort((a, b) => {
        if (a.pinned && !b.pinned) {
          return -1;
        }

        if (!a.pinned && b.pinned) {
          return 1;
        }

        return (b.priority ?? 0) - (a.priority ?? 0);
      }),
  });
}

const noticeBoardDetailsQuery = (filter: NoticeboardFilter) => ({
  queryKey: noticeBoardKeys.noticeBoardDetails(filter),
  queryFn: () =>
    gqlClient.request(noticeBoardDetails, {
      filter,
    }),
});

export function getNoticeBoardDetails(filter: NoticeboardFilter) {
  return queryClient.fetchQuery(noticeBoardDetailsQuery(filter));
}
export function useNoticeBoardDetails(filter: NoticeboardFilter) {
  return useQuery({
    ...noticeBoardDetailsQuery(filter),
    select: ({ communications_noticeboard }) =>
      communications_noticeboard.sort((a, b) => {
        if (a.pinned && !b.pinned) {
          return -1;
        }

        if (!a.pinned && b.pinned) {
          return 1;
        }

        return (b.priority ?? 0) - (a.priority ?? 0);
      }),
  });
}

export function useAcknowledgeNotice() {
  return useMutation({
    mutationFn: async (input: NoticeAction) =>
      gqlClient.request(acknowledgeNotice, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: noticeBoardKeys.all });
    },
  });
}

export type ReturnTypeFromUseNoticeBoard = UseQueryReturnType<
  typeof useNoticeBoard
>[number];

export type ReturnTypeFromUseNoticeBoardDetails = UseQueryReturnType<
  typeof useNoticeBoardDetails
>[number];
