import { useTheme } from '@mui/material';

export function TimelineDashedLine() {
  const { palette } = useTheme();
  return (
    <svg
      width="62"
      height="2"
      viewBox="0 0 62 2"
      fill="none"
      role="presentation"
    >
      <line
        x1="1"
        y1="1"
        x2="61"
        y2="1"
        stroke={palette.indigo.main}
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="2 4"
      />
    </svg>
  );
}
