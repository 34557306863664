import { LoadingButton } from '@mui/lab';
import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Notes_BehaviourType,
  type Person,
  getColorBasedOnIndex,
  usePermissions,
} from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFAutocomplete,
  RHFCheckbox,
  RHFDateTimePicker,
  RHFRadioGroup,
  RHFTextField,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNoteTagsBehaviour } from '../../api/behaviour/behaviour-tags';
import {
  type ReturnTypeFromUseStudentBehaviour,
  useStudentBehaviour,
} from '../../api/behaviour/student-behaviour';
import { useUpsertStudentBehaviour } from '../../api/behaviour/upsert-behaviour';
import {
  type ReturnTypeFromUseStudentSubjectGroups,
  useStudentsSubjectGroups,
} from '../../api/student/overview';
import type { StudentsSelectOption } from '../../api/student/students';
import { useSaveTimelineHighlight } from '../../api/student/timeline';
import { getBehaviourType } from '../../utils/get-behaviour-type';
import { RHFStaffAutocomplete } from '../common/staff-autocomplete';
import { RHFStudentAutocomplete } from '../common/student-autocomplete';
import {
  type CreateHighlightFormState,
  eventTypeByBehaviourType,
} from './create-behaviour-highlight-modal';

export type CreateBehaviourFormState = NonNullable<
  ReturnTypeFromUseStudentBehaviour['behaviours']
>[number] & {
  students: StudentsSelectOption;
  noteId: number | null;
  behaviour: NonNullable<CreateBehaviourFormState['tags']>[number];
  note: string;
  subjects: Pick<
    ReturnTypeFromUseStudentSubjectGroups,
    'name' | 'partyId' | 'subjects'
  >[];
  occurredOn: Dayjs;
  behaviourTypeState: Notes_BehaviourType;
};

type AdditonalHighlightState = {
  createHighlightFromBehaviour: boolean;
} & CreateHighlightFormState;

type FormState = CreateBehaviourFormState & AdditonalHighlightState;

export type UpsertBehaviourModalProps = {
  noteId?: number;
  open: boolean;
  onClose: () => void;
  behaviourId?: number;
  defaultState?: Partial<CreateBehaviourFormState>;
  behaviourType?: Notes_BehaviourType | 'All';
  setBehaviourType?: (type: Notes_BehaviourType) => void;
};

const writeHighlightPermission = 'ps:1:student_timeline:write_highlights';

export function UpsertBehaviourModal({
  open,
  onClose,
  behaviourId,
  defaultState,
  behaviourType = Notes_BehaviourType.Positive,
  setBehaviourType,
}: UpsertBehaviourModalProps) {
  const { t } = useTranslation(['common', 'people']);
  const { hasPermission } = usePermissions();

  const { resolver, rules } = useFormValidator<FormState>();

  const { data: behaviourData, isLoading: isLoadingBehaviourData } =
    useStudentBehaviour(
      { noteIds: [behaviourId ?? 0] },
      {
        enabled: Boolean(open && behaviourId),
      },
    );
  const initialState: Partial<CreateBehaviourFormState> | undefined =
    behaviourData?.behaviours?.[0] ?? defaultState;

  const { control, handleSubmit, reset, watch, setValue } = useForm<FormState>({
    resolver: resolver({
      students: rules.required(),
      occurredOn: rules.required(),
      behaviour: rules.required(),
      title: rules.required(),
      description: rules.required(),
      assignedTo: rules.required(),
    }),
    defaultValues: {
      occurredOn: dayjs(),
      behaviourTypeState: getBehaviourType(initialState?.category),
      createHighlightFromBehaviour: false,
    },
  });

  const [behaviourTypeOption, students, createHighlightFromBehaviour] = watch([
    'behaviourTypeState',
    'students',
    'createHighlightFromBehaviour',
  ]);
  const studentIds = students?.map(({ partyId }) => partyId) ?? [];

  const { data: subjectGroups = [] } = useStudentsSubjectGroups(
    { studentPartyIds: studentIds, subjectGroupIds: [] },
    {
      enabled: open,
    },
  );
  const { data: behaviourTags = [], isLoading: isLoadingBehaviourTags } =
    useNoteTagsBehaviour();

  const { mutate, isPending } = useUpsertStudentBehaviour();
  const { mutate: createHighlight, isPending: isCreatingHighlight } =
    useSaveTimelineHighlight();

  const onSubmit = handleSubmit(
    ({
      subjects,
      occurredOn,
      behaviour,
      note,
      behaviourTypeState,
      ...otherValues
    }) => {
      const subjectIds = subjects?.map((subject) => subject?.partyId);

      mutate(
        [
          {
            id: initialState?.noteId,
            note: note ?? initialState?.details,
            referencedParties: studentIds,
            tags: behaviour?.id ? [behaviour?.id] : [],
            associatedParties: subjectIds ?? [initialState?.associatedPartyIds],
            incidentDate: occurredOn.format('YYYY-MM-DDTHH:mm:ss'),
          },
        ],
        {
          onSuccess: (newBehaviourResponse) => {
            if (setBehaviourType) {
              setBehaviourType(behaviourTypeState);
            }

            if (otherValues.createHighlightFromBehaviour) {
              const { title, description, assignedTo } = otherValues;
              createHighlight(
                {
                  title,
                  highlight: description,
                  assignedToPartyIds: assignedTo.map(({ partyId }) => partyId),
                  studentPartyId: studentIds[0],
                  events: newBehaviourResponse.notes_upsertNotes.map(
                    ({ id, tags }) => {
                      const behaviourType = tags![0]?.behaviourType;
                      const eventType =
                        eventTypeByBehaviourType[behaviourType!];

                      return {
                        eventId: id!.toString(),
                        eventType,
                      };
                    },
                  ),
                },
                {
                  onSuccess: () => {
                    onClose();
                  },
                },
              );
            } else {
              onClose();
            }
          },
        },
      );
    },
  );

  const filterTagsByBehaviourType = useMemo(
    () =>
      behaviourTags?.filter((tag) => tag.behaviourType === behaviourTypeOption),
    [behaviourTypeOption, behaviourTags],
  );

  useEffect(() => {
    const isInitialBehaviourType =
      initialState?.tags?.[0]?.behaviourType === behaviourTypeOption;

    if (isInitialBehaviourType) {
      setValue('behaviour', initialState?.tags?.[0] ?? null);
    } else {
      setValue('behaviour', null);
    }
  }, [behaviourTypeOption, initialState, setValue]);

  useEffect(() => {
    let initStudents = initialState?.students;

    if (!initStudents) {
      initStudents = initialState?.referencedParties ?? [];
    }

    reset({
      ...(initialState ?? {}),
      students: initStudents as Person[],
      subjects: (initialState?.associatedParties ??
        initialState?.subjects) as ReturnTypeFromUseStudentSubjectGroups[],
      behaviour: initialState?.tags?.[0] ?? undefined,
      note: initialState?.details,
      behaviourTypeState: getBehaviourType(initialState?.category),
      occurredOn: dayjs(initialState?.incidentDate || undefined),
      createHighlightFromBehaviour: false,
    });
  }, [initialState, behaviourType]);

  const isCreateHighlightDisabled = studentIds.length > 1;

  useEffect(() => {
    if (isCreateHighlightDisabled) {
      setValue('createHighlightFromBehaviour', false);
    }
  }, [isCreateHighlightDisabled]);

  const isLoading = isLoadingBehaviourTags || isLoadingBehaviourData;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={onSubmit}>
        {isLoading ? (
          <Stack minHeight="40vh" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <DialogTitle onClose={onClose}>
              {initialState?.noteId
                ? t('people:editBehaviour')
                : t('people:createBehaviour')}
            </DialogTitle>
            <DialogContent>
              <Stack gap={3} mt={1}>
                <RHFStudentAutocomplete
                  multiple
                  label={t('common:students')}
                  controlProps={{
                    name: 'students',
                    control,
                  }}
                  limitTags={3}
                />
                <RHFDateTimePicker
                  label={t('common:date')}
                  controlProps={{
                    name: 'occurredOn',
                    control,
                  }}
                />
                <RHFAutocomplete
                  multiple
                  label={t('people:associations')}
                  optionIdKey="partyId"
                  getOptionLabel={(option) =>
                    `${option.subjects[0]?.name} - ${option.name}`
                  }
                  controlProps={{ name: 'subjects', control }}
                  options={subjectGroups}
                  renderTags={(tags, getTagProps) =>
                    tags.map((tag, index) => {
                      const [subject] = tag.subjects || [];
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          key={key}
                          {...tagProps}
                          size="small"
                          variant="soft"
                          color={
                            subject?.colour || getColorBasedOnIndex(tag.partyId)
                          }
                          label={`${subject?.name} - ${tag.name}`}
                        />
                      );
                    })
                  }
                />
                <RHFRadioGroup
                  radioGroupProps={{ sx: { flexDirection: 'row' } }}
                  label={t('people:behaviourType')}
                  options={[
                    Notes_BehaviourType.Positive,
                    Notes_BehaviourType.Negative,
                    Notes_BehaviourType.Neutral,
                  ].map((option) => ({
                    value: option,
                    label: t(`people:behaviourTypes.${option}`),
                  }))}
                  controlProps={{
                    name: 'behaviourTypeState',
                    defaultValue: behaviourType,
                    control,
                  }}
                />
                <RHFAutocomplete
                  fullWidth
                  filterOptions={(options) =>
                    options.filter((option) => option.availableToStaff)
                  }
                  options={filterTagsByBehaviourType || []}
                  label={t('people:tags')}
                  optionIdKey="id"
                  optionTextKey="name"
                  renderTag={(tag, renderTag) => {
                    const chipColor = getColorBasedOnIndex(tag.id);

                    return renderTag(
                      <Chip
                        size="small"
                        variant="soft"
                        color={chipColor}
                        label={tag.name}
                      />,
                    );
                  }}
                  controlProps={{
                    name: 'behaviour',
                    control,
                  }}
                />
                <RHFTextField
                  label={t('common:details')}
                  controlProps={{
                    name: 'note',
                    control,
                  }}
                  textFieldProps={{
                    fullWidth: true,
                    multiline: true,
                    rows: 4,
                  }}
                />

                {hasPermission(writeHighlightPermission) && (
                  <>
                    <Tooltip
                      title={
                        isCreateHighlightDisabled
                          ? t(
                              'people:canOnlyCreateHighlightsForAnIndividualStudent',
                            )
                          : undefined
                      }
                    >
                      <span>
                        <RHFCheckbox
                          label={t('people:createHighlightFromBehaviour')}
                          controlProps={{
                            name: 'createHighlightFromBehaviour',
                            control,
                          }}
                          checkboxProps={{
                            disabled: isCreateHighlightDisabled,
                          }}
                        />
                      </span>
                    </Tooltip>
                    <Collapse in={createHighlightFromBehaviour} unmountOnExit>
                      <Stack gap={3}>
                        <Typography component="h3" variant="subtitle1">
                          {t('people:highlightDetails')}
                        </Typography>
                        <RHFTextField
                          label={t('common:title')}
                          controlProps={{
                            name: 'title',
                            control,
                          }}
                        />
                        <RHFTextField
                          label={t('common:description')}
                          controlProps={{
                            name: 'description',
                            control,
                          }}
                          textFieldProps={{
                            fullWidth: true,
                            multiline: true,
                            rows: 4,
                          }}
                        />
                        <RHFStaffAutocomplete
                          label={t('people:assignedTo')}
                          multiple
                          controlProps={{
                            name: 'assignedTo',
                            control,
                          }}
                        />
                      </Stack>
                    </Collapse>
                  </>
                )}
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button variant="soft" color="inherit" onClick={onClose}>
                {t('common:actions.cancel')}
              </Button>

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isPending || isCreatingHighlight}
              >
                {t('common:actions.save')}
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </form>
    </Dialog>
  );
}
