import type { Person } from '@tyro/api';
import { PreferredNameFormat, type ReturnTypeDisplayName } from '../hooks';
import {
  getBaseColorBasedOnString,
  getColorBasedOnString,
} from './get-color-based-on-string';

type PersonName = Pick<Person, 'firstName' | 'lastName'>;

export function getColorBasedOnPerson(
  displayName: ReturnTypeDisplayName,
  person?: PersonName,
  removedColors?: Parameters<typeof getColorBasedOnString>[1],
) {
  return getColorBasedOnString(
    displayName(person, {
      format: PreferredNameFormat.SurnameFirstname,
    }),
    removedColors,
  );
}

export function getBaseColorBasedOnPerson(
  displayName: ReturnTypeDisplayName,
  person?: PersonName,
  removedColors?: Parameters<typeof getColorBasedOnString>[1],
) {
  return getBaseColorBasedOnString(
    displayName(person, {
      format: PreferredNameFormat.SurnameFirstname,
    }),
    removedColors,
  );
}
