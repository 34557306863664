import { Box, Button, Fade, Stack, Tooltip } from '@mui/material';
import {
  BulkAction,
  FeeStatus,
  PaymentStatus,
  ReceiptType,
  type StudentFeeFilter,
  usePermissions,
} from '@tyro/api';
import {
  ActionMenu,
  type BasicListNavigatorMenuItemParams,
  ConfirmDialog,
  type GridOptions,
  type ICellRendererParams,
  ListNavigatorType,
  type ReturnTypeDisplayName,
  Table,
  TablePersonAvatar,
  commonActionMenuProps,
  useDisclosure,
  useListNavigatorSettings,
  usePreferredNameLayout,
  useToast,
} from '@tyro/core';
import { type TFunction, useFormatNumber, useTranslation } from '@tyro/i18n';
import {
  DiscountIcon,
  EyeIcon,
  InfoCircleIcon,
  ReceiptIcon,
  RemoveDiscountIcon,
  VerticalDotsIcon,
  WalletCheckmarkIcon,
  WalletWithMoneyIcon,
} from '@tyro/icons';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useBulkApplyDiscounts } from '../../api/bulk-apply-discounts';
import {
  type ReturnTypeFromUsePayments,
  usePayments,
} from '../../api/payments';
import { getPrintFees } from '../../api/print-fees';
import {
  type ReturnTypeFromUseStudentFees,
  useStudentFees,
} from '../../api/student-fees';
import { getDiscountName } from '../../utils/get-discount-name';
import {
  BulkAddIndividualDiscountModal,
  type BulkAddIndividualDiscountModalProps,
  DISCOUNT_ID_FOR_ADHOC,
} from '../fees/form/bulk-add-individual-discount-modal';
import { WriteOffBalanceMultipleFeesModal } from '../fees/write-off-balance-multiple-fees-modal';
import { FeeStatusChip } from './fee-status-chip';
import { PayFeesModal } from './pay-fees-modal';

dayjs.extend(LocalizedFormat);

interface StudentFeeTableProps {
  filter: StudentFeeFilter;
}

const getColumnDefs = (
  t: TFunction<('fees' | 'common')[], undefined, ('fees' | 'common')[]>,
  onBeforeNavigate: () => void,
  formatCurrency: ReturnType<typeof useFormatNumber>['formatCurrency'],
  isStaffUser: boolean,
  payments: ReturnTypeFromUsePayments[],
  onPayClick: (feeId: ReturnTypeFromUseStudentFees['id']) => void,
  onPrintReceipt: (fee: ReturnTypeFromUseStudentFees) => void,
  displayName: ReturnTypeDisplayName,
): GridOptions<ReturnTypeFromUseStudentFees>['columnDefs'] => [
  {
    field: 'person',
    headerName: t('fees:for'),
    lockVisible: true,
    valueGetter: ({ data }) => displayName(data?.person),
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseStudentFees>) => {
      if (!data) return null;
      const { person } = data;

      return <TablePersonAvatar person={person} />;
    },
  },
  {
    field: 'feeName',
    headerName: t('fees:feeName'),
  },
  {
    field: 'dueDate',
    headerName: t('fees:dueBy'),
    valueFormatter: ({ data }) =>
      data?.dueDate ? dayjs(data.dueDate).format('LL') : '-',
    sort: 'asc',
  },
  {
    field: 'amount',
    headerName: t('fees:amount'),
    valueFormatter: ({ data }) => formatCurrency(data?.amount ?? 0),
    comparator: (a: number, b: number) => a - b,
    type: 'numericColumn',
  },
  {
    field: 'amountDiscounted',
    headerName: t('fees:discounted'),
    valueFormatter: ({ data }) => formatCurrency(data?.amountDiscounted ?? 0),
    comparator: (a: number, b: number) => a - b,
    type: 'numericColumn',
  },
  {
    field: 'amountWrittenOff',
    headerName: t('fees:amountWrittenOff'),
    valueFormatter: ({ data }) => formatCurrency(data?.amountWrittenOff ?? 0),
    comparator: (a: number, b: number) => a - b,
    type: 'numericColumn',
  },
  {
    field: 'amountDue',
    headerName: t('fees:due'),
    valueFormatter: ({ data }) => formatCurrency(data?.amountDue ?? 0),
    comparator: (a: number, b: number) => a - b,
    type: 'numericColumn',
  },
  {
    field: 'amountPaid',
    headerName: t('fees:amountPaid'),
    valueFormatter: ({ data }) => formatCurrency(data?.amountPaid ?? 0),
    comparator: (a: number, b: number) => a - b,
    type: 'numericColumn',
  },
  {
    field: 'discounts',
    headerName: t('fees:discounts'),
    hide: !isStaffUser,
    suppressColumnsToolPanel: !isStaffUser,
    valueGetter: ({ data }) => {
      const [discount] = data?.discounts || [];
      if (!discount) return '-';

      return getDiscountName({
        ...discount,
        value:
          discount.id === DISCOUNT_ID_FOR_ADHOC
            ? data?.adHocAmount ?? 0
            : discount.value,
      });
    },
  },
  {
    field: 'feeStatus',
    headerName: t('common:status'),
    valueGetter: ({ data }) =>
      data?.feeStatus ? t(`fees:status.${data.feeStatus}`) : '-',
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseStudentFees>) => {
      if (!data?.feeStatus) return '-';

      return (
        <Tooltip title={data?.writeOffNote} placement="top" arrow>
          <Stack gap={0.5} flexDirection="row">
            <FeeStatusChip status={data?.feeStatus} />
            {data?.writeOffNote && (
              <InfoCircleIcon sx={{ width: 18, height: 18 }} />
            )}
          </Stack>
        </Tooltip>
      );
    },
    sort: 'asc',
    sortIndex: 0,
  },
  {
    ...commonActionMenuProps,
    hide: !isStaffUser,
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseStudentFees>) => {
      if (!data) return null;
      const isPaid = data.feeStatus === FeeStatus.Complete;
      const hasTxs = payments.some(({ fee }) => fee.id === data.id.feeId);

      const { feeId, studentPartyId } = data.id;

      return (
        <ActionMenu
          iconOnly
          buttonIcon={<VerticalDotsIcon />}
          menuItems={[
            {
              label: t('fees:pay'),
              icon: <WalletWithMoneyIcon />,
              disabled: isPaid,
              disabledTooltip: isPaid
                ? t('fees:cantPayAsSelectedFeeHaveBeenFullyPaid')
                : undefined,
              onClick: () => onPayClick(data.id),
            },
            {
              label: t('fees:statement'),
              icon: <ReceiptIcon />,
              disabled: !hasTxs,
              disabledTooltip: hasTxs
                ? undefined
                : t('fees:youCantPrintStatement'),
              onClick: () => onPrintReceipt(data),
            },
            {
              label: t('fees:viewTransactions'),
              icon: <EyeIcon />,
              disabled: !hasTxs,
              disabledTooltip: hasTxs
                ? undefined
                : t('fees:cantViewTransactionFee'),
              navigateTo: `/fees/transactions/${feeId}?studentPartyId=${studentPartyId}`,
              onBeforeNavigate,
              hasAccess: ({ isStaffUserWithPermission }) =>
                isStaffUserWithPermission('ps:1:fees:write_fees'),
            },
          ]}
        />
      );
    },
  },
];

export function StudentFeesTable({ filter }: StudentFeeTableProps) {
  const { t } = useTranslation(['common', 'fees']);
  const { formatCurrency } = useFormatNumber();
  const { toast } = useToast();
  const { displayName } = usePreferredNameLayout();

  const [selectedStudentFeeIds, setSelectedStudentFeeIds] = useState<
    Set<string>
  >(new Set());

  const { isStaffUser, isStaffUserWithPermission } = usePermissions();
  const { mutateAsync: bulkApplyDiscounts } = useBulkApplyDiscounts();

  const {
    isOpen: isPayFeesModalOpen,
    onOpen: onOpenPayFeesModal,
    onClose: onClosePayFeesModal,
  } = useDisclosure();

  const {
    isOpen: isAddBulkDiscountModalOpen,
    onOpen: onOpenAddBulkDiscountModal,
    onClose: onCloseAddBulkDiscountModal,
  } = useDisclosure();

  const {
    isOpen: isRemoveDiscountConfirmOpen,
    onOpen: onOpenRemoveDiscountConfirm,
    onClose: onCloseRemoveDiscountConfirm,
  } = useDisclosure();

  const {
    isOpen: isWriteOffBalanceModalOpen,
    onOpen: onOpenWriteOffBalanceModal,
    onClose: onCloseWriteOffBalanceModal,
  } = useDisclosure();

  const { data: studentFees = [] } = useStudentFees(filter);

  const { data: paymentsData = [] } = usePayments({
    studentPartyIds: studentFees.map(
      (studentFee) => studentFee.id.studentPartyId,
    ),
    status: PaymentStatus.Succeeded,
  });

  const selectedStudentFees = useMemo(
    () =>
      studentFees?.filter((studentFee) =>
        selectedStudentFeeIds.has(JSON.stringify(studentFee.id.feeId)),
      ) ?? [],
    [studentFees, selectedStudentFeeIds],
  );

  const handleIndividualPayment = useCallback(
    (studentFeeId: ReturnTypeFromUseStudentFees['id']) => {
      setSelectedStudentFeeIds(new Set([JSON.stringify(studentFeeId.feeId)]));
      onOpenPayFeesModal();
    },
    [],
  );

  const { storeList } =
    useListNavigatorSettings<BasicListNavigatorMenuItemParams>({
      type: ListNavigatorType.StudentFees,
    });

  const visibleDataRef = useRef<() => ReturnTypeFromUseStudentFees[]>(null);

  const onBeforeNavigateProfile = useCallback(() => {
    storeList(
      t('fees:feesPage'),
      visibleDataRef.current?.().map(({ id, feeName }) => ({
        id: id.feeId,
        name: feeName,
      })),
    );
  }, []);

  const handlePrintReceipt = useCallback(
    async (fee: ReturnTypeFromUseStudentFees) => {
      try {
        const printResponse = await getPrintFees({
          feeId: fee.id.feeId,
          type: ReceiptType.Statement,
          studentId: fee.id.studentPartyId,
        });

        if (printResponse?.print_fees?.url)
          window.open(printResponse.print_fees.url, '_blank', 'noreferrer');
      } catch {
        toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
      }
    },
    [],
  );

  const columnDefs = useMemo(
    () =>
      getColumnDefs(
        t,
        onBeforeNavigateProfile,
        formatCurrency,
        isStaffUser,
        paymentsData,
        handleIndividualPayment,
        handlePrintReceipt,
        displayName,
      ),
    [
      t,
      onBeforeNavigateProfile,
      formatCurrency,
      isStaffUser,
      paymentsData,
      handleIndividualPayment,
      handlePrintReceipt,
      displayName,
    ],
  );

  const handleSaveIndividualDiscount: BulkAddIndividualDiscountModalProps['onSave'] =
    ({ discount, adhocAmount }) => {
      bulkApplyDiscounts(
        selectedStudentFees.map((fee) => ({
          ...fee.id,
          action: BulkAction.Upsert,
          discountId: discount.id,
          adhocAmount,
        })),
        {
          onSuccess: onCloseAddBulkDiscountModal,
        },
      );
    };

  const removeDiscounts = () =>
    bulkApplyDiscounts(
      selectedStudentFees
        .filter(({ discounts }) => discounts.length > 0)
        .map((fee) => ({
          ...fee.id,
          action: BulkAction.Remove,
          discountId: fee.discounts[0].id,
        })),
      {
        onSuccess: onCloseRemoveDiscountConfirm,
      },
    );

  const someFeesHaveBeenPaid = selectedStudentFees.some(
    ({ feeStatus }) => feeStatus === FeeStatus.Complete,
  );

  const doSomeSelectedDebtorsHaveDiscounts = selectedStudentFees.some(
    ({ discounts }) => discounts.length > 0,
  );

  const haveSomeSelectedDebtorsPaidSomething = selectedStudentFees.some(
    ({ amountPaid }) => amountPaid > 0,
  );

  const minimumAmount = useMemo(() => {
    if (selectedStudentFees.length === 0) return 0;

    return Math.min(
      ...selectedStudentFees.map(
        ({ amountDue, amountDiscounted }) => amountDue + amountDiscounted,
      ),
    );
  }, [selectedStudentFees]);

  const useHasWriteFeesPermission = isStaffUserWithPermission(
    'ps:1:fees:write_fees',
  );

  return (
    <>
      <Table
        visibleDataRef={visibleDataRef}
        rowData={studentFees || []}
        columnDefs={columnDefs}
        rowSelection="multiple"
        getRowId={({ data }) => JSON.stringify(data?.id)}
        rightAdornment={
          <Fade in={selectedStudentFees.length > 0} unmountOnExit>
            {useHasWriteFeesPermission ? (
              <Box>
                <ActionMenu
                  menuItems={[
                    {
                      label: t('fees:pay'),
                      icon: <WalletWithMoneyIcon />,
                      disabled: someFeesHaveBeenPaid,
                      disabledTooltip: someFeesHaveBeenPaid
                        ? t('fees:cantPayAsSomeSelectedFeesHaveBeenFullyPaid')
                        : undefined,
                      onClick: onOpenPayFeesModal,
                    },
                    {
                      label: t('fees:writeOffBalance'),
                      icon: <WalletCheckmarkIcon />,
                      disabled: someFeesHaveBeenPaid,
                      disabledTooltip: someFeesHaveBeenPaid
                        ? t(
                            'fees:youCanNotWriteOffBalanceAsSomeFeesHaveHaveBeenFullyPaid',
                          )
                        : undefined,
                      onClick: onOpenWriteOffBalanceModal,
                    },
                    {
                      label: doSomeSelectedDebtorsHaveDiscounts
                        ? t('fees:replaceDiscount')
                        : t('fees:addDiscount'),
                      icon: <DiscountIcon />,
                      disabled: haveSomeSelectedDebtorsPaidSomething,
                      disabledTooltip: haveSomeSelectedDebtorsPaidSomething
                        ? t(
                            `fees:${
                              doSomeSelectedDebtorsHaveDiscounts
                                ? 'youCanNotReplaceDiscountAsSomePeopleHaveAlreadyPaidFee'
                                : 'youCanNotAddDiscountAsSomePeopleHaveAlreadyPaidFee'
                            }`,
                          )
                        : undefined,
                      onClick: onOpenAddBulkDiscountModal,
                    },
                    {
                      label: t('fees:removeDiscount', {
                        count: selectedStudentFees.length,
                      }),
                      icon: <RemoveDiscountIcon />,
                      hasAccess: () => doSomeSelectedDebtorsHaveDiscounts,
                      disabled: haveSomeSelectedDebtorsPaidSomething,
                      disabledTooltip: haveSomeSelectedDebtorsPaidSomething
                        ? t(
                            'fees:youCanNotRemoveDiscountAsSomePeopleHaveAlreadyPaidFee',
                            { count: selectedStudentFees.length },
                          )
                        : undefined,
                      onClick: onOpenRemoveDiscountConfirm,
                    },
                  ]}
                />
              </Box>
            ) : (
              <Tooltip
                title={
                  someFeesHaveBeenPaid
                    ? t('fees:cantPayAsSomeSelectedFeesHaveBeenFullyPaid')
                    : undefined
                }
              >
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpenPayFeesModal}
                    disabled={someFeesHaveBeenPaid}
                  >
                    {t('fees:pay')}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Fade>
        }
        onRowSelection={(selectedRows) => {
          setSelectedStudentFeeIds(
            new Set(selectedRows.map((row) => JSON.stringify(row.id.feeId))),
          );
        }}
      />

      <PayFeesModal
        open={isPayFeesModalOpen}
        onClose={onClosePayFeesModal}
        feesToPay={selectedStudentFees}
      />

      <WriteOffBalanceMultipleFeesModal
        isOpen={isWriteOffBalanceModalOpen}
        feesToWriteOff={selectedStudentFees}
        onClose={onCloseWriteOffBalanceModal}
      />

      <BulkAddIndividualDiscountModal
        isOpen={isAddBulkDiscountModalOpen}
        feeAmount={minimumAmount}
        onSave={handleSaveIndividualDiscount}
        onClose={onCloseAddBulkDiscountModal}
      />

      <ConfirmDialog
        open={isRemoveDiscountConfirmOpen}
        title={t('fees:removeDiscount', { count: selectedStudentFees.length })}
        description={t('fees:areYouSureYouWantToRemoveDiscount', {
          count: selectedStudentFees.length,
        })}
        confirmText={t('common:yes')}
        cancelText={t('common:no')}
        onConfirm={removeDiscounts}
        onClose={onCloseRemoveDiscountConfirm}
      />
    </>
  );
}
