import { ToggleButtonGroup } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { getMetaCharacter } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { BoldIcon, ItalicIcon, UnderlineIcon } from '@tyro/icons';
import ToolbarColorPicker, { PickerModeOptions } from '../colours/color-picker';
import { ToggleButton } from '../toolbar-buttons/toggle-button';

export interface FontStylingProps {
  editor: Editor | null;
  options?: {
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    textColor?: boolean;
    highlight?: boolean;
  };
}

export default function FontStyling({ editor, options }: FontStylingProps) {
  const { t } = useTranslation(['common']);
  const metaCharacter = getMetaCharacter();

  if (!editor) {
    return null;
  }

  const {
    bold = true,
    italic = true,
    underline = true,
    textColor = true,
    highlight = true,
  } = options || {};

  return (
    <ToggleButtonGroup
      aria-label="text formatting"
      size="small"
      exclusive
      sx={{
        '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          borderLeft: 'none',
        },
        border: 'none',
        alignItems: 'center',
        backgroundColor: 'slate.50',
      }}
    >
      {bold && (
        <ToggleButton
          title={t('common:tooltipTitles.bold', {
            shortcut: `${metaCharacter} + B`,
          })}
          selected={editor.isActive('bold')}
          onClick={() => editor?.chain().toggleBold().run()}
          value="bold"
        >
          <BoldIcon />
        </ToggleButton>
      )}
      {italic && (
        <ToggleButton
          title={t('common:tooltipTitles.italic', {
            shortcut: `${metaCharacter} + I`,
          })}
          onClick={() => editor?.chain().toggleItalic().run()}
          selected={editor?.isActive('italic')}
          value="italic"
        >
          <ItalicIcon />
        </ToggleButton>
      )}
      {underline && (
        <ToggleButton
          title={t('common:tooltipTitles.underline', {
            shortcut: `${metaCharacter} + U`,
          })}
          onClick={() => editor.chain().toggleUnderline().run()}
          selected={editor?.isActive('underline')}
          value="underline"
        >
          <UnderlineIcon />
        </ToggleButton>
      )}
      {textColor && (
        <ToolbarColorPicker
          editor={editor}
          pickerMode={PickerModeOptions.COLOUR}
        />
      )}
      {highlight && (
        <ToolbarColorPicker
          editor={editor}
          pickerMode={PickerModeOptions.HIGHLIGHT}
        />
      )}
    </ToggleButtonGroup>
  );
}
