import { Box } from '@mui/material';
import { useUser } from '@tyro/api';
import { CustomStyles, Dialog, DialogContent, DialogTitle } from '@tyro/core';
import DOMPurify from 'dompurify';
import { useEffect, useMemo } from 'react';
import {
  type ReturnTypeFromUseNoticeBoard,
  useAcknowledgeNotice,
} from '../../api/notice-board';

export interface ViewNoticeModalProps {
  open: boolean;
  onClose: () => void;
  notice: ReturnTypeFromUseNoticeBoard | null;
}

export function ViewNoticeModal({
  open,
  onClose,
  notice,
}: ViewNoticeModalProps) {
  const { activeProfile } = useUser();
  const { mutateAsync } = useAcknowledgeNotice();

  const content = useMemo(() => {
    if (!notice?.notice) {
      return '';
    }

    return DOMPurify.sanitize(notice.notice);
  }, [notice?.notice]);

  useEffect(() => {
    if (notice) {
      const hasAlreadyAcknowledged =
        notice.recipients?.some(
          ({ partyId, acknowledged }) =>
            partyId === activeProfile?.partyId && acknowledged,
        ) ?? false;

      if (hasAlreadyAcknowledged === false) {
        mutateAsync({
          noticeId: notice.id!,
          enable: true,
        });
      }
    }
  }, [notice]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>{notice?.title}</DialogTitle>
      <DialogContent>
        <Box
          pb={2}
          sx={{
            '& .cover-photo': {
              maxWidth: '100%',
              borderRadius: 1,
            },
            ...CustomStyles,
            '& table': {
              tableLayout: 'auto',
            },
          }}
        >
          {notice?.photoUrl && (
            <img
              className="cover-photo"
              src={notice.photoUrl}
              alt={notice.title}
            />
          )}
          {/* biome-ignore lint/security/noDangerouslySetInnerHtml: Required to show tiptap content */}
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
