import { Notes_BehaviourType } from '@tyro/api';

export function getBehaviourType(type?: string) {
  switch (type) {
    case 'Positive':
      return Notes_BehaviourType.Positive;
    case 'Negative':
      return Notes_BehaviourType.Negative;
    case 'Neutral':
      return Notes_BehaviourType.Neutral;

    default:
      Notes_BehaviourType.Positive;
  }
}
