import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import {
  HighlightStatus,
  type Person,
  useAcademicNamespace,
  usePermissions,
  useUser,
} from '@tyro/api';
import { LoadingPlaceholderContainer } from '@tyro/core';
import { Trans, useTranslation } from '@tyro/i18n';
import { FullScreenIcon } from '@tyro/icons';
import { StudentAvatar } from '@tyro/people';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRunReports } from '../../api/run-report';
import { useReportFormatValues } from '../../hooks/use-report-format-values';
import { Report, getReportUrl } from '../../utils/get-report-url';
import type { ReportChipValue } from '../types';

type LinkRowData = {
  id: { value: number };
  avatar_cell: {
    value: Person & { aenFlag: boolean };
    link: { profileTab: string; queryParams: { highlightId: number } };
  };
};

export function HighlightsWidget() {
  const { t } = useTranslation(['common', 'reports']);
  const { activeProfile } = useUser();
  const { isTyroUser } = usePermissions();
  const { activeAcademicNamespace } = useAcademicNamespace();

  const { data: highlightsData, isLoading } = useRunReports({
    topReportId: Report.HIGHLIGHTS,
    filter: {
      reportId: Report.HIGHLIGHTS,
      limit: 5,
      filters: [
        ...(activeProfile?.partyId && !isTyroUser
          ? [
              {
                filterId: 'staff',
                filterValue: [activeProfile?.partyId],
              },
            ]
          : []),
        {
          filterId: 'status',
          filterValue: [HighlightStatus.Pending],
        },
        {
          filterId: 'from_date',
          filterValue: activeAcademicNamespace!.startDate,
        },
        {
          filterId: 'to_date',
          filterValue: activeAcademicNamespace!.endDate,
        },
      ],
    },
  });

  const { mapField } = useReportFormatValues({
    settings: { linkTarget: '_self' },
  });

  const linksById = useMemo<Map<number, string>>(() => {
    const typedValue = (highlightsData?.data ?? []) as LinkRowData[];
    return new Map(
      typedValue.map((row) => {
        const { id, avatar_cell } = row as LinkRowData;

        return [
          id.value,
          `/people/students/${avatar_cell.value.partyId}/${avatar_cell.link.profileTab}?highlightId=${id.value}`,
        ];
      }) ?? [],
    );
  }, [highlightsData?.data]);

  const highlights = mapField<{
    id: number;
    highlight: string;
    highlight_date: string;
    status: ReportChipValue<HighlightStatus>;
    assigned_to: string;
    resolved_by: string;
    resolved_on: string;
    avatar_cell: Person & {
      aenFlag: boolean;
      priorityFlag: boolean;
      medicalFlag: string;
    };
    created_by: string;
  }>(highlightsData);

  return (
    <Card
      variant="soft"
      sx={{
        flex: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pl={1}
        mb={1}
      >
        <Typography variant="h6" component="span">
          {t('reports:pendingHighlights')}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <IconButton
            component={Link}
            to={getReportUrl({
              report: Report.HIGHLIGHTS,
              filters: {
                from_date: dayjs(activeAcademicNamespace!.startDate),
                to_date: dayjs(activeAcademicNamespace!.endDate),
                staff:
                  activeProfile?.partyId && !isTyroUser
                    ? [activeProfile.partyId]
                    : [],
                status: [HighlightStatus.Pending],
              },
            })}
          >
            <FullScreenIcon
              sx={{ width: 20, height: 20, color: 'primary.main' }}
            />
          </IconButton>
        </Stack>
      </Stack>
      {isLoading || highlights.length === 0 ? (
        <Card
          sx={{
            minHeight: 160,
          }}
        >
          <LoadingPlaceholderContainer isLoading={isLoading}>
            <Stack
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              spacing={1}
            >
              <img
                src="/assets/emojis/+1.png"
                role="presentation"
                alt="Thumbs up"
                width="48"
              />
              <Typography
                variant="body1"
                component="span"
                color="text.secondary"
              >
                {t('reports:noBehaviourEventsToday')}
              </Typography>
            </Stack>
          </LoadingPlaceholderContainer>
        </Card>
      ) : (
        <Stack spacing={1.25}>
          {highlights.map(
            ({ id, avatar_cell, highlight, highlight_date, created_by }) => {
              const name = avatar_cell?.textValue;
              const { aenFlag, priorityFlag, medicalFlag, avatarUrl } =
                avatar_cell?.typedValue ?? {};

              const loggedTime = highlight_date?.typedValue
                ? dayjs(highlight_date.typedValue).format('D MMM, LT')
                : '-';

              return (
                <Card
                  component={Link}
                  key={id?.textValue ?? ''}
                  to={linksById.get(id?.typedValue ?? 0) ?? '/'}
                  sx={{
                    p: 1.5,
                    textDecoration: 'inherit',
                    '&:hover': {
                      bgcolor: 'indigo.100',
                    },
                    '&:active': {
                      bgcolor: 'indigo.200',
                    },
                  }}
                >
                  <Stack spacing={1}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={1.5}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box onClick={(e) => e.preventDefault()}>
                          <StudentAvatar
                            partyId={avatar_cell?.typedValue?.partyId ?? 0}
                            name={name ?? ''}
                            src={avatarUrl}
                            isPriorityStudent={!!priorityFlag}
                            hasSupportPlan={!!aenFlag}
                            hasMedical={!!medicalFlag}
                            person={avatar_cell?.typedValue}
                            size={48}
                          />
                        </Box>
                        <Stack>
                          <Typography variant="subtitle1" component="span">
                            {name}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="span"
                        textAlign="right"
                      >
                        <Trans ns="reports" i18nKey="loggedAtTimeByStaff">
                          Logged at {{ loggedTime }} <br />
                          by{' '}
                          <Typography
                            variant="body2"
                            component="span"
                            fontWeight="600"
                            color="text.primary"
                          >
                            {/* @ts-expect-error */}
                            {{ createdBy: created_by?.textValue ?? '' }}
                          </Typography>
                        </Trans>
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxHeight: 44,
                        WebkitLineClamp: '2',
                      }}
                      flex={1}
                    >
                      <Typography variant="body2" component="span">
                        {highlight?.textValue}
                      </Typography>
                    </Box>
                  </Stack>
                </Card>
              );
            },
          )}
        </Stack>
      )}
    </Card>
  );
}
