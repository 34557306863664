import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { Editor } from '@tiptap/react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFAutocomplete,
  RHFDatePicker,
  RHFTextField,
  useFormValidator,
  useToast,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { EditCalendarIcon } from '@tyro/icons';
import {
  type NoticeParty,
  useNoticeBoardSearchProps,
} from '@tyro/notice-board';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { ReturnTypeFromUseNoticeBoardDetails } from '../../api/notice-board';
import { useSaveNotice } from '../../api/save-notice';
import { useUploadPhoto } from '../../api/upload-photo';
import { getAssigneesForRequest } from '../../utils/format-assignees';
import type { NoticeBoardFormState } from '../../utils/types';

type PublishOrScheduleModalType = {
  noticeId?: number;
  title: string;
  assignees: NoticeBoardFormState['noticeAssignees'];
  photoUrl?: string;
  newPhoto: NoticeBoardFormState['newPhoto'];
  priority?: number;
  editor: Editor | null;
  noticeData?: ReturnTypeFromUseNoticeBoardDetails;
  type: 'schedule' | 'publish' | null;
  onSaveSuccess: () => void;
};

type SchedulePublishNoticeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  dataForPublishOrScheduleModal: PublishOrScheduleModalType;
};

export function PublishOrScheduleNoticeModal({
  isOpen,
  onClose,
  dataForPublishOrScheduleModal,
}: SchedulePublishNoticeModalProps) {
  const { t } = useTranslation(['noticeBoard', 'common']);
  const navigate = useNavigate();
  const { toast } = useToast();
  const {
    type,
    editor,
    title,
    assignees,
    newPhoto,
    priority,
    noticeData,
    onSaveSuccess,
  } = dataForPublishOrScheduleModal;
  const isScheduleNotice = type === 'schedule';

  const noticeOptions = useNoticeBoardSearchProps();

  const { resolver, rules } = useFormValidator<NoticeBoardFormState>();
  const { control, handleSubmit, reset } = useForm<NoticeBoardFormState>({
    resolver: resolver({
      validFrom: rules.required(),
      validUntil: rules.required(),
      title: rules.required(),
    }),
    defaultValues: {
      title: title,
      noticeAssignees: assignees,
      validFrom: dayjs(noticeData?.validFrom),
      validUntil: dayjs(noticeData?.validUntil),
    },
  });

  const { mutateAsync: saveNotice, isPending: isSavingNotice } =
    useSaveNotice();
  const { mutateAsync: uploadPhoto, isPending: isUploadingPhoto } =
    useUploadPhoto();

  useEffect(() => {
    reset({
      title: title,
      noticeAssignees: assignees,
      validFrom: dayjs(noticeData?.validFrom),
      validUntil: dayjs(noticeData?.validUntil),
    });
  }, [dataForPublishOrScheduleModal, reset, isOpen]);

  const onSubmit = handleSubmit(
    async ({ title, noticeAssignees, validFrom, validUntil }) => {
      if (!editor) return;

      try {
        let photoUrl = undefined;

        if (newPhoto) {
          const response = (await uploadPhoto([newPhoto])) as Response;
          const data = (await response.json()) as { id: number; path: string };

          photoUrl = data.path;
        } else if (newPhoto === null) {
          photoUrl = null;
        }

        let htmlContent = editor.getHTML();
        const noticeId = noticeData?.id;

        htmlContent = `<div>${htmlContent}</div>`;

        const { assignees, allStaff, allContacts } =
          getAssigneesForRequest(noticeAssignees);

        return saveNotice(
          [
            {
              id: noticeId,
              title: title,
              notice: htmlContent,
              validFrom: dayjs(validFrom).format('YYYY-MM-DDTHH:mm:ss'),
              validUntil: dayjs(validUntil).format('YYYY-MM-DDTHH:mm:ss'),
              noticeAssignees: assignees,
              allStaff,
              allContacts,
              priority,
              photoUrl,
            },
          ],
          {
            onSuccess: async () => {
              navigate('/notice-board');
              onSaveSuccess();
              onClose;
            },
          },
        );
      } catch {
        toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
      }
    },
  );

  return (
    <form onSubmit={onSubmit}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {isScheduleNotice
            ? t('noticeBoard:schedule')
            : t('noticeBoard:publishNotice')}
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" gap={2} marginTop={1}>
            <Stack display="flex" flexDirection="row" gap={2}>
              <RHFTextField
                label={t('noticeBoard:title')}
                controlProps={{
                  name: 'title',
                  control,
                }}
                textFieldProps={{
                  fullWidth: true,
                  multiline: true,
                  rows: 3,
                }}
              />

              <RHFAutocomplete<NoticeBoardFormState, NoticeParty, true>
                {...noticeOptions}
                controlProps={{
                  control,
                  name: 'noticeAssignees',
                }}
                inputProps={{
                  onKeyDown: (event) => {
                    if (event.key === 'Backspace' || event.key === 'Delete') {
                      event.stopPropagation();
                    }
                  },
                }}
                sx={{
                  '&.MuiAutocomplete-root': {
                    width: '100%',
                  },
                }}
              />
            </Stack>
            <Stack
              display="flex"
              flexDirection="column"
              spacing={2}
              borderRadius={2}
              padding={2}
              sx={{
                height: '153px',
                backgroundColor: 'indigo.50',
              }}
            >
              <Box display="flex">
                <EditCalendarIcon sx={{ color: 'indigo.500' }} />
                <Typography marginLeft={1}>
                  {isScheduleNotice
                    ? t('noticeBoard:scheduleNoticeText')
                    : t('noticeBoard:publishNoticeText')}
                </Typography>
              </Box>
              <Stack display="flex" flexDirection="row" gap={2}>
                <RHFDatePicker
                  label={t('common:from')}
                  controlProps={{ name: 'validFrom', control }}
                  inputProps={{
                    fullWidth: true,
                    variant: 'white-filled',
                  }}
                />
                <RHFDatePicker
                  label={t('noticeBoard:until')}
                  controlProps={{ name: 'validUntil', control }}
                  inputProps={{
                    fullWidth: true,
                    variant: 'white-filled-outlined',
                    sx: {
                      '&.MuiFilledInput-input:hover': {
                        backgroundColor: '#fff',
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="soft" onClick={onClose}>
            {t('common:actions.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            loading={isSavingNotice || isUploadingPhoto}
            onClick={onSubmit}
          >
            {t('common:actions.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}
