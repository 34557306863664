import { Card, type CardProps, useTheme } from '@mui/material';
import * as m from 'motion/react-m';
import { useEffect, useRef, useState } from 'react';
import { getClassesFromObject } from '../../utils/get-classes-from-object';

interface ExpandableCardProps extends CardProps {
  isExpanded: boolean;
  onCollapse: () => void;
  children: React.ReactNode;
}

export function ExpandableCard({
  isExpanded,
  onCollapse,
  children,
  ...props
}: ExpandableCardProps) {
  const innerCardRef = useRef<HTMLDivElement>(null);
  const { zIndex } = useTheme();
  const [animateClose, setAnimateClose] = useState(true);
  const [previousDimensions, setPreviousDimensions] = useState<{
    width: number;
    height: number;
    left: number;
    top: number;
  } | null>(null);

  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflow = 'hidden';
      setAnimateClose(true);

      const { width, height, left, top } =
        innerCardRef.current!.getBoundingClientRect();
      setPreviousDimensions({ width, height, left, top });

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const resizeCheckFunc = () => {
        setAnimateClose(
          windowWidth === window.innerWidth &&
            windowHeight === window.innerHeight,
        );
      };
      const keyPressFunc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          onCollapse();
        }
      };
      window.addEventListener('resize', resizeCheckFunc);
      window.addEventListener('keydown', keyPressFunc);

      return () => {
        window.removeEventListener('resize', resizeCheckFunc);
        window.removeEventListener('keydown', keyPressFunc);
      };
    }

    setTimeout(
      () => {
        setPreviousDimensions(null);
        document.body.style.overflow = 'auto';
      },
      animateClose ? 300 : 0,
    );
  }, [isExpanded, setPreviousDimensions]);

  return (
    <Card
      ref={innerCardRef}
      component={m.div}
      layout
      initial={false}
      {...props}
      className={getClassesFromObject({
        'expandable-card': true,
        expanded: isExpanded,
        [props.className || '']: !!props.className,
      })}
      style={{
        ...(isExpanded && {
          position: 'fixed',
          top: 8,
          left: 8,
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
          zIndex: zIndex.modal - 1,
        }),
        ...(!isExpanded &&
          previousDimensions && {
            position: 'fixed',
            ...previousDimensions,
            zIndex: zIndex.modal - 1,
          }),
      }}
    >
      {children}
    </Card>
  );
}
