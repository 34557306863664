import { GradeType, type UsePermissionsReturn } from '@tyro/api';
import type {
  EditableCallbackParams,
  ICellEditorParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from '@tyro/core';
import type { TFunction } from '@tyro/i18n';
import set from 'lodash/set';
import type { ReturnTypeFromUseAssessmentResults } from '../api/assessment-results';
import type { ReturnTypeFromUseAssessmentById } from '../api/assessments';
import type { ReturnTypeFromUseGrades } from '../api/grade-sets/grades';
import { TableGradeAutocomplete } from '../components/common/table-grade-select';
import { checkAndSetGrades } from './check-and-set-grades';

const editAssessmentPermission = 'ps:1:assessment:write_assessment_result';

const getCommonResultFieldProps = (
  field: 'result' | 'targetResult',
  {
    permissions,
    formatPercent,
    assessmentData,
    gradeById,
  }: {
    permissions: UsePermissionsReturn;
    formatPercent: (value: number) => string;
    assessmentData: ReturnTypeFromUseAssessmentById;
    gradeById: Map<number, ReturnTypeFromUseGrades>;
  },
) =>
  ({
    editable: ({
      data,
    }: EditableCallbackParams<ReturnTypeFromUseAssessmentResults>) =>
      !!data?.examinable &&
      !data?.absent &&
      permissions.hasPermission(editAssessmentPermission),
    valueFormatter: ({
      value,
    }: ValueFormatterParams<ReturnTypeFromUseAssessmentResults, number>) =>
      typeof value === 'number' ? formatPercent(value) : '',
    valueSetter: (
      params: ValueSetterParams<ReturnTypeFromUseAssessmentResults>,
    ) => {
      const newValue =
        (!params.newValue && params.newValue !== 0) ||
        Number.isNaN(Number(params.newValue))
          ? undefined
          : Math.max(0, Math.min(100, Number(params.newValue)));

      if (params.data?.[field] !== newValue) {
        set(params.data ?? {}, field, newValue);

        if (assessmentData) {
          checkAndSetGrades({
            changedField: field,
            assessmentData,
            gradeById,
            params,
          });
        }
        return true;
      }

      return false;
    },
  }) as const;

export const getCommonGradeFieldProps = (
  field: 'gradeId' | 'targetGradeId',
  {
    permissions,
    assessmentData,
    gradeById,
  }: {
    permissions: UsePermissionsReturn;
    assessmentData: ReturnTypeFromUseAssessmentById;
    gradeById: Map<number, ReturnTypeFromUseGrades>;
  },
) => {
  const gradesByName = new Map<string, ReturnTypeFromUseGrades>(
    Array.from(gradeById.values()).map((grade) => [grade.name, grade]),
  );

  return {
    editable: ({
      data,
    }: EditableCallbackParams<ReturnTypeFromUseAssessmentResults>) =>
      !!data?.examinable &&
      !data?.absent &&
      permissions.hasPermission(editAssessmentPermission),
    valueFormatter: ({
      value,
    }: ValueFormatterParams<
      ReturnTypeFromUseAssessmentResults,
      ReturnTypeFromUseGrades | null
    >) => value?.name ?? '',
    cellEditorSelector: ({
      data,
    }: ICellEditorParams<ReturnTypeFromUseAssessmentResults>) => ({
      component: TableGradeAutocomplete,
      params: {
        multiple: false,
        filter: {
          gradeSetId: assessmentData?.gradeSets?.[0].id,
          studyLevel: data?.studentStudyLevel,
          programmeShortName: data?.studentProgramme?.shortName,
        },
      },
    }),
    valueGetter: ({
      data,
    }: ValueGetterParams<ReturnTypeFromUseAssessmentResults>) =>
      gradeById.get(data?.[field] ?? 0) ?? null,
    valueSetter: (
      params: ValueSetterParams<
        ReturnTypeFromUseAssessmentResults,
        number | string | ReturnTypeFromUseGrades
      >,
    ) => {
      let newValue =
        !params.newValue || Number.isNaN(Number(params.newValue))
          ? undefined
          : Number(params.newValue);

      if (
        params.newValue &&
        typeof params.newValue === 'object' &&
        'id' in params.newValue
      ) {
        newValue = params.newValue.id;
      }

      if (typeof params.newValue === 'string') {
        if (params.newValue.startsWith('{') && params.newValue.endsWith('}')) {
          const grade = JSON.parse(params.newValue);
          const gradeId = grade?.id;
          newValue = gradeById.get(gradeId)?.id;
        } else {
          newValue = gradesByName.get(params.newValue)?.id;
        }
      }

      if (params.data?.gradeId !== newValue) {
        set(params.data ?? {}, field, newValue);

        if (assessmentData) {
          checkAndSetGrades({
            changedField: field,
            assessmentData,
            gradeById,
            params,
          });
        }

        return true;
      }
      return false;
    },
  } as const;
};

export function getResultFields(
  assessmentData: ReturnTypeFromUseAssessmentById | null | undefined,
  gradeById: Map<number, ReturnTypeFromUseGrades>,
  permissions: UsePermissionsReturn,
  t: TFunction<
    ('common' | 'assessments')[],
    undefined,
    ('common' | 'assessments')[]
  >,
  formatPercent: (value: number) => string,
) {
  const showResult = [GradeType.Percentage, GradeType.Both].includes(
    assessmentData?.gradeType!,
  );
  const showGrade = [GradeType.GradeSet, GradeType.Both].includes(
    assessmentData?.gradeType!,
  );

  return [
    ...(showResult
      ? ([
          {
            field: 'result',
            headerName: t('common:result'),
            ...getCommonResultFieldProps('result', {
              permissions,
              formatPercent,
              assessmentData: assessmentData!,
              gradeById,
            }),
          },
        ] as const)
      : []),
    ...(showGrade
      ? ([
          {
            field: 'gradeId',
            headerName: t('common:grade'),
            ...getCommonGradeFieldProps('gradeId', {
              permissions,
              assessmentData: assessmentData!,
              gradeById,
            }),
          },
        ] as const)
      : []),
    ...(assessmentData?.captureTarget && showResult
      ? ([
          {
            field: 'targetResult',
            headerName: t('assessments:targetResult'),
            ...getCommonResultFieldProps('targetResult', {
              permissions,
              formatPercent,
              assessmentData: assessmentData!,
              gradeById,
            }),
          },
        ] as const)
      : []),
    ...(assessmentData?.captureTarget && showGrade
      ? ([
          {
            field: 'targetGradeId',
            headerName: t('assessments:targetGrade'),
            ...getCommonGradeFieldProps('targetGradeId', {
              permissions,
              assessmentData: assessmentData!,
              gradeById,
            }),
          },
        ] as const)
      : []),
  ] as const;
}
