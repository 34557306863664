import { getPreference, getUser } from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  type NonMenuLink,
  getNumber,
  lazyWithRetry,
} from '@tyro/core';
import { LetterIcon } from '@tyro/icons';
import { type LoaderFunction, redirect } from 'react-router-dom';
import { getLabels } from './api/labels';
import { getMail, getMailList } from './api/mails';
import { MailCountLabel } from './components/common/mail-count-label';
import { SystemLabelMapping, getLabelById } from './utils/labels';

const Mail = lazyWithRetry(() => import('./pages/index'));

const labelPageLoader: LoaderFunction = async ({ params, request }) => {
  const { activeProfile } = await getUser();
  const activeProfileId = activeProfile?.partyId ?? 0;
  const isSchoolMail = request.url.includes('mail/school');
  const isScheduledMailList =
    request.url.includes('mail/scheduled') ||
    request.url.includes('mail/school/scheduled');

  const labels = await getLabels({
    personPartyId: isSchoolMail ? -1 : activeProfileId,
  });

  const matchedLabel = getLabelById(params.labelId ?? '0', labels);
  return getMailList(
    isScheduledMailList ? undefined : matchedLabel?.originalId,
    isSchoolMail ? -1 : activeProfileId,
    isSchoolMail,
    isScheduledMailList,
  );
};

const mailPageLoader: LoaderFunction = async ({ params, request }) => {
  const mailId = getNumber(params.mailId);
  const isSchoolMail = request.url.includes('mail/school');
  const isScheduled =
    request.url.includes('mail/scheduled') ||
    request.url.includes('mail/school/scheduled');

  return getMail(mailId ?? 0, isSchoolMail, isScheduled);
};

const getMailPages = (): NonMenuLink[] => [
  {
    type: NavObjectType.NonMenuLink,
    index: true,
    loader: async ({ request }) => {
      const schoolMailPreference = await getPreference('mail.preferred-mail');
      const redirectToSchoolMail =
        request.url.endsWith('/mail') && schoolMailPreference === 'school';
      return redirectToSchoolMail
        ? redirect(`/mail/school/${SystemLabelMapping.Inbox}`)
        : redirect(`./${SystemLabelMapping.Inbox}`);
    },
  },
  {
    type: NavObjectType.NonMenuLink,
    path: ':labelId',
    loader: labelPageLoader,
    element: <Mail />,
    children: [
      {
        type: NavObjectType.NonMenuLink,
        path: 'view/:mailId',
        loader: mailPageLoader,
        element: <Mail />,
      },
    ],
  },
  {
    type: NavObjectType.NonMenuLink,
    path: 'label/:labelId',
    loader: labelPageLoader,
    element: <Mail />,
    children: [
      {
        type: NavObjectType.NonMenuLink,
        path: 'view/:mailId',
        loader: mailPageLoader,
        element: <Mail />,
      },
    ],
  },
];

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootLink,
        path: 'mail',
        title: t('navigation:general.mail'),
        icon: <LetterIcon />,
        info: <MailCountLabel />,
        hasAccess: (permissions) =>
          permissions.hasAtLeastOnePermission([
            'ps:1:communications:read_mail',
            'ps:1:communications:write_mail',
          ]),
        element: <Mail />,
        children: [
          ...getMailPages(),
          {
            type: NavObjectType.NonMenuLink,
            path: 'school',
            hasAccess: ({ hasPermission }) =>
              hasPermission('ps:1:communications:school_mailbox'),
            children: getMailPages(),
          },
        ],
      },
    ],
  },
];
