import {
  Box,
  Divider,
  type InputBaseComponentProps,
  Popover,
  Stack,
  type StackProps,
  TextField,
  type TextFieldProps,
  useTheme,
} from '@mui/material';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import {
  EditorContent,
  type EditorContentProps,
  type Extensions,
  useEditor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useTranslation } from '@tyro/i18n';

import { TextIcon } from '@tyro/icons';
import {
  type ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useDisclosure, useMergeRefs } from '../../hooks';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';
import { InsertLinkButton } from '../insert-link-button';
import { VariableChipNode } from './extensions/variable-chip';
import { ListItemsToggle } from './toolbar/list-items-toggle';
import FontStyling from './toolbar/text-formatting/font-style';

export type EditorTextFieldProps = Omit<
  TextFieldProps,
  'variant' | 'onChange'
> & {
  variant?:
    | TextFieldProps['variant']
    | 'white-filled'
    | 'white-filled-outlined';
  onChange?: (value: string) => void;
  toolbarContainerProps?: ToolbarProps['toolbarContainerProps'];
} & Pick<
    ToolbarProps,
    'leftBarAdornment' | 'rightBarAdornment' | 'enableExtensions'
  >;

type ToolbarProps = EditorContentProps &
  InputBaseComponentProps & {
    toolbarContainerProps?: StackProps;
    leftBarAdornment?:
      | ((editor: ToolbarProps['editor']) => ReactNode)
      | ReactNode;
    rightBarAdornment?:
      | ((editor: ToolbarProps['editor']) => ReactNode)
      | ReactNode;
    enableExtensions?: {
      undoRedo?: boolean;
      heading?: boolean;
      textFormatting?: boolean;
      listItems?: boolean;
      links?: boolean;
    };
  };

const dividerStyles = {
  height: '20px',
  alignSelf: 'center',
  mx: 0.5,
};

type ToolbarHandle = {
  focus: () => void;
};

const Toolbar = forwardRef<ToolbarHandle, ToolbarProps>(
  (
    {
      editor,
      leftBarAdornment,
      rightBarAdornment,
      enableExtensions,
      toolbarContainerProps,
      innerRef,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation(['common']);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { id, isOpen, onClose, getButtonProps } = useDisclosure();

    const { textFormatting, listItems, links } = enableExtensions || {};
    const refs = useMergeRefs(innerRef, ref);

    useImperativeHandle(ref, () => ({
      focus: () => {
        editor?.commands.focus();
      },
    }));

    if (!editor) return null;

    return (
      <>
        <EditorContent editor={editor} {...props} ref={refs} />
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mt={1.5}
          ml={-1.5}
          mb={-0.25}
          {...toolbarContainerProps}
          sx={{
            '& .MuiToggleButtonGroup-root': {
              backgroundColor: 'transparent',
              '& .MuiToggleButtonGroup-grouped': {
                m: 0,
                p: 0.25,
              },
            },
            ...toolbarContainerProps?.sx,
          }}
        >
          <Stack
            flexDirection="row"
            sx={{
              color: 'text.primary',
              '.MuiIconButton-root': {
                height: '30px',
                width: '30px',
                '& svg': {
                  fontSize: '1.25rem',
                },
              },
            }}
          >
            {[textFormatting, listItems].some(Boolean) && (
              <>
                <IconButtonWithTooltip
                  title={t('common:tooltipTitles.formattingOptions')}
                  placement="top"
                  ref={buttonRef}
                  {...getButtonProps()}
                >
                  <TextIcon />
                </IconButtonWithTooltip>
                <Popover
                  open={isOpen}
                  id={id}
                  onClose={onClose}
                  anchorEl={buttonRef.current}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  sx={{
                    '& .MuiToggleButtonGroup-root': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Box display="flex" flexWrap="wrap">
                    {textFormatting ? (
                      <FontStyling
                        editor={editor}
                        options={{ textColor: false, highlight: false }}
                      />
                    ) : null}
                    {listItems ? (
                      <>
                        <Divider
                          flexItem
                          orientation="vertical"
                          sx={dividerStyles}
                        />
                        <ListItemsToggle editor={editor} />
                      </>
                    ) : null}
                  </Box>
                </Popover>
              </>
            )}
            {links ? (
              <>
                <Divider flexItem orientation="vertical" sx={dividerStyles} />
                <InsertLinkButton editor={editor} />
              </>
            ) : null}
            {leftBarAdornment && (
              <Divider flexItem orientation="vertical" sx={dividerStyles} />
            )}
            {typeof leftBarAdornment === 'function'
              ? leftBarAdornment?.(editor)
              : leftBarAdornment}
          </Stack>
          {typeof rightBarAdornment === 'function'
            ? rightBarAdornment?.(editor)
            : rightBarAdornment}
        </Stack>
      </>
    );
  },
);

export function EditorTextField({
  value,
  placeholder = '',
  variant,
  onChange,
  inputRef,
  leftBarAdornment,
  rightBarAdornment,
  enableExtensions,
  toolbarContainerProps,
  ...textFieldProps
}: EditorTextFieldProps) {
  const theme = useTheme();
  const { spacing, palette } = theme;

  const getEditorExtensions = useCallback(() => {
    const { heading, textFormatting, links } = enableExtensions || {};

    return [
      StarterKit.configure({
        ...(!textFormatting && {
          bold: false,
          strike: false,
          code: false,
          codeBlock: false,
        }),
        heading: heading ? undefined : false,
      }),
      textFormatting && Underline,
      placeholder &&
        Placeholder.configure({
          placeholder: placeholder || '',
        }),
      links &&
        Link.configure({
          openOnClick: false,
          validate: (href) => /^https?:\/\//.test(href),
        }),
      VariableChipNode,
    ].filter(Boolean) as Extensions;
  }, [enableExtensions, placeholder]);

  const editor = useEditor({
    extensions: getEditorExtensions(),
    content: value ?? '',
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content !== value) {
        onChange?.(content);
      }
    },
  });

  useEffect(() => {
    if (editor && value !== undefined && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  const isWhiteFilledVariant =
    variant === 'white-filled' || variant === 'white-filled-outlined';

  return (
    <TextField
      {...textFieldProps}
      inputRef={inputRef}
      multiline
      InputProps={{
        inputComponent: Toolbar,
        inputProps: {
          editor,
          enableExtensions,
          leftBarAdornment,
          rightBarAdornment,
          toolbarContainerProps,
          innerRef: inputRef,
        },
      }}
      InputLabelProps={{ shrink: editor?.isFocused || !!editor?.getText() }}
      variant={isWhiteFilledVariant ? 'filled' : variant}
      sx={{
        '.MuiInputBase-root': {
          pb: 1,
          flexDirection: 'column',
          flexGrow: 1,
        },
        ...(typeof textFieldProps?.sx === 'function'
          ? textFieldProps.sx(theme)
          : textFieldProps?.sx),
        ...(isWhiteFilledVariant && {
          '& .MuiInputBase-root, & .MuiInputBase-root:focus, & .MuiInputBase-root.Mui-focused, & .MuiInputBase-root:hover, & .MuiInputBase-root, & .MuiInputBase-input:focus, & .MuiInputBase-input.Mui-focused, & .MuiInputBase-input:hover':
            {
              backgroundColor: palette.background.default,
              borderRadius: spacing(1),
            },
          ...(variant === 'white-filled-outlined' && {
            '& .MuiInputBase-root': {
              border: '1px solid',
              borderColor: 'divider',
            },
          }),
        }),
        '.tiptap': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minHeight: '93px',
          '&:focus': {
            outline: 'none',
          },
          '& p': {
            margin: 0,
          },
        },
      }}
    />
  );
}
