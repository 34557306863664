import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { usePaginationList } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { ChevronLeftIcon, ChevronRightIcon } from '@tyro/icons';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as m from 'motion/react-m';
import type { ReturnTypeFromUseNoticeBoard } from '../../api/notice-board';

dayjs.extend(LocalizedFormat);

export interface MoreNoticesCardProps {
  notices: ReturnTypeFromUseNoticeBoard[];
  onClick: (notice: ReturnTypeFromUseNoticeBoard) => void;
  gridHasNotices: boolean;
  isNoticeboardOpen: boolean;
  collapsedTransforms:
    | {
        x: number;
        y: number;
      }
    | undefined;
}

export function MoreNoticesCard({
  notices,
  onClick,
  gridHasNotices,
  isNoticeboardOpen,
  collapsedTransforms,
}: MoreNoticesCardProps) {
  const { t } = useTranslation(['noticeBoard']);
  const { palette } = useTheme();

  const { currentList, paginationCount, currentPage, setCurrentPage } =
    usePaginationList({
      initialList: notices,
      filteredList: notices,
      rowsPerPage: 8,
    });

  const isListEmpty = notices.length === 0;
  const totalPages = Math.max(paginationCount, 1);

  return (
    <Card
      component={m.div}
      animate={{
        width: isNoticeboardOpen ? 'auto' : 24,
        height: isNoticeboardOpen ? 'auto' : 24,
        ...(isNoticeboardOpen
          ? { x: 0, y: 0, scale: 1 }
          : (collapsedTransforms ?? {})),
      }}
      transition={{ duration: 0.6 }}
      className="more-notices"
      sx={{
        p: 0.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 50,
          minWidth: 200,
          background: `linear-gradient(90deg, ${palette.indigo[300]} 0%, ${palette.indigo[400]} 100%)`,
          width: '100%',
          borderRadius: 1.75,
          pl: 2.25,
          pr: 1,
        }}
      >
        <Typography variant="h6" component="h3" color="white" noWrap>
          {t('noticeBoard:moreNotices')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: alpha(palette.common.white, 0.6),
            height: 26,
            pl: 1,
            pr: '1px',
            borderRadius: 1.625,

            '& button': {
              p: 0,
              color: 'indigo.700',

              '&:disabled': {
                color: 'indigo.400',
              },

              '&:first-of-type': {
                ml: 1,
              },
            },
          }}
        >
          <Typography variant="caption" color="indigo.600" noWrap>
            {t('noticeBoard:pageXofY', {
              page: currentPage,
              totalPages,
            })}
          </Typography>
          <IconButton
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage <= 1}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage >= totalPages}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>
      <Stack
        sx={{
          py: 0.5,
          px: 0.25,
          justifyContent: 'flex-start',
          alignItems: 'stretch',

          '& button': {
            justifyContent: 'space-between',
            borderColor: 'transparent',
            py: 0.75,
            minHeight: 38,

            '& h4': {
              color: 'text.primary',
            },
          },
        }}
      >
        {isListEmpty && (
          <Stack
            sx={{
              backgroundColor: 'indigo.50',
              borderRadius: 1,
              my: 0.25,
              p: 2,
            }}
          >
            <Typography variant="body1" fontWeight={600} color="text.secondary">
              {gridHasNotices
                ? t('noticeBoard:noAdditionalNotices')
                : t('noticeBoard:noPublishedNotices')}
            </Typography>
          </Stack>
        )}
        {currentList.map((notice) => {
          const { validFrom } = notice;

          const date = dayjs(validFrom).format('L');
          return (
            <Stack
              key={notice.id}
              direction="row"
              component={Button}
              variant="outlined"
              role="button"
              onClick={() => onClick(notice)}
              spacing={1}
              sx={{
                '& .MuiChip-root': {
                  backgroundColor: alpha('#fff', 0.6),
                  backdropFilter: 'blur(16px)',
                  color: 'indigo.600',
                  border: '1px solid',
                  borderColor: 'indigo.600',
                  minWidth: 24,
                  fontWeight: 500,
                },
              }}
            >
              <Typography variant="subtitle2" component="h4" noWrap>
                {notice.title}
              </Typography>
              <Chip color="indigo" size="small" label={date} />
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
}
