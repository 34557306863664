import {
  Box,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useUser } from '@tyro/api';
import {
  IconButtonWithTooltip,
  RadioTabGroup,
  useDebouncedValue,
  useDisclosure,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { CollapseIcon, ExpandIcon, MegaphoneIcon } from '@tyro/icons';
import * as m from 'motion/react-m';
import { type RefObject, useId, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import {
  type ReturnTypeFromUseNoticeBoard,
  useNoticeBoard,
} from '../../api/notice-board';
import { MoreNoticesCard } from './more-notices-card';
import { NoticeCard } from './notice-card';
import { ViewNoticeModal } from './view-notice-modal';

export function NoticeboardDashboardWidget() {
  const id = useId();
  const { t } = useTranslation(['noticeBoard']);
  const [containerRef, { width }] = useMeasure<HTMLDivElement>();
  const [headerRef, { width: headerWidth }] = useMeasure<HTMLDivElement>();
  const [unreadToggleRef, { width: unreadToggleWidth }] =
    useMeasure<HTMLDivElement>();
  const { palette } = useTheme();
  const {
    value: openNotice,
    debouncedValue: debouncedOpenNotice,
    setValue: setOpenNotice,
  } = useDebouncedValue<ReturnTypeFromUseNoticeBoard | null>({
    defaultValue: null,
  });
  const lessThan600 = useMediaQuery('(max-width: 600px)');
  const lessThan900 = useMediaQuery('(max-width: 900px)');
  const { activeProfile } = useUser();

  const { data: notices } = useNoticeBoard({
    active: true,
  });

  const hasUnreadNotices = notices?.some(
    (notice) =>
      notice.recipients?.every(
        ({ partyId, acknowledged }) =>
          partyId !== activeProfile?.partyId || !acknowledged,
      ) ?? true,
  );
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: hasUnreadNotices,
  });
  const [filter, setFilter] = useState<'unread' | 'all'>(
    hasUnreadNotices ? 'unread' : 'all',
  );

  const size = useMemo(() => {
    if (width < 790) {
      return 'small';
    }
    if (width < 960) {
      return 'medium';
    }
    return 'large';
  }, [width]);

  const filterOptions = useMemo(() => {
    return [
      {
        value: 'unread' as const,
        label: t('noticeBoard:unread'),
        notices:
          notices?.filter(
            (notice) =>
              notice.recipients?.every(
                ({ partyId, acknowledged }) =>
                  partyId !== activeProfile?.partyId || !acknowledged,
              ) ?? true,
          ) ?? [],
      },
      {
        value: 'all' as const,
        label: t('noticeBoard:all'),
        notices: notices ?? [],
      },
    ];
  }, [t, activeProfile, notices]);

  const { gridAmount, gridNotices, moreNotices } = useMemo(() => {
    const filteredOptions =
      filterOptions.find(({ value }) => value === filter)?.notices ?? [];

    const hasFirstItemGotImage = Boolean(filteredOptions?.[0]?.photoUrl);

    switch (size) {
      case 'small':
      case 'medium': {
        return {
          gridAmount: 2,
          gridNotices: filteredOptions.slice(0, 2),
          moreNotices: filteredOptions.slice(2),
        };
      }
      case 'large': {
        const numberOfGridNotices = hasFirstItemGotImage ? 3 : 4;
        return {
          gridAmount: numberOfGridNotices,
          gridNotices: filteredOptions.slice(0, numberOfGridNotices),
          moreNotices: filteredOptions.slice(numberOfGridNotices),
        };
      }
    }
  }, [filterOptions, filter, size]);

  const collapsedNoticeTransforms = useMemo(() => {
    const numberOfGridItems = gridNotices.length;
    const missingGridItems = gridAmount - numberOfGridItems;
    const xAdjustmentForGridItems = missingGridItems * 2;
    const moreXAdjustmentNumber = numberOfGridItems === 0 ? -8 : -6;
    const moreXAdjustmentBasedOnGrid = missingGridItems * moreXAdjustmentNumber;

    switch (size) {
      case 'small': {
        let moreY = -120;
        if (numberOfGridItems === 1) moreY = -78;
        if (numberOfGridItems === 0) moreY = -46;

        if (lessThan600) {
          return {
            highlightNotice1: {
              x: 187 + xAdjustmentForGridItems,
              y: -44,
            },
            highlightNotice2: { x: 188, y: -80 },
            moreNotices: {
              x: 178 + moreXAdjustmentBasedOnGrid,
              y: moreY,
            },
          };
        }
        return {
          highlightNotice1: {
            x: 182 + xAdjustmentForGridItems,
            y: -44,
          },
          highlightNotice2: { x: 184, y: -80 },
          moreNotices: {
            x: 174 + moreXAdjustmentBasedOnGrid,
            y: moreY,
          },
        };
      }
      case 'medium': {
        if (lessThan900) {
          return {
            highlightNotice1: {
              x: 162 + xAdjustmentForGridItems,
              y: -46,
            },
            highlightNotice2: { x: 183, y: -80 },
            moreNotices: {
              x: 38 + moreXAdjustmentBasedOnGrid,
              y: 10,
            },
          };
        }
        return {
          highlightNotice1: {
            x: 168 + xAdjustmentForGridItems,
            y: -46,
          },
          highlightNotice2: { x: 189, y: -80 },
          moreNotices: {
            x: 44 + moreXAdjustmentBasedOnGrid,
            y: 10,
          },
        };
      }
      case 'large': {
        if (gridAmount === 4) {
          return {
            highlightNotice1: {
              x: 186 + xAdjustmentForGridItems,
              y: -45,
            },
            highlightNotice2: {
              x: 186 + xAdjustmentForGridItems,
              y: -80,
            },
            highlightNotice3: {
              x: 50 + xAdjustmentForGridItems,
              y: -45,
            },
            highlightNotice4: {
              x: 49,
              y: -80,
            },
            moreNotices: {
              x: -69 + moreXAdjustmentBasedOnGrid,
              y: 5,
            },
          };
        }
        return {
          highlightNotice1: {
            x: 188 + xAdjustmentForGridItems,
            y: -45,
          },
          highlightNotice2: {
            x: 61 + xAdjustmentForGridItems,
            y: -45,
          },
          highlightNotice3: {
            x: 50,
            y: -82,
          },
          moreNotices: {
            x: -88 + moreXAdjustmentBasedOnGrid,
            y: 11,
          },
        };
      }
    }
  }, [size, lessThan600, lessThan900, gridAmount, gridNotices]);

  const getOpenButtonTransform = () => {
    if (isOpen) return 'translateY(0)';
    if (size === 'small') return 'translate(7px, -11px)';
    return 'translate(7px, -11px)';
  };
  const ToggleOpenIcon = isOpen ? CollapseIcon : ExpandIcon;

  return (
    <>
      <Box
        ref={containerRef}
        display="flex"
        justifyContent="flex-end"
        alignContent="flex-end"
      >
        <Box
          component={m.div}
          role="region"
          id={id}
          aria-labelledby={`${id}-label`}
          layout
          layoutRoot
          animate={{
            width: isOpen ? '100%' : 86 + headerWidth + unreadToggleWidth,
            height: isOpen ? 'auto' : 54,
          }}
          transition={{
            duration: 0.6,
          }}
          sx={{
            border: '1px solid',
            borderColor: 'indigo.lighter',
            background: alpha('#ffffff', 0.2),
            borderRadius: 3.25,
            overflow: 'hidden',
            position: 'relative',

            '& .noticeboard-grid': {
              flex: 1,
              display: 'grid',
              rowGap: 1.5,
              columnGap: 3,
              pr: 6,

              '&.small': {
                pr: 0,

                '& .noticeboard-header': {
                  pr: 6,
                },
              },

              '&.medium': {
                gridTemplateColumns: 'minmax(112px, 3fr) minmax(112px, 4fr)',
                gridTemplateRows: '44px 1fr 1fr',

                '& .highlight-notice-1': {
                  gridColumn: '1 / span 1',
                  gridRow: '2 / span 1',
                },
                '& .highlight-notice-2': {
                  gridColumn: '1 / span 1',
                  gridRow: '3 / span 1',
                },

                '& .more-notices': {
                  gridColumn: '2 / span 1',
                  gridRow: '1 / span 3',
                },
              },

              '&.large': {
                gridTemplateColumns:
                  'minmax(112px, 3fr) minmax(112px, 3fr) minmax(112px, 4fr)',
                gridTemplateRows: '44px 1fr 1fr',

                '& .noticeboard-header': {
                  gridColumn: '1 / span 2',
                },

                '&.grid-3': {
                  '& .highlight-notice-1': {
                    gridColumn: '1 / span 1',
                    gridRow: '2 / span 2',
                  },
                  '& .highlight-notice-2': {
                    gridColumn: '2 / span 1',
                    gridRow: '2 / span 1',
                  },
                  '& .highlight-notice-3': {
                    gridColumn: '2 / span 1',
                    gridRow: '3 / span 1',
                  },
                },

                '&.grid-4': {
                  '& .highlight-notice-1': {
                    gridColumn: '1 / span 1',
                    gridRow: '2 / span 1',
                  },
                  '& .highlight-notice-2': {
                    gridColumn: '1 / span 1',
                    gridRow: '3 / span 1',
                  },
                  '& .highlight-notice-3': {
                    gridColumn: '2 / span 1',
                    gridRow: '2 / span 1',
                  },
                },

                '& .highlight-notice-4': {
                  gridColumn: '2 / span 1',
                  gridRow: '3 / span 1',
                },
                '& .more-notices': {
                  gridColumn: '3 / span 1',
                  gridRow: '1 / span 3',
                },
              },

              '& [class*="highlight-notice-"], & .more-notices': {
                position: 'relative',
              },

              '& .highlight-notice-1': {
                zIndex: 5,
              },
              '& .highlight-notice-2': {
                zIndex: 4,
              },
              '& .highlight-notice-3': {
                zIndex: 3,
              },
              '& .highlight-notice-4': {
                zIndex: 2,
              },
            },
          }}
        >
          <Box
            component={m.div}
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: 2,
              background: alpha(palette.blue[500], 0.08),
              transition: 'padding 0.6s ease-out',
              px: 2,
              py: isOpen ? 2 : '5px',
            }}
          >
            <Box
              className={`noticeboard-grid grid-${gridAmount} ${size} ${isOpen ? 'expanded' : 'collapsed'}`}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="noticeboard-header"
                spacing={1.5}
              >
                <Stack
                  ref={headerRef}
                  direction="row"
                  alignItems="center"
                  spacing={0.75}
                >
                  <MegaphoneIcon
                    sx={{ color: 'indigo.600', width: 28, height: 28 }}
                  />
                  <Typography id={`${id}-label`} component="h2" variant="h5">
                    {t('noticeBoard:noticeBoard')}
                  </Typography>
                </Stack>
                <RadioTabGroup
                  groupRef={
                    unreadToggleRef as unknown as RefObject<HTMLDivElement>
                  }
                  value={filter}
                  onChange={(value) => {
                    setFilter((prev) => value ?? prev);
                    if (!isOpen) {
                      onToggle();
                    }
                  }}
                  optionIdKey="value"
                  sx={{
                    borderColor: 'indigo.lighter',
                    transition: 'opacity 0.3s ease-out',
                    position: 'relative',
                    zIndex: 6,
                  }}
                  getOptionLabel={(option) => (
                    <Stack component="span" direction="row" spacing={1}>
                      <span>{option.label}</span>
                      <span>{option.notices.length}</span>
                    </Stack>
                  )}
                  options={filterOptions}
                />
              </Stack>
              {gridNotices.map((notice, index) => {
                let noticeSize: 'small' | 'large' = 'small';
                const hasImage = !!notice.photoUrl;
                const isSmallWithImage = size === 'small' && hasImage;
                const isFirstWithImageWhenLarge =
                  size === 'large' && index === 0 && hasImage;

                if (isSmallWithImage || isFirstWithImageWhenLarge) {
                  noticeSize = 'large';
                }

                return (
                  <NoticeCard
                    key={notice.id}
                    notice={notice}
                    size={noticeSize}
                    className={`highlight-notice-${index + 1}`}
                    onClick={setOpenNotice}
                    isNoticeboardOpen={isOpen}
                    collapsedTransforms={
                      collapsedNoticeTransforms[
                        `highlightNotice${index + 1}` as keyof typeof collapsedNoticeTransforms
                      ]
                    }
                  />
                );
              })}
              <MoreNoticesCard
                notices={moreNotices ?? []}
                gridHasNotices={gridNotices.length > 0}
                onClick={setOpenNotice}
                isNoticeboardOpen={isOpen}
                collapsedTransforms={collapsedNoticeTransforms.moreNotices}
              />
            </Box>
          </Box>
          <IconButtonWithTooltip
            aria-expanded={isOpen}
            aria-controls={id}
            title={t('noticeBoard:toggleNoticeboardVisibility')}
            onClick={onToggle}
            sx={{
              mb: 0.5,
              position: 'absolute',
              right: 18,
              transition: 'transform 0.6s ease-out;',
            }}
            style={{
              top: 20,
              transform: getOpenButtonTransform(),
            }}
          >
            <ToggleOpenIcon sx={{ color: 'indigo.600' }} />
          </IconButtonWithTooltip>
        </Box>
      </Box>
      <ViewNoticeModal
        open={!!openNotice}
        notice={openNotice ?? debouncedOpenNotice}
        onClose={() => setOpenNotice(null)}
      />
    </>
  );
}
