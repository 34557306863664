import {
  Box,
  Card,
  CardHeader,
  type CardProps,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { useTranslation } from '@tyro/i18n';
import { EditIcon, InfoCircleIcon, SaveIcon, UndoIcon } from '@tyro/icons';
import {
  type ReactElement,
  type ReactNode,
  cloneElement,
  useEffect,
  useState,
} from 'react';
import {
  type FieldValues,
  type Path,
  type PathValue,
  type Resolver,
  type UseControllerProps,
  useForm,
} from 'react-hook-form';
import { LinkRender } from '../table/elements/link-render';

type CardEditableField<TField extends FieldValues> = {
  label: string;
  labelForEditingMode?: string;
  tooltipInfo?: string;
  // NOTE: this is the proper type but as it is a recursive typed function it causes eslint/typescript performance issues.
  // value: PathValue<TField, Path<TField>>;
  value: unknown;
  valueEditor?: ReactElement<{ controlProps: UseControllerProps<TField> }>;
  valueRenderer?: ReactNode;
  readOnly?: boolean;
  showOnlyOnEdition?: boolean;
};

export type CardEditableFormProps<TField extends FieldValues> = CardProps & {
  title: string;
  editable?: boolean;
  rightAdornment?: ReactNode;
  hideBorder?: boolean;
  fields: Array<CardEditableField<TField>>;
  resolver?: Resolver<TField>;
  onSave?: (data: TField, onSuccess: () => void) => void;
  onEdit?: () => void;
  onCancel?: () => void;
};

export const CardEditableForm = <TField extends FieldValues>({
  title,
  editable,
  rightAdornment,
  fields,
  resolver,
  onSave,
  onEdit,
  onCancel,
  hideBorder,
  sx,
  children,
  ...cardProps
}: CardEditableFormProps<TField>) => {
  const { t } = useTranslation(['common']);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<TField>({ resolver });

  const handleSave = (data: TField) => {
    if (isDirty) {
      setIsSubmitting(true);
      onSave?.(data, () => {
        setIsEditMode(false);
        setIsSubmitting(false);
      });
    } else {
      setIsEditMode(false);
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    reset();
    onCancel?.();
  };

  const handleEdit = () => {
    setIsEditMode(true);
    onEdit?.();
  };

  useEffect(() => {
    const newValues = fields.reduce((acc, { value, valueEditor }) => {
      if (valueEditor) {
        acc[valueEditor.props.controlProps.name] = (value ?? null) as PathValue<
          TField,
          Path<TField>
        >;
      }

      return acc;
    }, {} as TField);

    reset(newValues, { keepDirty: editable });
  }, [fields]);

  return (
    <Card
      variant="outlined"
      sx={{
        ...{ position: 'static', overflow: 'inherit', ...sx },
        ...(hideBorder && {
          border: 'none',
          '.MuiCardHeader-root': { borderBottom: 'none !important' },
        }),
      }}
      component={isEditMode ? 'form' : 'div'}
      onSubmit={handleSubmit(handleSave)}
      {...cardProps}
    >
      <CardHeader
        title={title}
        {...((editable || rightAdornment) && {
          action: (
            <Stack direction="row" alignItems="center">
              {editable && (
                <>
                  {isEditMode ? (
                    <>
                      <Tooltip title={t('common:actions.cancel')}>
                        <IconButton
                          aria-label={t('common:actions.cancel')}
                          onClick={handleCancel}
                          disabled={isSubmitting}
                        >
                          <UndoIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={t('common:actions.save')}>
                        <IconButton
                          aria-label={t('common:actions.save')}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={24} />
                          ) : (
                            <SaveIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip title={t('common:actions.edit')}>
                      <IconButton
                        aria-label={t('common:actions.edit')}
                        onClick={handleEdit}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              {rightAdornment}
            </Stack>
          ),
        })}
      />
      <Box
        component="dl"
        sx={{
          p: 3,
          m: 0,
          display: 'grid',
          gridRowGap: '2rem',
          gridColumnGap: '4rem',
          gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        }}
      >
        {fields.map(
          (
            {
              label,
              labelForEditingMode = label,
              tooltipInfo,
              value,
              valueRenderer,
              valueEditor,
              showOnlyOnEdition,
              readOnly,
            },
            index,
          ) => {
            const canBeEdited = isEditMode && !readOnly && valueEditor;

            if (showOnlyOnEdition && !isEditMode) return null;

            const displayValue = valueRenderer || (value as string) || '-';

            return (
              <Box
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={`${label}-${index}`}
                component={canBeEdited ? 'label' : 'div'}
              >
                <Stack
                  component="span"
                  gap={0.5}
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography flex="1 0 0%" component="dt" variant="subtitle1">
                    {isEditMode ? labelForEditingMode : label}
                  </Typography>

                  {tooltipInfo && (
                    <Box display="flex" flex="1" justifyContent="flex-start">
                      <Tooltip title={tooltipInfo}>
                        <InfoCircleIcon sx={{ width: 18, height: 18 }} />
                      </Tooltip>
                    </Box>
                  )}
                </Stack>

                {canBeEdited ? (
                  cloneElement(valueEditor, {
                    controlProps: {
                      name: valueEditor.props.controlProps.name,
                      control,
                    },
                  })
                ) : (
                  <Typography
                    paddingY={0.5}
                    component="dd"
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      overflowWrap: 'break-word',
                    }}
                  >
                    {typeof displayValue === 'string' ? (
                      <LinkRender text={displayValue} />
                    ) : (
                      displayValue
                    )}
                  </Typography>
                )}
              </Box>
            );
          },
        )}
      </Box>
      <Box p={3} pt={0}>
        {children}
      </Box>
    </Card>
  );
};
