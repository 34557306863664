import {
  Box,
  Card,
  Chip,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { Colour, HighlightStatus, usePermissions } from '@tyro/api';
import {
  DialogCloseButton,
  Select,
  getClassesFromObject,
  useDebouncedValue,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  CheckmarkCircleBigIcon,
  ChevronLeftIcon,
  ClockWithMoreDotsIcon,
  EditIcon,
  SearchTextIcon,
} from '@tyro/icons';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import * as m from 'motion/react-m';
import { useId, useState } from 'react';
import {
  type ReturnTypeFromUseStudentTimelineHighlights,
  useSaveTimelineHighlight,
} from '../../../../../api/student/timeline';
import type { TimelineItemProps } from '../item';
import { TimelineItemIcon } from '../item-icon';
import { TimelineHighlightIcon } from '../timeline-highlight-icon';
import { getEventDateTimes } from '../utils';
import { Comments } from './comments';

dayjs.extend(LocalizedFormat);

export interface TimelinePreviewerHighlightCardContentProps {
  data: Omit<ReturnTypeFromUseStudentTimelineHighlights, 'events'> & {
    events: TimelineItemProps['event'][];
  };
  onOpenEvent: (eventId: TimelineItemProps['event']) => void;
  selectedEventIds: Set<string>;
  onClose: () => void;
}

const cardHeight = 48;
const cardMargin = 8;

function getEventCollapseStyle(index: number, numberOfEvents: number) {
  let translateY = index * (cardHeight + cardMargin);
  let scale = 0.84;

  if (index === numberOfEvents - 1) {
    translateY -= 14;
    scale = 1;
  } else if (index === numberOfEvents - 2) {
    translateY -= 7;
    scale = 0.92;
  }

  return {
    transform: `translateY(-${Math.max(0, translateY)}px) scale(${scale})`,
  };
}

const statusOptions: {
  id: HighlightStatus;
  color: Colour | 'indigo' | 'slate';
  icon: JSX.Element;
}[] = [
  {
    id: HighlightStatus.Pending,
    color: 'slate',
    icon: <ClockWithMoreDotsIcon />,
  },
  {
    id: HighlightStatus.Reviewing,
    color: 'indigo',
    icon: <SearchTextIcon />,
  },
  {
    id: HighlightStatus.Resolved,
    color: Colour.Green,
    icon: <CheckmarkCircleBigIcon />,
  },
];

function StatusSelect({
  highlightData,
}: {
  highlightData: TimelinePreviewerHighlightCardContentProps['data'];
}) {
  const { t } = useTranslation(['common']);
  const [tempInternalState, setTempInternalState] = useState<HighlightStatus>(
    highlightData.status,
  );
  const { mutate } = useSaveTimelineHighlight();
  const { isStaffUserWithPermission } = usePermissions();

  const hasPermissionToEdit = isStaffUserWithPermission(
    'ps:1:student_timeline:write_highlights',
  );

  return (
    <Select
      value={tempInternalState}
      label={t('common:status')}
      variant="white-filled"
      optionIdKey="id"
      options={statusOptions}
      disabled={!hasPermissionToEdit}
      SelectProps={{
        IconComponent: EditIcon,
      }}
      getOptionLabel={(option) => {
        const isSelected = tempInternalState === option.id;

        return (
          <Chip
            size="small"
            icon={option.icon}
            color={isSelected ? option.color : 'slate'}
            label={t(`common:highlightStatus.${option.id}`)}
            variant="soft"
            sx={{
              borderRadius: 0.75,
              ...(!isSelected ? { backgroundColor: 'transparent' } : {}),
            }}
          />
        );
      }}
      renderValue={(option) => (
        <Chip
          size="small"
          icon={option.icon}
          color={option.color}
          label={t(`common:highlightStatus.${option.id}`)}
          variant="soft"
          sx={{ borderRadius: 0.75 }}
        />
      )}
      onChange={(event) => {
        const newValue = event.target.value as HighlightStatus;
        setTempInternalState(newValue);
        mutate(
          {
            id: highlightData.id,
            status: newValue,
            studentPartyId: highlightData.studentPartyId,
          },
          {
            onError: () => {
              setTempInternalState(highlightData.status);
            },
          },
        );
      }}
      size="small"
      sx={{
        '& .MuiSelect-icon': {
          top: 'calc(50% - 0.1em)',
          right: 8,
          color: 'text.secondary',
        },
        '& .MuiSelect-iconOpen': {
          transform: 'rotate(0deg)',
        },
      }}
    />
  );
}

function EventsDetails({
  events,
  onOpenEvent,
  selectedEventIds,
}: Pick<TimelinePreviewerHighlightCardContentProps['data'], 'events'> &
  Pick<
    TimelinePreviewerHighlightCardContentProps,
    'onOpenEvent' | 'selectedEventIds'
  >) {
  const id = useId();
  const { t } = useTranslation(['common']);
  const {
    value: isOpen,
    debouncedValue: isOpenDebounced,
    setValue: setOpen,
  } = useDebouncedValue({
    defaultValue: events.length === 1,
  });
  const { palette } = useTheme();

  return (
    <Stack
      sx={{
        '& > h3': {
          m: 0,
          '& > button': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',

            '& svg': {
              transition: 'transform 0.15s ease-in-out',
            },
          },
        },
        '& .timeline-event': {
          backgroundColor: alpha(palette.slate[300], 0.3),
          backdropFilter: 'blur(30px)',
          border: '1px solid',
          borderColor: 'indigo.50',
          borderRadius: 1.5,
          boxShadow: `0 3px 5px 0 ${alpha(palette.primary.main, 0.08)}`,
          p: 0.5,
          alignItems: 'center',
          transition: 'transform 0.15s ease-in-out',
          textAlign: 'left',
          cursor: 'pointer',

          '&.selected': {
            backgroundColor: 'white',
            borderColor: 'indigo.main',
          },
        },
      }}
    >
      <Box
        component="h3"
        sx={{ display: 'flex', '& *': { color: 'text.secondary' } }}
      >
        <Box
          component="button"
          type="button"
          aria-expanded={isOpen}
          aria-controls={`${id}-section`}
          id={id}
          onClick={() => setOpen((prev) => !prev)}
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>{t('common:xTimelineEvents', { count: events.length })}</span>
          <ChevronLeftIcon sx={isOpen ? { transform: 'rotate(-90deg)' } : {}} />
        </Box>
      </Box>
      <Box
        component={m.div}
        role="region"
        id={`${id}-section`}
        layout
        layoutRoot
        aria-labelledby={id}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mt: 1,
          mb: 0.5,
          mx: 1,

          '& .details-container': {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: !isOpen && !isOpenDebounced ? '1' : undefined,
          },
        }}
        animate={{
          height: isOpen ? 'auto' : 60,
        }}
        transition={{ ease: 'easeInOut', duration: 0.3 }}
      >
        {events.map((event, index) => (
          <Stack
            component="button"
            type="button"
            key={JSON.stringify(event.id)}
            className={getClassesFromObject({
              'timeline-event': true,
              selected: selectedEventIds.has(JSON.stringify(event.id)),
            })}
            direction="row"
            spacing={1}
            style={!isOpen ? getEventCollapseStyle(index, events.length) : {}}
            onClick={isOpen ? () => onOpenEvent(event) : () => setOpen(true)}
          >
            <Box
              sx={{
                bgcolor: 'white',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 38,
                height: 38,
                minWidth: 38,
                border: '1px solid',
                borderColor: 'slate.100',
              }}
            >
              <TimelineItemIcon type={event.id.entityType} />
            </Box>
            <Stack flex={1} pr={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                flex={1}
                spacing={1}
              >
                <Box flex={1} minWidth={0} overflow="hidden" maxWidth={148}>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    color="text.secondary"
                    lineHeight={1.3}
                    noWrap
                  >
                    {event.headline}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  lineHeight={1.51}
                  noWrap
                >
                  {getEventDateTimes(event).monthTimestamp}
                </Typography>
              </Stack>
              {event.details && (
                <m.div
                  className="details-container"
                  layout
                  animate={{
                    height: isOpen ? 'auto' : 18.2, // Typography line height
                  }}
                  transition={{ ease: 'easeInOut', duration: 0.3 }}
                >
                  <Typography variant="body2" fontWeight={600} lineHeight={1.3}>
                    {event.details}
                  </Typography>
                </m.div>
              )}
            </Stack>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
}

export function TimelinePreviewerHighlightCardContent({
  data,
  onOpenEvent,
  selectedEventIds,
  onClose,
}: TimelinePreviewerHighlightCardContentProps) {
  const { t } = useTranslation(['common', 'people']);
  const { displayName } = usePreferredNameLayout();

  return (
    <Card sx={{ p: 1, pb: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <TimelineHighlightIcon sx={{ color: 'indigo.main' }} />
          <Typography variant="subtitle1" component="h3">
            {t('common:highlight')}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption" color="text.secondary">
            {dayjs(data.createdOn).format('lll')}
          </Typography>
          <DialogCloseButton onClick={onClose} />
        </Stack>
      </Stack>
      <Stack sx={{ mt: 1, mb: 2 }} spacing={0.5} alignItems="flex-start">
        <Stack sx={{ px: 1.5 }}>
          <Typography variant="subtitle2" component="h4">
            {data.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {data.highlight}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {t('people:byName', { name: displayName(data.createdBy) })}
          </Typography>
        </Stack>
        <StatusSelect highlightData={data} />
      </Stack>
      <Stack spacing={2}>
        <EventsDetails
          events={data.events}
          onOpenEvent={onOpenEvent}
          selectedEventIds={selectedEventIds}
        />
        <Comments highlightId={data.id} comments={data.comments} />
      </Stack>
    </Card>
  );
}
