import { IconButton, Stack, Typography, alpha, useTheme } from '@mui/material';
import { Colour, FileTransferFeature, usePermissions } from '@tyro/api';
import {
  DatePicker,
  Dialog,
  DialogCloseButton,
  DialogContent,
  LoadingPlaceholder,
  mixHexColors,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  CameraIcon,
  FolderDotIcon,
  MedicalCardPatientIcon,
  NotebookIcon,
} from '@tyro/icons';
import dayjs, { type Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotes } from '../../../api/note/list';
import { useStudentAenData } from '../../../api/student/aen/student-aen-data';
import { useStudentMedicalConditions } from '../../../api/student/medicals/student-medical-data';
import { useStudent } from '../../../api/student/students';
import { useStudentTimeline } from '../../../api/student/timeline';
import { useStudentActions } from '../../../store/student-actions';
import { Timeline, type TimelineProps } from '../../common/timeline/core';
import { StudentAvatarPicture } from '../student-avatar/student-avatar-picture';
import { NeedToKnowActions } from './actions';
import { NTKAen } from './aen';
import { BottomTabs, type BottomTabsProps } from './bottom-tabs';
import { NTKCondition } from './condition';
import { NTKContainer } from './container';
import { NTKNote } from './notes';
import { PersonalInfoCard } from './personal-info';

type ResponseError = {
  response: Response;
} | null;

interface NeedToKnowModalProps {
  id: string;
  studentPartySettings: { partyId: number; groupId?: number } | undefined;
  open: boolean;
  onClose: () => void;
}

function NTKTabPanel({
  tabs,
  tabKey,
  currentTab,
  children,
}: {
  tabs: BottomTabsProps['tabs'];
  tabKey: BottomTabsProps['tabs'][number]['key'];
  currentTab: BottomTabsProps['tabs'][number]['key'];
  children: React.ReactNode;
}) {
  const matchedTab = tabs.find((tab) => tab.key === tabKey);

  if (!matchedTab) return null;

  return (
    <div
      role="tabpanel"
      id={matchedTab.panelId}
      aria-labelledby={matchedTab.id}
      hidden={currentTab !== matchedTab.key}
      style={{
        display: currentTab === matchedTab.key ? 'block' : 'none',
      }}
    >
      {children}
    </div>
  );
}

export function NeedToKnowModal({
  id,
  studentPartySettings,
  open,
  onClose,
}: NeedToKnowModalProps) {
  const { partyId: studentPartyId = 0, groupId } = studentPartySettings ?? {};
  const { t } = useTranslation(['common', 'people']);
  const { displayName } = usePreferredNameLayout();
  const { isStaffUserWithPermission } = usePermissions();
  const { palette, customShadows } = useTheme();
  const [currentTab, setCurrentTab] =
    useState<BottomTabsProps['tabs'][number]['key']>('needToKnow');
  const [timelineDate, setTimelineDate] = useState<Dayjs>(dayjs());

  const { openNeedToKnowForStudent, getAvatarUploadButtonProps } =
    useStudentActions();
  const {
    data: student,
    isLoading: isStudentLoading,
    isPending: isTimelinePending,
  } = useStudent(studentPartyId, open);
  const {
    data: priorityNotes = [],
    error: notesError,
    isLoading: isNotesLoading,
  } = useNotes(
    {
      partyIds: [studentPartyId],
      priority: true,
    },
    open,
  );
  const {
    data: aenData = [],
    error: aenError,
    isLoading: isAenLoading,
  } = useStudentAenData(
    { studentPartyIds: [studentPartyId], active: true },
    open,
  );

  const {
    data: medicalConditions = [],
    error: medicalError,
    isLoading: isMedicalLoading,
  } = useStudentMedicalConditions(studentPartyId, { enabled: open });

  const hasTimelineAccess = isStaffUserWithPermission(
    'ps:1:student_timeline:student_timeline',
  );
  const { data: timelineData, isLoading: isTimelineLoading } =
    useStudentTimeline(
      {
        studentPartyIds: [studentPartyId],
        fromDate: timelineDate.format('YYYY-MM-DD'),
        toDate: timelineDate.format('YYYY-MM-DD'),
      },
      {
        enabled: open && hasTimelineAccess,
      },
    );

  const timelineEvents = useMemo<TimelineProps['events']>(() => {
    if (!timelineData) return [];

    return timelineData.events.map((event) => ({
      id: event.timelineId!,
      headline: event.headline,
      date: event.startDate,
      startDateTime: event.startDatetime,
      endDateTime: event.endDatetime,
      details: event.details,
      meta: event.meta!,
      createdBy: event.createdBy,
    }));
  }, [timelineData]);

  const aenEntries = aenData?.[0]?.entries ?? [];

  const name = displayName(student?.person);
  const isLoading =
    isStudentLoading ||
    isNotesLoading ||
    isAenLoading ||
    isMedicalLoading ||
    isTimelinePending;
  const hasPriorityNotesAccess =
    (notesError as ResponseError)?.response?.status !== 403;
  const hasAenAccess = (aenError as ResponseError)?.response?.status !== 403;
  const hasMedicalAccess =
    (medicalError as ResponseError)?.response?.status !== 403;

  const tabs = useMemo(() => {
    const values: BottomTabsProps['tabs'] = [];

    if (hasPriorityNotesAccess || hasAenAccess || hasMedicalAccess) {
      values.push({
        key: 'needToKnow',
        id: `${id}-ntk-tab`,
        panelId: `${id}-ntk-panel`,
        name: t('people:needToKnow'),
      });
    }

    if (hasTimelineAccess) {
      values.push({
        key: 'timeline',
        id: `${id}-timeline-tab`,
        panelId: `${id}-timeline-panel`,
        name: t('people:timeline'),
      });
    }

    values.push({
      key: 'personalInfo',
      id: `${id}-personal-info-tab`,
      panelId: `${id}-personal-info-panel`,
      name: t('people:personalDetails'),
    });

    return values;
  }, [
    hasPriorityNotesAccess,
    hasAenAccess,
    hasMedicalAccess,
    hasTimelineAccess,
    id,
  ]);

  const reopenNeedToKnow = useCallback(() => {
    openNeedToKnowForStudent(studentPartyId, groupId);
  }, [studentPartyId, groupId]);

  useEffect(() => {
    const hasTab = tabs.some(({ key }) => key === currentTab);

    if (!hasTab && tabs[0]) {
      setCurrentTab(tabs[0].key as BottomTabsProps['tabs'][number]['key']);
    }
  }, [tabs]);

  useEffect(() => {
    if (studentPartyId === 0) {
      setCurrentTab(tabs[0].key as BottomTabsProps['tabs'][number]['key']);
    }
  }, [studentPartyId]);

  const canUploadStudentAvatar = isStaffUserWithPermission(
    'ps:1:documents:write_student_photos',
  );

  return (
    <Dialog
      id={id}
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          height: 'calc(100vh - 32px)',
          maxHeight: 920,
        },
      }}
    >
      {isLoading ? (
        <LoadingPlaceholder sx={{ height: '100%' }} />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={3}
            sx={{
              position: 'sticky',
              top: 0,
              p: 2,
              backgroundColor: alpha(palette.indigo[100], 0.1),
              boxShadow: customShadows.z1,
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <IconButton
                disabled={!canUploadStudentAvatar}
                aria-label={t('common:changeAvatarForName', {
                  name,
                })}
                sx={{
                  padding: 0,
                  '& .image-upload-icon': {
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: alpha(palette.slate['800'], 0.4),
                    backdropFilter: 'blur(1px)',
                    zIndex: 1,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    opacity: 0,
                    transition: 'opacity 0.15s ease-in-out',

                    '& svg': {
                      color: palette.common.white,
                      width: 32,
                      height: 32,
                    },
                  },
                  '&:hover, &:focus': {
                    backgroundColor: 'transparent',
                    '& .image-upload-icon': {
                      opacity: 1,
                    },
                  },
                }}
                {...getAvatarUploadButtonProps(
                  {
                    partyId: studentPartyId,
                    uploadType: FileTransferFeature.StudentPhotos,
                  },
                  {
                    onClick: onClose,
                    onClose: reopenNeedToKnow,
                  },
                )}
              >
                {canUploadStudentAvatar && (
                  <div className="image-upload-icon">
                    <CameraIcon />
                  </div>
                )}
                <StudentAvatarPicture
                  name={name}
                  src={student?.person?.avatarUrl}
                  isPriorityStudent={!!student?.extensions?.priority}
                  hasSupportPlan={!!student?.extensions?.aen}
                  hasMedical={!!student?.extensions?.medical}
                  size={68}
                  person={student?.person}
                />
              </IconButton>
              <Stack>
                <Typography component="h2" variant="h4">
                  {name}
                </Typography>
                <Typography variant="body1">
                  {student?.classGroup?.name}
                </Typography>
              </Stack>
            </Stack>
            <DialogCloseButton
              onClick={onClose}
              sx={{ mt: '6px !important' }}
            />
          </Stack>
          <DialogContent
            sx={{
              pt: 1,
              px: 2,
              pb: 8,
              backgroundColor: mixHexColors(palette.indigo[50], '#fff', 0.3),
            }}
          >
            <NTKTabPanel
              tabs={tabs}
              tabKey="needToKnow"
              currentTab={currentTab}
            >
              {hasPriorityNotesAccess && (
                <NTKContainer
                  heading={t('common:notes')}
                  icon={<NotebookIcon />}
                  headerLink={`/people/students/${studentPartyId}/notes`}
                  hasItems={priorityNotes.length > 0}
                  noItemsText={t('people:noPriorityNotes')}
                  onClose={onClose}
                >
                  {priorityNotes.map((note) => (
                    <NTKNote key={note.id} note={note} />
                  ))}
                </NTKContainer>
              )}
              {hasAenAccess && (
                <NTKContainer
                  heading="AEN"
                  icon={<FolderDotIcon />}
                  colour={Colour.Blue}
                  headerLink={`/people/students/${studentPartyId}/aen`}
                  hasItems={aenEntries.length > 0}
                  noItemsText={t('people:noAenRecords')}
                  onClose={onClose}
                >
                  {aenEntries.map((aen) => (
                    <NTKAen key={aen.id} aen={aen} />
                  ))}
                </NTKContainer>
              )}
              {hasMedicalAccess && (
                <NTKContainer
                  heading={t('people:medicalConditions')}
                  icon={<MedicalCardPatientIcon />}
                  colour={Colour.Emerald}
                  headerLink={`/people/students/${studentPartyId}/medical`}
                  hasItems={medicalConditions.length > 0}
                  noItemsText={t('people:noMedicalRecords')}
                  onClose={onClose}
                >
                  {medicalConditions.map((medicalCondition) => (
                    <NTKCondition
                      key={medicalCondition.id}
                      condition={medicalCondition}
                    />
                  ))}
                </NTKContainer>
              )}
            </NTKTabPanel>
            <NTKTabPanel tabs={tabs} tabKey="timeline" currentTab={currentTab}>
              <Stack mt={1.5} spacing={1}>
                <DatePicker
                  label={t('common:date')}
                  value={timelineDate}
                  onChange={(date) => {
                    if (date) {
                      setTimelineDate(date);
                    }
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                  variant="white-filled-outlined"
                />
                <Timeline
                  events={timelineEvents}
                  showGroupLabels={false}
                  showActions={false}
                  loading={isTimelineLoading}
                  containerProps={{
                    sx: {
                      boxShadow: 'none',
                      backgroundColor: 'white',
                    },
                  }}
                  timelineContainerProps={{
                    sx: {
                      backgroundColor: 'white',
                      border: '1px solid',
                      borderColor: 'divider',
                      p: 1,
                      minHeight: 200,
                      height: 'calc(100vh - 280px)',
                    },
                  }}
                />
              </Stack>
            </NTKTabPanel>
            <NTKTabPanel
              tabs={tabs}
              tabKey="personalInfo"
              currentTab={currentTab}
            >
              <PersonalInfoCard studentPartyId={studentPartyId} open={open} />
            </NTKTabPanel>
            <BottomTabs
              componentId={id}
              tabs={tabs}
              value={currentTab}
              setTab={setCurrentTab as (value: string) => void}
            />
          </DialogContent>
          <NeedToKnowActions
            studentPartySettings={studentPartySettings}
            open={open}
            onActionClick={onClose}
            onActionClose={reopenNeedToKnow}
          />
        </>
      )}
    </Dialog>
  );
}
