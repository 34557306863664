import { useMutation } from '@tanstack/react-query';
import {
  type DefaultProfileInput,
  type SwitchProfileInput,
  gqlClient,
  graphql,
  queryClient,
  userKeys,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import set from 'lodash/set';

const switchProfile = graphql(`
  mutation composite_switchProfile($input: SwitchProfileInput!) {
    composite_switchProfile(input: $input) {
      id
    }
  }
`);

const setDefaultProfile = graphql(`
  mutation users_setDefaultProfile($input: DefaultProfileInput!) {
    users_setDefaultProfile {
      success
    }
  }
`);

export function useSwitchProfile() {
  return useMutation({
    mutationFn: async (input: SwitchProfileInput) =>
      gqlClient.request(switchProfile, { input }),
  });
}

export function useSetDefaultProfile() {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: async (input: DefaultProfileInput) =>
      gqlClient.request(setDefaultProfile, { input }),
    onMutate: async ({ profileId }) => {
      queryClient.setQueryData(userKeys.details(), (oldData) => {
        if (!oldData) return oldData;

        set(oldData, 'myAuthDetails.defaultProfileId', profileId);
        return oldData;
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
    onError: async () => {
      await queryClient.invalidateQueries({ queryKey: userKeys.all });
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}
