import {
  Box,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import {
  CalendarEventAttendeeType,
  CalendarEventType,
  SubjectGroupType,
  usePermissions,
} from '@tyro/api';
import {
  Avatar,
  IconButtonWithTooltip,
  useDisclosure,
  usePreferredNameLayout,
  useToast,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  CloseIcon,
  CopyIcon,
  EditIcon,
  ExternalLinkIcon,
  LocationIcon,
  PersonCheckmarkIcon,
  TrashIcon,
  UserGroupTwoIcon,
  UserSwapIcon,
} from '@tyro/icons';
import { SubIcon } from '@tyro/substitution';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';
import type { Attendee } from '../../../@types/calendar';
import type { ExtendedEventInput } from '../../../api/events';
import {
  type ReturnTypeFromUseEventForEdit,
  useCalendarEventsForEdit,
} from '../../../api/events-for-edit';
import { getEventToEdit } from '../../../utils/get-event-to-edit';
import { getPartyName, getPartyPerson } from '../../../utils/get-party-name';
import { sortParticipants } from '../../../utils/sort-participants';
import { DeleteCalendarEventConfirmModal } from './delete-calendar-event-confirm-modal';
import type { FormState } from './edit-event-details-modal/types';

dayjs.extend(LocalizedFormat);

interface CalendarDetailsPopoverProps {
  eventElementRef: HTMLElement | null;
  event: ExtendedEventInput | undefined;
  onClose: () => void;
  onEdit: (eventToEdit: Partial<FormState>) => void;
}

const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 48,
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.primary,
}));

export function FormattedDateAndTime({
  allDay,
  startDateTime,
  endDateTime,
}: {
  allDay: boolean;
  startDateTime: string | Date;
  endDateTime: string | Date;
}) {
  const start = dayjs(startDateTime);
  const end = dayjs(endDateTime);

  if (allDay) {
    return <>{start.format('LL')}</>;
  }

  const dash = (
    <Box
      component="span"
      sx={{
        mx: 0.25,
      }}
    >
      -
    </Box>
  );

  if (start.isSame(end, 'day')) {
    return (
      <>
        {start.format('LL [⋅] LT')} {dash} {end.format('LT')}
      </>
    );
  }

  return (
    <>
      {start.format('LLL')} {dash} {end.format('LLL')}
    </>
  );
}

function getAttendeeAvatarSrc(partyInfo: Attendee['partyInfo']) {
  if (!partyInfo) return undefined;

  switch (partyInfo.__typename) {
    case 'GeneralGroup':
    case 'SubjectGroup':
      return partyInfo.avatarUrl ?? undefined;
    case 'Staff':
    case 'Student':
      return partyInfo.person.avatarUrl ?? undefined;
    default:
      return undefined;
  }
}

export function CalendarDetailsPopover({
  eventElementRef,
  onClose,
  onEdit,
  event,
}: CalendarDetailsPopoverProps) {
  const { t } = useTranslation(['common', 'calendar']);
  const { isTyroUser } = usePermissions();
  const { toast } = useToast();
  const { displayName } = usePreferredNameLayout();
  const [state, copyToClipboard] = useCopyToClipboard();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: openDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();

  const originalEvent = event?.originalEvent;
  const eventId = originalEvent?.eventId ?? 0;

  const { data: eventsForEditData = [] } = useCalendarEventsForEdit(
    {
      eventId: [eventId],
    },
    !!eventId,
  );

  const [eventForEdit] = eventsForEditData;

  const sortedParticipants = sortParticipants(
    (originalEvent?.attendees ??
      []) as ReturnTypeFromUseEventForEdit['attendees'],
  );
  const sortedExclusions = sortParticipants(
    (originalEvent?.exclusions ??
      []) as ReturnTypeFromUseEventForEdit['attendees'],
  );

  const handleEdit = useCallback(() => {
    onEdit(getEventToEdit(eventForEdit, displayName));
  }, [eventForEdit, onEdit, displayName]);

  const attendanceUrl = useMemo(() => {
    const { attendees, type, startTime } = event?.originalEvent || {};
    const eventStartTime = `eventStartTime=${startTime}`;

    if (type === CalendarEventType.Lesson) {
      return `/groups/subject/${
        event?.originalEvent?.lessonInfo?.subjectGroupId ?? ''
      }/attendance?${eventStartTime}`;
    }

    if (type === CalendarEventType.SchoolActivity) {
      return `/school-activity/school-activity/${
        event?.originalEvent?.sourceId ?? ''
      }/attendance?${eventStartTime}`;
    }

    const customGroupsAsAttendees = (attendees || []).filter(
      (attendee) => attendee?.partyInfo?.__typename === 'GeneralGroup',
    );

    if (customGroupsAsAttendees.length === 1) {
      return `/groups/custom/${customGroupsAsAttendees[0].partyId}/attendance?${eventStartTime}`;
    }

    const { partyInfo: subjectGroupPartyInfo } = attendees?.find((attendee) => {
      return attendee.partyInfo?.__typename === 'SubjectGroup';
    }) ?? { partyInfo: undefined };

    if (
      type === CalendarEventType.General &&
      subjectGroupPartyInfo?.__typename === 'SubjectGroup'
    ) {
      const type =
        subjectGroupPartyInfo.subjectGroupType === SubjectGroupType.SupportGroup
          ? 'support'
          : 'subject';
      return `/groups/${type}/${subjectGroupPartyInfo.partyId}/attendance?${eventStartTime}`;
    }

    return '';
  }, [event]);

  useEffect(() => {
    if (state.error) {
      toast(t('common:issueCopyingToClipboard'), {
        variant: 'error',
      });
    } else if (state.value) {
      toast(t('common:copiedToClipboard'));
    }
  }, [state]);

  return (
    <Popover
      open={!!eventElementRef && !!eventForEdit}
      anchorEl={eventElementRef}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          sx: { p: 1, minWidth: 280 },
        },
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        {isTyroUser && (
          <Tooltip title={t('calendar:copyEventId')}>
            <IconButton
              aria-label={t('calendar:copyEventId')}
              size="small"
              onClick={() => copyToClipboard(eventId.toString())}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 24,
                  height: 24,
                }}
              >
                <CopyIcon
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                />
              </Box>
            </IconButton>
          </Tooltip>
        )}
        {attendanceUrl && (
          <Tooltip title={t('calendar:takeAttendance')}>
            <IconButton
              component={Link}
              aria-label={t('calendar:takeAttendance')}
              size="small"
              to={attendanceUrl}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 24,
                  height: 24,
                }}
              >
                <PersonCheckmarkIcon
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                />
              </Box>
            </IconButton>
          </Tooltip>
        )}

        {event?.originalEvent?.type === CalendarEventType.Lesson && (
          <Tooltip title={t('calendar:goToSubjectGroup')}>
            <IconButton
              component={Link}
              aria-label={t('calendar:goToSubjectGroup')}
              size="small"
              to={`/groups/subject/${
                event?.originalEvent?.lessonInfo?.subjectGroupId ?? ''
              }`}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 24,
                  height: 24,
                }}
              >
                <ExternalLinkIcon
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                />
              </Box>
            </IconButton>
          </Tooltip>
        )}

        {event?.editable && (
          <Stack flexDirection="row" alignItems="center">
            <IconButtonWithTooltip
              title={t('common:actions.edit')}
              aria-label={t('common:actions.edit')}
              size="small"
              onClick={handleEdit}
            >
              <EditIcon
                sx={{
                  width: 16,
                  height: 16,
                }}
              />
            </IconButtonWithTooltip>

            <IconButtonWithTooltip
              title={t('common:actions.delete')}
              aria-label={t('common:actions.delete')}
              size="small"
              onClick={openDeleteModal}
            >
              <TrashIcon
                sx={{
                  width: 16,
                  height: 16,
                }}
              />
            </IconButtonWithTooltip>

            <DeleteCalendarEventConfirmModal
              isOpen={isDeleteModalOpen}
              isRecurringEvent={!!eventForEdit?.schedule?.recurrenceRule}
              deleteFromDate={dayjs(event?.start).format('YYYY-MM-DD')}
              event={eventForEdit}
              onClose={closeDeleteModal}
            />
          </Stack>
        )}

        <IconButton
          aria-label={t('common:actions.close')}
          size="small"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" paddingRight={1.5}>
        <IconContainer
          sx={{
            height: 28,
          }}
        >
          <Box
            sx={{
              backgroundColor: event?.borderColor,
              width: 16,
              height: 16,
              borderRadius: 0.75,
            }}
          />
        </IconContainer>
        <Stack>
          <Typography variant="h6" component="h2">
            {event?.title}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            <FormattedDateAndTime
              allDay={Boolean(event?.allDay)}
              startDateTime={event?.start ?? ''}
              endDateTime={event?.end ?? ''}
            />
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          my: 2,
        }}
      >
        <IconContainer>
          <LocationIcon aria-label={t('calendar:room')} />
        </IconContainer>
        <Typography variant="body2" color="text.secondary">
          {event?.room ?? '-'}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{
          mt: 1.5,
          mb: 2,
        }}
      >
        <IconContainer sx={{ height: 30 }}>
          <UserGroupTwoIcon aria-label={t('calendar:participants')} />
        </IconContainer>
        <Stack>
          <Typography component="h3" variant="subtitle2" sx={{ py: 0.5 }}>
            {t('calendar:numberOfParticipants', {
              count: sortedParticipants.length ?? 0,
            })}
          </Typography>
          {sortedParticipants.length > 0 && (
            <Stack spacing={1.25} sx={{ mt: 0.5 }}>
              {sortedParticipants.map(({ partyId, partyInfo, type }) => {
                const partyName = getPartyName(partyInfo, displayName);
                const isSubStaff =
                  event?.isSubstitution &&
                  [
                    CalendarEventAttendeeType.Organiser,
                    CalendarEventAttendeeType.Additional,
                  ].includes(type) &&
                  !eventForEdit?.attendees?.some(
                    (attendee) => attendee.partyId === partyId,
                  );

                return (
                  <Stack
                    direction="row"
                    key={partyId}
                    spacing={1}
                    alignItems="center"
                  >
                    <Avatar
                      name={partyName}
                      src={getAttendeeAvatarSrc(partyInfo)}
                      person={getPartyPerson(partyInfo)}
                      sx={{
                        width: 32,
                        height: 32,
                        fontSize: '0.75rem',
                      }}
                    />
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing={0.75}>
                        <Typography variant="body2" color="text.primary">
                          {partyName}
                        </Typography>
                        {isSubStaff && <SubIcon size="small" />}
                      </Stack>
                      {partyInfo?.__typename && (
                        <Typography variant="caption" color="text.secondary">
                          {t(`calendar:attendeeType.${partyInfo.__typename}`)}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          )}
        </Stack>
      </Stack>
      {sortedExclusions.length > 0 && (
        <Stack
          direction="row"
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          <IconContainer sx={{ height: 30 }}>
            <UserSwapIcon aria-label={t('common:changes')} />
          </IconContainer>
          <Stack>
            <Typography component="h3" variant="subtitle2" sx={{ py: 0.5 }}>
              {t('common:changes')}
            </Typography>
            {sortedExclusions.length > 0 && (
              <Stack spacing={1.25} sx={{ mt: 0.5 }}>
                {sortedExclusions.map(({ partyId, partyInfo }) => {
                  const partyName = getPartyName(partyInfo, displayName);
                  return (
                    <Stack
                      direction="row"
                      key={partyId}
                      spacing={1}
                      alignItems="center"
                    >
                      <Avatar
                        name={partyName}
                        src={getAttendeeAvatarSrc(partyInfo)}
                        person={getPartyPerson(partyInfo)}
                        sx={{
                          width: 32,
                          height: 32,
                          fontSize: '0.75rem',
                        }}
                      />
                      <Stack>
                        <Typography variant="body2" color="text.primary">
                          {partyName}
                        </Typography>
                        {partyInfo?.__typename && (
                          <Typography variant="caption" color="text.secondary">
                            {t(`calendar:attendeeType.${partyInfo.__typename}`)}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Popover>
  );
}
