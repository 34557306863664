import { Box, IconButton, type IconButtonProps } from '@mui/material';
import * as m from 'motion/react-m';
import { forwardRef } from 'react';

const sizeVariants = {
  small: {
    hover: { scale: 1.1 },
    tap: { scale: 0.95 },
  },
  medium: {
    hover: { scale: 1.09 },
    tap: { scale: 0.97 },
  },
  large: {
    hover: { scale: 1.08 },
    tap: { scale: 0.99 },
  },
} as const;

export const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = 'medium', ...other }, ref) => {
    const sizeVariant = sizeVariants[size] || sizeVariants.medium;

    return (
      <Box
        component={m.div}
        whileTap="tap"
        whileHover="hover"
        variants={sizeVariant}
        sx={{
          display: 'inline-flex',
        }}
      >
        <IconButton size={size} ref={ref} {...other}>
          {children}
        </IconButton>
      </Box>
    );
  },
);

if (process.env.NODE_ENV === 'development') {
  IconButtonAnimate.displayName = 'IconButtonAnimate';
}
