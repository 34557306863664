import { Stack, Tooltip, Typography } from '@mui/material';
import type {
  Person,
  Timeline_EventMeta_Absent,
  Timeline_EventMeta_Behaviour,
  Timeline_EventMeta_Late,
  Timeline_EventMeta_Note,
  Timeline_EventMeta_StudentDocs,
  Timeline_TimelineEventId,
} from '@tyro/api';
import { getClassesFromObject } from '@tyro/core';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { forwardRef } from 'react';
import { TimelineItemIcon } from './item-icon';
import { TimelineDashedLine } from './style-components';
import { getEventDateTimes } from './utils';

dayjs.extend(LocalizedFormat);

export interface TimelineItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  event: {
    id: Timeline_TimelineEventId;
    headline: string;
    date: string;
    startDateTime: string;
    endDateTime?: string | null;
    details?: string | null;
    meta:
      | Timeline_EventMeta_Absent
      | Timeline_EventMeta_Behaviour
      | Timeline_EventMeta_Late
      | Timeline_EventMeta_Note
      | Timeline_EventMeta_StudentDocs;
    createdBy?: Pick<
      Person,
      'partyId' | 'title' | 'firstName' | 'lastName' | 'avatarUrl' | 'type'
    >;
  };
  groupBy: 'week' | 'month';
  itemHeight: number;
  additionalDotStyles?: React.CSSProperties;
  showGroupLabels?: boolean;
  'data-index': number;
}

export const TimelineItem = forwardRef<HTMLDivElement, TimelineItemProps>(
  (
    {
      event,
      groupBy,
      itemHeight,
      additionalDotStyles,
      showGroupLabels,
      ...props
    },
    ref,
  ) => {
    const { weekTimestamp, monthTimestamp } = getEventDateTimes(event);

    return (
      <>
        <div
          ref={ref}
          {...props}
          style={{
            ...props.style,
            transform:
              props?.style?.position === 'relative'
                ? 'translateY(0)'
                : props.style?.transform,
          }}
          className={getClassesFromObject({
            'timeline-item': true,
            [props.className ?? '']: !!props.className,
          })}
        >
          <div role="gridcell">
            <Tooltip title={monthTimestamp}>
              <Typography variant="body2" color="text.secondary">
                {groupBy === 'month' ? monthTimestamp : weekTimestamp}
              </Typography>
            </Tooltip>
          </div>
          <div role="gridcell">
            <TimelineDashedLine />
            <TimelineItemIcon type={event.id.entityType!} />
            <Stack sx={{ flex: 1 }}>
              <Typography variant="subtitle1" component="h3">
                {event.headline}
              </Typography>
              {event.details && (
                <Typography variant="body2" color="text.secondary">
                  {event.details}
                </Typography>
              )}
            </Stack>
          </div>
        </div>
        <div
          className="timeline-dot-container"
          role="presentation"
          style={{
            transform: props.style?.transform,
            top: itemHeight / 2 - 7,
            ...additionalDotStyles,
          }}
        >
          <div className="timeline-dot" />
        </div>
      </>
    );
  },
);

if (process.env.NODE_ENV !== 'production') {
  TimelineItem.displayName = 'TimelineItem';
}
