import { Box, Chip, Stack, Tooltip } from '@mui/material';
import { AttendanceCodeType, type Student, usePermissions } from '@tyro/api';
import {
  type AgGridReact,
  type BulkEditedRows,
  type CellClassParams,
  type EditableCallbackParams,
  type GridOptions,
  type ICellRendererParams,
  type NewValueParams,
  RouterLink,
  Table,
  type TableProps,
  type ValueGetterParams,
  type ValueSetterParams,
  getLocaleTimestamp,
  useDebouncedValue,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { StudentAvatar } from '@tyro/people';
import dayjs, { type Dayjs } from 'dayjs';
import set from 'lodash/set';
import { useMemo, useRef, useState } from 'react';
import {
  type ReturnTypeFromUseAttendanceCodes,
  useAttendanceCodes,
} from '../../api/attendance-codes';
import { LightTooltip, RollbookAttendanceValue } from './attendance-value';
import { AttendanceCodeCellEditor } from './code-cell-editor';
import { NoteModal } from './note-modal';
import { RollbookToolbar } from './toolbar';

export type AttendanceRoleBook = {
  studentPartyId: Student['partyId'];
  student: Pick<Student, 'person' | 'extensions'>;
  classGroup?: {
    name: string;
  } | null;
  attendanceByKey: Record<string, string | null>;
  noteByKey: Record<string, string | null>;
};

type OnBulkSave = NonNullable<TableProps<AttendanceRoleBook>['onBulkSave']>;

type AttendanceChange = Record<
  string,
  {
    id: string;
    attendanceCodeId: number | null;
    partyId: number;
    date: string;
    note?: string;
  }
>;

export type RoleBookTableProps = Omit<
  TableProps<AttendanceRoleBook>,
  'onBulkSave' | 'columnDefs'
> & {
  groupId?: number;
  dateRange: [Dayjs, Dayjs];
  isLessonRoleBook?: boolean;
  setDateRange: (newDateRange: [Dayjs, Dayjs]) => void;
  attendanceTimes: Array<{
    date: string;
    times: Array<{
      id: number;
      time?: string;
      name?: string | null;
    }>;
  }>;
  maxWeeks?: number;
  isCellEditable?: (
    data: AttendanceRoleBook | undefined,
    date: string,
    time: {
      id: number;
      time?: string;
      name?: string | null;
    },
  ) => boolean;
  getCellTooltip?: (
    data: AttendanceRoleBook | undefined,
    date: string,
    time: {
      id: number;
      time?: string;
      name?: string | null;
    },
  ) => JSX.Element | string | undefined;
  onBulkSave: (
    attendanceChanges: Array<AttendanceChange[number]>,
  ) => ReturnType<OnBulkSave>;
};

function splitFirstOccurrence(string: string, separator: string) {
  const [first, ...rest] = string.split(separator);

  const remainder = rest.join(separator) || null;

  return [first, remainder];
}

const getAttendanceCountValueGetter =
  (
    codesToMatch: AttendanceCodeType[],
    attendanceCodesMap: Map<string, ReturnTypeFromUseAttendanceCodes>,
  ) =>
  ({ data, node }: ValueGetterParams<AttendanceRoleBook>) => {
    const getMatchedCodes = (rowData: typeof data) =>
      Array.from(Object.values(rowData?.attendanceByKey ?? {})).filter((id) => {
        if (!id) return false;
        const sessionCodeType = attendanceCodesMap.get(id)?.sessionCodeType;

        return sessionCodeType && codesToMatch.includes(sessionCodeType);
      }).length;

    if (node?.groupData !== undefined) {
      return node.childrenAfterFilter?.reduce(
        (acc, child) => acc + getMatchedCodes(child.data),
        0,
      );
    }

    return getMatchedCodes(data);
  };

const bellColors = ['blue', 'default', 'primary'] as const;

const lessonAttendanceCodes = [
  AttendanceCodeType.Present,
  AttendanceCodeType.Late,
  AttendanceCodeType.UnexplainedAbsence,
];

export function RoleBookTable({
  dateRange,
  setDateRange,
  attendanceTimes,
  loading,
  onBulkSave,
  isLessonRoleBook,
  groupId,
  maxWeeks,
  isCellEditable,
  getCellTooltip,
  ...tableProps
}: RoleBookTableProps) {
  const { t } = useTranslation(['common', 'attendance']);
  const { displayName } = usePreferredNameLayout();
  const { isStaffUserWithPermission } = usePermissions();
  const tableRef = useRef<AgGridReact<AttendanceRoleBook>>(null);

  const [view, setView] = useState<'icons' | 'codes'>('icons');

  const [codeFilter, setCodeFilter] = useState<
    ReturnTypeFromUseAttendanceCodes[]
  >([]);

  const {
    value: noteRowAndKey,
    debouncedValue: debouncedNoteRowAndKey,
    setValue: setNoteRowAndKey,
  } = useDebouncedValue<{ rowId: string; noteKey: string } | null>({
    defaultValue: null,
  });

  const codeFilterIds = useMemo(
    () => codeFilter.map(({ id }) => id),
    [codeFilter],
  );

  const { data: attendanceCodes, isLoading: isAttendanceCodesLoading } =
    useAttendanceCodes({});

  const attendanceCodesMap = useMemo(
    () =>
      (attendanceCodes ?? []).reduce((acc, attendanceCode) => {
        if (attendanceCode.active) {
          acc.set(attendanceCode.name, attendanceCode);
        }
        return acc;
      }, new Map<string, ReturnTypeFromUseAttendanceCodes>()),
    [attendanceCodes],
  );

  const columns = useMemo(() => {
    return [
      {
        headerName: t('common:student'),
        field: 'student',
        valueGetter: ({ data }) => displayName(data?.student?.person),
        cellRenderer: ({ data }: ICellRendererParams<AttendanceRoleBook>) => {
          if (!data) return null;
          const { person, extensions } = data.student;

          const name = displayName(person);

          return (
            <Box display="flex" alignItems="center">
              <StudentAvatar
                partyId={person.partyId}
                src={person?.avatarUrl ?? undefined}
                name={name}
                isPriorityStudent={!!extensions?.priority}
                hasSupportPlan={!!extensions?.aen}
                hasMedical={!!extensions?.medical}
                currentGroupId={groupId}
                person={person}
                ContainingButtonProps={{
                  sx: {
                    my: 1,
                    mr: 1.5,
                  },
                }}
              />
              <Stack>
                <RouterLink
                  sx={{ fontWeight: 600, lineHeight: 1.5 }}
                  to={`/people/students/${person.partyId}/attendance`}
                >
                  {name}
                </RouterLink>
              </Stack>
            </Box>
          );
        },
        sortable: true,
        pinned: 'left',
        sort: 'asc',
        lockVisible: true,
      },
      {
        headerName: t('common:class'),
        field: 'classGroup.name',
        enableRowGroup: true,
      },
      {
        colId: 'unexplainedAbsences',
        headerName: t('attendance:uneAbsences'),
        headerTooltip: t('attendance:unexplainedAbsences'),
        valueGetter: getAttendanceCountValueGetter(
          [AttendanceCodeType.UnexplainedAbsence],
          attendanceCodesMap,
        ),
        sortable: true,
        width: 120,
      },
      {
        colId: 'explainedAbsences',
        headerName: t('attendance:expAbsences'),
        headerTooltip: t('attendance:explainedAbsences'),
        valueGetter: getAttendanceCountValueGetter(
          [AttendanceCodeType.ExplainedAbsence],
          attendanceCodesMap,
        ),
        sortable: true,
        hide: true,
        width: 120,
      },
      {
        colId: 'totalAbsences',
        headerName: t('attendance:totalAbs'),
        headerTooltip: t('attendance:totalAbsences'),
        valueGetter: getAttendanceCountValueGetter(
          [
            AttendanceCodeType.ExplainedAbsence,
            AttendanceCodeType.UnexplainedAbsence,
          ],
          attendanceCodesMap,
        ),
        sortable: true,
        hide: true,
        width: 120,
      },
      {
        colId: 'totalPresents',
        headerName: t('attendance:totalPres'),
        headerTooltip: t('attendance:totalPresents'),
        valueGetter: getAttendanceCountValueGetter(
          [AttendanceCodeType.Late, AttendanceCodeType.Present],
          attendanceCodesMap,
        ),
        sortable: true,
        hide: true,
        width: 120,
      },
      {
        colId: 'lateCount',
        headerName: t('attendance:late'),
        valueGetter: getAttendanceCountValueGetter(
          [AttendanceCodeType.Late],
          attendanceCodesMap,
        ),
        sortable: true,
        hide: !isLessonRoleBook,
        width: 120,
      },
      ...attendanceTimes.map(({ date, times }) => ({
        headerName: dayjs(date).format('ddd D'),
        headerClass: [
          'ag-center-aligned-cell',
          'ag-left-cell-border',
          'ag-right-cell-border',
        ],
        children: times?.flatMap((currentTime, index) => {
          const { id, name, time } = currentTime;
          const key = `${date}-${id}`;
          const isFirstElement = index === 0;
          const isLastElement = index === times.length - 1;
          const headerName = name ?? time;
          const tooltip = getLocaleTimestamp(time);

          return [
            {
              field: `attendanceByKey.${key}`,
              headerName: headerName,
              suppressHeaderMenuButton: true,
              suppressColumnsToolPanel: true,
              headerComponent: () => (
                <Chip
                  size="small"
                  color={bellColors[index % bellColors.length]}
                  variant="soft"
                  label={headerName}
                />
              ),
              headerTooltip:
                !time || tooltip === headerName ? undefined : tooltip,
              headerClass: [
                'ag-center-aligned-cell',
                'ag-cell-small-padding',
                isFirstElement ? 'ag-left-cell-border' : null,
                isLastElement ? 'ag-right-cell-border' : null,
              ],
              width: view === 'icons' ? 55 : 80,
              cellStyle: {
                justifyContent: 'center',
                paddingLeft: 0,
                paddingRight: 0,
              },
              cellClass: ({ data }: CellClassParams<AttendanceRoleBook>) => [
                isStaffUserWithPermission('ps:1:attendance:write_attendance') &&
                  (isCellEditable?.(data, date, currentTime) ?? true) &&
                  'ag-editable-cell',
                !isCellEditable?.(data, date, currentTime)
                  ? 'ag-cell-greyed-out'
                  : null,
                isFirstElement ? 'ag-left-cell-border' : null,
                isLastElement ? 'ag-right-cell-border' : null,
              ],
              cellRenderer: ({
                data,
              }: ICellRendererParams<AttendanceRoleBook>) => {
                const codeName = data?.attendanceByKey[key];
                const attendanceCode = attendanceCodesMap.get(codeName ?? '');
                const note = data?.noteByKey[key];
                const customTooltip = getCellTooltip?.(data, date, currentTime);
                const TooltipComponent =
                  typeof customTooltip !== 'string' ? LightTooltip : Tooltip;

                if (
                  !attendanceCode ||
                  attendanceCode.sessionCodeType === AttendanceCodeType.NotTaken
                ) {
                  return customTooltip ? (
                    <TooltipComponent
                      title={customTooltip}
                      followCursor
                      enterDelay={1000}
                    >
                      <Box sx={{ width: '100%', height: '100%' }} />
                    </TooltipComponent>
                  ) : null;
                }
                const {
                  id: codeId,
                  name: code,
                  sessionCodeType,
                } = attendanceCode;

                return (
                  <RollbookAttendanceValue
                    attendanceCodeType={sessionCodeType}
                    code={code}
                    view={view}
                    note={note}
                    customTooltip={customTooltip}
                    includedInFilter={
                      codeFilterIds.length === 0 ||
                      codeFilterIds.includes(codeId)
                    }
                  />
                );
              },
              valueGetter: ({
                data,
              }: ICellRendererParams<AttendanceRoleBook>) => {
                const codeName = data?.attendanceByKey[key];
                const note = data?.noteByKey[key];

                if (!codeName) return null;

                return note ? `${codeName}  ${note}` : codeName;
              },
              valueSetter: ({
                newValue,
                data,
                node,
                isEditCheckCall,
              }: ValueSetterParams<AttendanceRoleBook, string | null>) => {
                if (newValue === 'session-note' && node?.id) {
                  setNoteRowAndKey({
                    rowId: node.id,
                    noteKey: key,
                  });
                  return false;
                }

                if (!newValue) {
                  data.attendanceByKey[key] = null;
                  if (!isEditCheckCall) {
                    node?.setDataValue(`noteByKey.${key}`, null);
                  }
                  return true;
                }

                const [code, note] = splitFirstOccurrence(newValue, '  ');
                const isValidCode = attendanceCodesMap.has(code ?? '');

                if (!isValidCode) return false;

                if (!isEditCheckCall && note) {
                  node?.setDataValue(`noteByKey.${key}`, note);
                }
                data.attendanceByKey[key] = code;
                return true;
              },
              cellEditorSelector: AttendanceCodeCellEditor(
                key,
                t,
                Array.from(attendanceCodesMap.values()).filter((code) =>
                  isLessonRoleBook
                    ? code.visibleForTeacher &&
                      !code.custom &&
                      lessonAttendanceCodes.includes(code.codeType)
                    : true,
                ),
                isLessonRoleBook,
              ),
              editable: ({
                data,
              }: EditableCallbackParams<AttendanceRoleBook>) =>
                isStaffUserWithPermission('ps:1:attendance:write_attendance') &&
                (isCellEditable?.(data, date, currentTime) ?? true),
            },
            {
              field: `noteByKey.${key}`,
              headerName: `${name ?? ''} ${t('attendance:note')}`,
              editable: ({
                data,
              }: EditableCallbackParams<AttendanceRoleBook>) =>
                isStaffUserWithPermission('ps:1:attendance:write_attendance') &&
                (isCellEditable?.(data, date, currentTime) ?? true),
              valueSetter: ({
                newValue,
                data,
              }: ValueSetterParams<AttendanceRoleBook, string | null>) => {
                if (!data.noteByKey[key] && !newValue) return false;

                data.noteByKey[key] = !newValue ? null : newValue;
                return true;
              },
              onCellValueChanged: ({
                api,
                node,
              }: NewValueParams<AttendanceRoleBook>) => {
                if (node) {
                  api.refreshCells({
                    force: true,
                    rowNodes: [node],
                  });
                }
              },
              hide: true,
              suppressColumnsToolPanel: true,
            },
          ];
        }),
      })),
    ] as GridOptions<AttendanceRoleBook>['columnDefs'];
  }, [
    attendanceCodesMap,
    attendanceTimes,
    codeFilterIds,
    displayName,
    isStaffUserWithPermission,
    setNoteRowAndKey,
    t,
    view,
    isLessonRoleBook,
    isCellEditable,
    getCellTooltip,
  ]);

  return (
    <>
      <Table
        {...tableProps}
        ref={tableRef}
        loading={loading || isAttendanceCodesLoading || tableProps.isLoading}
        columnDefs={columns}
        sx={{
          '.ag-cell-greyed-out': {
            backgroundColor: 'slate.100',
          },
          '.ag-cell-small-padding': {
            padding: '0 4px',
          },
        }}
        onBulkSave={(
          data: BulkEditedRows<
            AttendanceRoleBook,
            'attendanceByKey' | 'noteByKey'
          >,
        ) => {
          const attendanceChanges: AttendanceChange = {};

          for (const [studentPartyId, changes] of Object.entries(data)) {
            for (const [changeKey, value] of Object.entries(changes)) {
              const [key, dateAndBellId] = changeKey.split('.') as [
                string,
                string,
              ];
              const [year, month, day, id] = dateAndBellId.split('-');

              const { attendanceByKey, noteByKey } =
                tableProps.rowData.find(
                  (row) => row.studentPartyId === Number(studentPartyId),
                ) || {};

              const studentChangeKey = `${studentPartyId}-${dateAndBellId}`;

              if (!attendanceChanges[studentChangeKey]) {
                const currentAttendanceId = attendanceCodesMap.get(
                  attendanceByKey?.[dateAndBellId] ?? '',
                )?.id;
                const currentNote = noteByKey?.[dateAndBellId];

                attendanceChanges[studentChangeKey] = {
                  attendanceCodeId: currentAttendanceId ?? null,
                  note: currentNote ?? '',
                  id,
                  partyId: Number(studentPartyId),
                  date: `${year}-${month}-${day}`,
                };
              }

              const newValue = value.newValue as unknown as string | null;

              if (key === 'attendanceByKey') {
                const attendanceCodeId =
                  attendanceCodesMap.get(newValue ?? '')?.id ?? null;
                set(
                  attendanceChanges,
                  `${studentChangeKey}.attendanceCodeId`,
                  attendanceCodeId,
                );
              } else if (key === 'noteByKey') {
                set(attendanceChanges, `${studentChangeKey}.note`, newValue);
              }
            }
          }

          return onBulkSave(Object.values(attendanceChanges));
        }}
        toolbar={
          <RollbookToolbar
            dateRange={dateRange}
            setDateRange={setDateRange}
            view={view}
            setView={setView}
            codeFilter={codeFilter}
            setCodeFilter={setCodeFilter}
            maxWeeks={maxWeeks}
          />
        }
        cellSelection={{
          suppressMultiRanges: true,
          handle: {
            mode: 'fill',
            direction: 'xy',
          },
        }}
        defaultColDef={{
          suppressMovable: true,
          menuTabs: ['generalMenuTab'],
          filter: false,
        }}
      />

      <NoteModal
        open={!!noteRowAndKey}
        onClose={() => setNoteRowAndKey(null)}
        tableRef={tableRef}
        noteRowAndKey={noteRowAndKey || debouncedNoteRowAndKey}
      />
    </>
  );
}
