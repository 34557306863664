import { Node as TiptapNode, mergeAttributes } from '@tiptap/core';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import { Color } from '@tiptap/extension-color';
import Focus from '@tiptap/extension-focus';
import FontFamily from '@tiptap/extension-font-family';
import { Heading } from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { TextAlign } from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { type TFunction, useTranslation } from '@tyro/i18n';

import type { FileTransferRiuType } from '@tyro/api';
import {
  Column,
  FourColumnLayout,
  ThreeColumnLayout,
  TwoColumnLayout,
} from '../components/text-editor/extensions/add-column-extension';
import { VariableChipNode } from '../components/text-editor/extensions/variable-chip';
import { ImageResize } from '../utils/tiptap-image-resize';
import { useUploadAndInsertImage } from './use-upload-and-insert-image';

interface ExtensionOptions {
  placeholder?: string;
  t: TFunction<'common'[]>;
}

const TemplateWrapper = TiptapNode.create({
  name: 'templateWrapper',
  group: 'block',
  content: 'block*',
  parseHTML() {
    return [{ tag: 'div.template-wrapper' }];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, {
        class: 'template-wrapper',
      }),
      0,
    ];
  },
  isolating: true,
});

const CustomAvatarSpan = TiptapNode.create({
  name: 'span',
  group: 'inline',
  inline: true,
  content: 'inline*',
  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (node) => {
          const checkForLabel =
            typeof node !== 'string' &&
            node.hasAttribute('label') &&
            Boolean(
              node.getAttribute('label')?.includes('Student Photo') ||
                node.getAttribute('label')?.includes('Tenant Photo'),
            );
          return checkForLabel ? null : false;
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, { class: 'avatar-photo' }),
      0,
    ];
  },
});

const getExtensions = ({ placeholder, t }: ExtensionOptions) => [
  StarterKit.configure({
    heading: false,
    bulletList: {
      itemTypeName: 'listItem',
      keepMarks: false,
      keepAttributes: true,
    },
    hardBreak: {
      keepMarks: true,
    },
    horizontalRule: {
      HTMLAttributes: {
        class: 'tyro-hr',
      },
    },
    orderedList: {
      itemTypeName: 'listItem',
    },
  }),
  BubbleMenu,
  Color,
  Column,
  VariableChipNode,
  Focus.configure({
    className: 'has-focus',
    mode: 'deepest',
  }),
  FontFamily.configure({
    types: ['textStyle'],
  }),
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  Highlight.configure({
    multicolor: true,
  }),
  Link.configure({
    openOnClick: false,
    autolink: false,
    validate: (href) => /^https?:\/\//.test(href),
  }),
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === 'paragraph') {
        return placeholder || t('common:startTyping');
      }
      return placeholder || t('common:startTyping');
    },
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  TextStyle,
  ThreeColumnLayout,
  FourColumnLayout,
  TwoColumnLayout,
  Underline,
  Image.configure({
    inline: true,
  }),
  CustomAvatarSpan,
  ImageResize(t),
];

type useTipTapEditorProps = {
  placeholder?: string;
  extensions?: ReturnType<typeof getExtensions>;
  enableTemplateWrapper?: boolean;
  riuType: FileTransferRiuType;
};

export function useTipTapEditor({
  placeholder = '',
  extensions,
  enableTemplateWrapper,
  riuType,
}: useTipTapEditorProps) {
  const { t } = useTranslation(['common']);

  const { handleDropImageEditor } = useUploadAndInsertImage({ riuType });

  return useEditor({
    extensions: [
      ...getExtensions({ placeholder, t }),
      ...(enableTemplateWrapper ? [TemplateWrapper] : []),
      ...(extensions || []),
    ],
    editorProps: {
      handleDrop: (view, event, _slice, moved) => {
        const [file] = event.dataTransfer?.files || [];

        if (!moved && file) {
          handleDropImageEditor(view, event, file);
          return true;
        }

        return false;
      },
    },
  });
}
