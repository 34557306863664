import { Box, type BoxProps } from '@mui/material';
import { forwardRef } from 'react';

const dateLargeWidth = 160;
const dateSmallWidth = 100;

interface TimelineContainerProps extends BoxProps {
  showActions: boolean;
  cardWidth: number;
}

export const TimelineContainer = forwardRef<
  HTMLDivElement,
  TimelineContainerProps
>(({ children, showActions, cardWidth, ...props }, ref) => {
  const dateWidth = cardWidth > 600 ? dateLargeWidth : dateSmallWidth;
  return (
    <Box
      ref={ref}
      {...props}
      sx={{
        backgroundColor: '#f9fbfd', // 40% slate.100 on white
        p: 2,
        pt: showActions ? 8 : 2,
        flex: 1,
        minHeight: 460,
        overflowY: 'auto',
        contain: 'strict',

        '& .timeline-section-header, & .timeline-item': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          breakInside: 'avoid',
        },
        '& .timeline-section-header': {
          display: 'flex',
          alignItems: 'center',
          height: 38,
          backgroundColor: 'slate.100',
          borderRadius: '19px',
          px: 1.5,
          zIndex: 1,

          '&.start-of-year, &.end-of-year': {
            backgroundColor: 'indigo.100',
            pr: 0.5,
          },
        },
        '& .timeline-item': {
          display: 'flex',
          alignItems: 'center',
          py: 1,
          cursor: 'pointer',
          borderRadius: 1,
          outlineColor: 'indigo.main',
          boxSizing: 'content-box',

          '&.open-in-preview': {
            backgroundColor: 'white',
            outline: '1px solid',
            outlineColor: 'indigo.400',

            '& + .timeline-dot-container > .timeline-dot': {
              backgroundColor: 'indigo.600',
            },
          },

          '&:hover, &:focus': {
            backgroundColor: 'indigo.50',
          },

          '& > div': {
            flex: 1,
          },

          '& > div:nth-of-type(1)': {
            maxWidth: dateWidth,
            pl: 1.5,
          },

          '& > div:nth-of-type(2)': {
            display: 'flex',
            alignItems: 'center',
          },

          '& .timeline-item-icon': {
            width: 32,
            height: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            borderRadius: '50%',
            ml: 2,
            mr: 1.5,

            '& svg': {
              width: 22,
              height: 22,
            },
          },
        },

        '& .timeline-dot-container': {
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: `min(${dateWidth + 4}px, calc(50% - 2px))`,
          zIndex: 4,
          pointerEvents: 'none',
          breakInside: 'avoid',

          '& .timeline-dot': {
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: 'indigo.400',
            border: '2px solid',
            borderColor: 'indigo.50',
          },
        },

        '& .timeline': {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          top: 0,
          height: '100%',
          width: '20px',
          backgroundColor: 'indigo.50',
          border: '1px solid',
          borderColor: 'indigo.100',
          zIndex: 3,
          left: `min(${dateWidth}px, calc(50% - 10px))`,
          borderRadius: '10px',

          '& .inner-timeline': {
            width: 4,
            height: 'calc(100% - 16px)',
            backgroundColor: 'indigo.400',
            my: 1,
            borderRadius: '2px',
          },
        },

        '& .top-item-mask': {
          position: 'sticky',
          top: 0,
          height: 64,
          mt: -8,
          // @ts-expect-error
          backgroundColor: props?.sx?.backgroundColor ?? '#f9fbfd', // 40% slate.100 on white
          transform: 'translateY(-64px)',
          zIndex: 2,
        },
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
});
