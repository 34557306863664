import {
  Core_StaffNameDisplayType,
  PartyPersonType,
  type Person,
  usePersonalSettings,
} from '@tyro/api';
import { useMemo } from 'react';

export type DisplayNamePersonProps =
  | Pick<Person, 'title' | 'firstName' | 'lastName' | 'type'>
  | undefined
  | null;

export enum PreferredNameFormat {
  FirstnameSurname = 'FIRST_NAME_SURNAME',
  SurnameFirstname = 'SURNAME_FIRST_NAME',
}

const displayName = (
  person: DisplayNamePersonProps,
  options?: {
    format?: PreferredNameFormat | Core_StaffNameDisplayType | undefined;
  },
): string => {
  if (!person) {
    return '';
  }

  switch (options?.format) {
    case PreferredNameFormat.FirstnameSurname:
    case Core_StaffNameDisplayType.FirstNameLastName:
      return [person.firstName, person.lastName].filter(Boolean).join(' ');
    case Core_StaffNameDisplayType.Abbreviated:
      return [person.title?.name, person.lastName].filter(Boolean).join(' ');
    default:
      return [person.lastName, person.firstName].filter(Boolean).join(', ');
  }
};

const displayNames = (
  persons: DisplayNamePersonProps[] | undefined | null,
  options?: {
    separator?: string;
    format?: PreferredNameFormat | Core_StaffNameDisplayType | undefined;
    emptyValue?: string;
  },
): string => {
  const { separator = ', ', format, emptyValue = '' } = options || {};
  if (!persons) {
    return emptyValue;
  }
  return (
    persons
      .map((person) => displayName(person, format ? { format } : undefined))
      .filter(Boolean)
      .join(separator) || emptyValue
  );
};

export function sortByDisplayName(
  studentA: DisplayNamePersonProps,
  studentB: DisplayNamePersonProps,
) {
  const nameA = displayName(studentA);
  const nameB = displayName(studentB);

  return nameA.localeCompare(nameB);
}

export function searchDisplayName<T extends DisplayNamePersonProps>(
  options: T[],
  toSearch: string,
) {
  if (!toSearch) return options;

  const splitInputValue = toSearch.toLowerCase().split(' ');

  return options.filter((option) => {
    const studentName = displayName(option).toLowerCase();
    return splitInputValue.every((string) => studentName.includes(string));
  });
}

export function preferredNameLayoutUtils() {
  return {
    displayName,
    displayNames,
    sortByDisplayName,
    searchDisplayName,
  };
}

export function usePreferredNameLayout() {
  const {
    substitution_display_teacherDisplayNameType: teacherDisplayNameType,
  } = usePersonalSettings();

  const displayNameWithSettings = useMemo(
    () =>
      (...args: Parameters<typeof displayName>) => {
        const [person, options] = args;

        if (
          process.env.NODE_ENV !== 'production' &&
          person &&
          !('type' in person)
        ) {
          throw new Error('type is not provided');
        }

        switch (person?.type) {
          case PartyPersonType.Staff:
            return displayName(person, {
              ...options,
              format: teacherDisplayNameType ?? options?.format,
            });
          default:
            return displayName(person, options);
        }
      },
    [teacherDisplayNameType],
  );

  const displayNamesWithSettings = useMemo(
    () =>
      (...args: Parameters<typeof displayNames>) => {
        const [persons, options] = args;

        switch (persons?.[0]?.type) {
          case PartyPersonType.Staff:
            return displayNames(persons, {
              ...options,
              format: teacherDisplayNameType ?? options?.format,
            });
          default:
            return displayNames(persons, options);
        }
      },
    [teacherDisplayNameType],
  );

  return useMemo(
    () => ({
      displayName: displayNameWithSettings,
      displayNames: displayNamesWithSettings,
      sortByDisplayName,
      searchDisplayName,
    }),
    [],
  );
}

export type ReturnTypeDisplayName = ReturnType<
  typeof usePreferredNameLayout
>['displayName'];

export type ReturnTypeDisplayNames = ReturnType<
  typeof usePreferredNameLayout
>['displayNames'];
