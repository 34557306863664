import { Stack, type StackProps, Typography } from '@mui/material';
import type { Colour } from '@tyro/api';
import { Link } from 'react-router-dom';

type NTKContainerProps = StackProps & {
  colour?: Colour | 'indigo';
  icon: React.ReactNode;
  heading: string;
  headingType?: 'soft' | 'contained';
  headerLink: string;
  hasItems: boolean;
  noItemsText?: string;
  onClose?: () => void;
};

export function NTKContainer({
  children,
  sx,
  colour = 'indigo',
  icon,
  heading,
  headingType = 'soft',
  headerLink,
  hasItems,
  noItemsText = 'No items',
  onClose,
  ...props
}: NTKContainerProps) {
  const headingTypeStyles =
    headingType === 'soft'
      ? {
          backgroundColor: `${colour}.50`,
          color: `${colour}.600`,
          border: '2px solid',
          borderColor: `${colour}.500`,
          '&:hover': {
            backgroundColor: `${colour}.100`,
          },
          '&:active': {
            backgroundColor: `${colour}.200`,
          },
        }
      : {
          backgroundColor: `${colour}.500`,
          color: 'white',
          border: '2px solid',
          borderColor: `${colour}.500`,
          '&:hover': {
            backgroundColor: `${colour}.400`,
            borderColor: `${colour}.400`,
          },
          '&:active': {
            backgroundColor: `${colour}.600`,
            borderColor: `${colour}.600`,
          },
        };

  return (
    <Stack
      {...props}
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        p: 1.5,
        pb: hasItems ? 2 : 1.5,
        mt: 1.5,
        border: '2px solid',
        borderColor: `${colour}.600`,
        ...sx,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          component={Link}
          direction="row"
          spacing={1}
          onClick={onClose}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            px: 1,
            py: 0.5,
            borderRadius: 5,
            textDecoration: 'none',
            ...headingTypeStyles,
          }}
          to={headerLink}
        >
          {icon}
          <Typography variant="h6" component="h3">
            {heading}
          </Typography>
        </Stack>

        {!hasItems && (
          <Typography
            component="span"
            variant="subtitle2"
            sx={{
              mr: 1,
            }}
          >
            {noItemsText}
          </Typography>
        )}
      </Stack>
      {hasItems && <Stack>{children}</Stack>}
    </Stack>
  );
}
