import { useQuery } from '@tanstack/react-query';
import { gqlClient, graphql, queryClient } from '@tyro/api';
import { tyroTuitionConfigKeys } from './keys';

const tyroTuitionConfig = graphql(/* GraphQL */ `
  query composite_cmsConfig {
    composite_cmsConfig {
      url
      token
    }
  }
`);

const tyroTuitionConfigQuery = {
  queryKey: tyroTuitionConfigKeys.tyroTuitionConfig(),
  queryFn: async () => gqlClient.request(tyroTuitionConfig),
};

export function getTyroTuitionConfig() {
  return queryClient.fetchQuery(tyroTuitionConfigQuery);
}

export function useTyroTuitionConfig() {
  return useQuery({
    ...tyroTuitionConfigQuery,
    select: ({ composite_cmsConfig }) => composite_cmsConfig,
  });
}
