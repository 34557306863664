import {
  DatePicker as MuiDatePicker,
  type DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import type { Dayjs } from 'dayjs';

import { type TextFieldProps, useTheme } from '@mui/material';

export type DatePickerProps = MuiDatePickerProps<Dayjs> & {
  variant?:
    | TextFieldProps['variant']
    | 'white-filled'
    | 'white-filled-outlined';
};

export function DatePicker({ variant, ...pickerProps }: DatePickerProps) {
  const { spacing, palette } = useTheme();

  const isWhiteFilledVariant =
    variant === 'white-filled' || variant === 'white-filled-outlined';

  return (
    <MuiDatePicker
      {...pickerProps}
      slotProps={{
        textField: {
          ...pickerProps.slotProps?.textField,
          variant: isWhiteFilledVariant ? 'filled' : variant,
          sx: {
            ...pickerProps.slotProps?.textField,
            ...(isWhiteFilledVariant && {
              '& .MuiInputBase-root, & .MuiInputBase-root.Mui-focused': {
                backgroundColor: palette.background.default,
                borderRadius: spacing(1),
              },
              ...(variant === 'white-filled-outlined' && {
                '& .MuiInputBase-root': {
                  border: '1px solid',
                  borderColor: 'divider',
                },
              }),
              '& .MuiInputBase-root:hover': {
                backgroundColor: palette.primary.lighter,
              },
            }),
          },
        },
      }}
    />
  );
}
