import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useAuth, usePermissions, useUser } from '@tyro/api';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from '@tyro/i18n';

import { ActionMenuIconWrapper, Avatar, useDisclosure } from '@tyro/core';
import { GearIcon, LangSwapIcon, UserIcon, UserSwapIcon } from '@tyro/icons';
import { IconButtonAnimate } from '../../icon-button-animate';
import { MenuPopover } from '../../menu-popover';
import { LanguageSwitchModal } from './language-switch-modal';
import { ProfileSwitchModal } from './profile-switch-modal';

export default function AccountPopover() {
  const { t } = useTranslation(['common']);
  const { activeProfile, user } = useUser();
  const { logout } = useAuth();

  const { isStaffUserWithPermission, isTyroUser } = usePermissions();
  const {
    isOpen: isProfileSwitchModalOpen,
    onOpen: openProfileSwitchModal,
    onClose: closeProfileSwitchModal,
  } = useDisclosure();
  const {
    isOpen: isLanguageSwitchModalOpen,
    onOpen: openLanguageSwitchModal,
    onClose: closeLanguageSwitchModal,
  } = useDisclosure();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const profileId = activeProfile?.partyId;
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const options = useMemo(
    () =>
      [
        {
          label: t('common:profile'),
          linkTo: `/people/staff/${profileId ?? 0}/personal`,
          icon: <UserIcon />,
          hasAccess: isStaffUserWithPermission(
            'ps:1:people:view_staff_profile',
          ),
        },
        {
          label: t('common:personalSettings'),
          linkTo: '/settings/personal',
          icon: <GearIcon />,
          hasAccess: isStaffUserWithPermission(
            'ps:1:general_admin:write_personal_settings',
          ),
        },
        {
          label: t('common:switchProfile'),
          onClick: openProfileSwitchModal,
          icon: <UserSwapIcon />,
          hasAccess:
            user && Array.isArray(user?.profiles) && user.profiles.length > 1,
        },
        {
          label: t('common:switchLanguage'),
          onClick: openLanguageSwitchModal,
          icon: <LangSwapIcon />,
          hasAccess: isTyroUser,
        },
      ].filter((option) => option.hasAccess || option.hasAccess === undefined),
    [
      isStaffUserWithPermission,
      t,
      profileId,
      user?.profiles,
      isTyroUser,
      openProfileSwitchModal,
      openLanguageSwitchModal,
    ],
  );

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={activeProfile?.avatarUrl}
          name={activeProfile?.nickName ?? undefined}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {activeProfile?.nickName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {activeProfile?.tenant.name}
          </Typography>
        </Box>

        {options.length > 0 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack sx={{ py: 1 }}>
              {options.map(({ label, onClick, linkTo, icon }) => (
                <MenuItem
                  dense
                  key={label}
                  component={linkTo ? RouterLink : 'button'}
                  onClick={onClick}
                  to={linkTo}
                >
                  <ActionMenuIconWrapper>{icon}</ActionMenuIconWrapper>
                  {label}
                </MenuItem>
              ))}
            </Stack>
          </>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem dense onClick={logout} sx={{ m: 1 }}>
          {t('common:logout')}
        </MenuItem>
      </MenuPopover>
      <ProfileSwitchModal
        open={isProfileSwitchModalOpen}
        onClose={closeProfileSwitchModal}
      />
      <LanguageSwitchModal
        open={isLanguageSwitchModalOpen}
        onClose={closeLanguageSwitchModal}
      />
    </>
  );
}
