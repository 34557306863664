import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type QueryAdditionalOptions,
  type Timeline_HighlightFilter,
  type Timeline_SaveHighlight,
  type Timeline_SaveHighlightComment,
  type Timeline_TimelineFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { peopleKeys } from '../keys';

const studentTimeline = graphql(/* GraphQL */ `
  query timeline_studentTimeline($filter: Timeline_TimelineFilter!) {
    timeline_studentTimeline(filter: $filter) {
      events {
        timelineId {
          entityType
          entityId
          studentPartyId
        }
        startDatetime
        endDatetime
        startDate
        endDate
        headline
        details
        meta {
          __typename
          type
          ... on Timeline_EventMeta_Absent {
            note
            time
          }
          ... on Timeline_EventMeta_Behaviour {
            behaviourType
            tag
            colour
          }
          ... on Timeline_EventMeta_Late {
            note
            time
          }
          ... on Timeline_EventMeta_Late {
            note
            time
          }
          ... on Timeline_EventMeta_Note {
            tag
            colour
          }
          ... on Timeline_EventMeta_StudentDocs {
            link
          }
        }
        studentId
        createdBy {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
    }
  }
`);

const studentTimelineHighlights = graphql(/* GraphQL */ `
  query timeline_studentHighlights($filter:  Timeline_HighlightFilter!) {
    timeline_studentHighlights(filter: $filter) {
      id
      studentPartyId
      title
      highlight
      status
      createdOn
      createdBy {
        partyId
        title {
          id
          name
          nameTextId
        }
        firstName
        lastName
        avatarUrl
        type
      }
      assignedToParties {
        partyId
        ... on Staff {
          person {
            title {
              id
              name
              nameTextId
            }
            firstName
            lastName
            avatarUrl
            type
          }
        }
      }
      comments {
        commentId
        comment
        commenter {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
        createdOn
        likedByParties {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
      events {
        timelineId {
          entityType
          entityId
          studentPartyId
        }
        startDatetime
        endDatetime
        startDate
        endDate
        headline
        details
        meta {
          __typename
          type
          ... on Timeline_EventMeta_Absent {
            note
            time
          }
          ... on Timeline_EventMeta_Behaviour {
            behaviourType
            tag
            colour
          }
          ... on Timeline_EventMeta_Late {
            note
            time
          }
          ... on Timeline_EventMeta_Late {
            note
            time
          }
          ... on Timeline_EventMeta_Note {
            tag
            colour
          }
          ... on Timeline_EventMeta_StudentDocs {
            link
          }
        }
        studentId
        student {
          person {
            partyId
            title {
              id
              name
              nameTextId
            }
            firstName
            lastName
            avatarUrl
            type
          }
        }
      }
    }
  }
`);

const saveTimelineHighlight = graphql(/* GraphQL */ `
  mutation timeline_saveHighlight($input: Timeline_SaveHighlight!) {
    timeline_saveHighlight(input: $input) {
      id
    }
  }
`);

const saveTimelineHighlightComment = graphql(/* GraphQL */ `
  mutation timeline_saveHighlightComment($input: Timeline_SaveHighlightComment!) {
    timeline_saveHighlightComment(input: $input) {
      commentId
    }
  }
`);

const studentTimelineQuery = (filter: Timeline_TimelineFilter) => ({
  queryKey: peopleKeys.students.timeline(filter),
  queryFn: async () => {
    return gqlClient.request(studentTimeline, {
      filter,
    });
  },
});

export function getStudentTimeline(filter: Timeline_TimelineFilter) {
  return queryClient.fetchQuery(studentTimelineQuery(filter));
}

export function useStudentTimeline(
  filter: Timeline_TimelineFilter,
  additionalOptions?: Omit<QueryAdditionalOptions, 'academicNamespaceId'>,
) {
  return useQuery({
    ...studentTimelineQuery(filter),
    ...additionalOptions,
    select: ({ timeline_studentTimeline }) => timeline_studentTimeline,
  });
}

const studentTimelineHighlightsQuery = (filter: Timeline_HighlightFilter) => ({
  queryKey: peopleKeys.students.timelineHighlights(filter),
  queryFn: async () => {
    return gqlClient.request(studentTimelineHighlights, {
      filter,
    });
  },
});

export function getStudentTimelineHighlights(filter: Timeline_HighlightFilter) {
  return queryClient.fetchQuery(studentTimelineHighlightsQuery(filter));
}

export function useStudentTimelineHighlights(filter: Timeline_HighlightFilter) {
  return useQuery({
    ...studentTimelineHighlightsQuery(filter),
    select: ({ timeline_studentHighlights }) =>
      timeline_studentHighlights
        .map((highlight) => ({
          ...highlight,
          events: highlight.events.map((event) => ({
            id: event.timelineId!,
            headline: event.headline,
            date: event.startDate,
            startDateTime: event.startDatetime,
            endDateTime: event.endDatetime,
            details: event.details,
            meta: event.meta!,
          })),
        }))
        .sort((a, b) => b.createdOn.localeCompare(a.createdOn)),
  });
}

export function useSaveTimelineHighlight() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: (input: Timeline_SaveHighlight) =>
      gqlClient.request(saveTimelineHighlight, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: peopleKeys.students.allTimelineHighlights(),
      });
    },
    onError: async () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

export function useSaveTimelineHighlightComment() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: (input: Timeline_SaveHighlightComment) =>
      gqlClient.request(saveTimelineHighlightComment, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: peopleKeys.students.allTimelineHighlights(),
      });
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

export type ReturnTypeFromUseStudentTimeline = UseQueryReturnType<
  typeof useStudentTimeline
>;

export type ReturnTypeFromUseStudentTimelineHighlights = UseQueryReturnType<
  typeof useStudentTimelineHighlights
>[number];
