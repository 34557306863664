import {
  AssessmentType,
  useAcademicNamespace,
  usePermissions,
} from '@tyro/api';
import { ActionMenu, useDisclosure } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  CheckmarkCircleIcon,
  CommentIcon,
  CopyIcon,
  EditCalendarIcon,
  EditIcon,
  EyeIcon,
  PrinterIcon,
  StopIcon,
  VerticalDotsIcon,
} from '@tyro/icons';
import { useNavigate } from 'react-router-dom';
import type { ReturnTypeFromUseAssessments } from '../../api/assessments';
import { usePublishAssessmentBasedOnType } from '../../api/publish-assessments';
import {
  assessmentUrlPathBasedOnType,
  getAssessmentSubjectGroupsLink,
} from '../../utils/get-assessment-subject-groups-link';
import { PublishAssessmentModal } from './publish-assessment-modal';

type AssessmentActionMenuProps = {
  id: ReturnTypeFromUseAssessments['id'];
  publishedFrom?: ReturnTypeFromUseAssessments['publishedFrom'];
  assessmentType: ReturnTypeFromUseAssessments['assessmentType'];
  academicNamespaceId: number;
  canEnterOverallComments: boolean;
};

const writeAssessmentPermission = 'ps:1:assessment:write_assessments';
const writeClassPermission = 'ps:1:assessment:write_class_assessments';

export const AssessmentActionMenu = ({
  id,
  publishedFrom,
  assessmentType,
  academicNamespaceId,
  canEnterOverallComments,
}: AssessmentActionMenuProps) => {
  const { t } = useTranslation(['common', 'assessments']);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { activeAcademicNamespace } = useAcademicNamespace();
  const { isStaffUserWithPermission } = usePermissions();
  const navigate = useNavigate();
  const isActiveAcademicYear =
    academicNamespaceId === activeAcademicNamespace?.academicNamespaceId;

  const assessmentPath = getAssessmentSubjectGroupsLink(
    id,
    assessmentType,
    academicNamespaceId,
  );

  const isTermAssessment = assessmentType === AssessmentType.Term;
  const { unpublish } = usePublishAssessmentBasedOnType(id, isTermAssessment);

  const hasPermissionToWriteAssessment = isStaffUserWithPermission(
    assessmentType === AssessmentType.InClass
      ? writeClassPermission
      : writeAssessmentPermission,
  );

  let hasPermissionToPublishAssessment = false;

  switch (assessmentType) {
    case AssessmentType.Term: {
      hasPermissionToPublishAssessment = isStaffUserWithPermission(
        'ps:1:assessment:term_publish_to_parents',
      );
      break;
    }
    case AssessmentType.StateCba: {
      hasPermissionToPublishAssessment = isStaffUserWithPermission(
        'ps:1:assessment:cba_publish_to_parents',
      );
      break;
    }
    case AssessmentType.InClass: {
      hasPermissionToPublishAssessment = isStaffUserWithPermission(
        'ps:1:assessment:class_publish_to_parents',
      );
      break;
    }
  }

  return (
    <>
      <ActionMenu
        iconOnly
        buttonIcon={<VerticalDotsIcon />}
        menuItems={
          assessmentPath
            ? [
                [
                  {
                    label: t('assessments:actions.view'),
                    icon: <EyeIcon />,
                    navigateTo: assessmentPath,
                  },
                  {
                    label: t('assessments:actions.edit'),
                    icon: <EditIcon />,
                    disabled: !isActiveAcademicYear,
                    disabledTooltip: !isActiveAcademicYear
                      ? t('assessments:editAssessmentsToolTip')
                      : undefined,
                    navigateTo: `${assessmentPath}/edit`,
                    hasAccess: () => hasPermissionToWriteAssessment,
                  },
                  {
                    label: t('assessments:actions.makeOverallComments'),
                    icon: <CommentIcon />,
                    hasAccess: () =>
                      isTermAssessment && canEnterOverallComments,
                    navigateTo: `${assessmentPath}/overall-comments`,
                  },
                  {
                    label: t('common:actions.clone'),
                    icon: <CopyIcon />,
                    hasAccess: () =>
                      isActiveAcademicYear && hasPermissionToWriteAssessment,
                    navigateTo: `/assessments/${assessmentUrlPathBasedOnType[assessmentType]}/create?from=${id}`,
                  },
                  {
                    label: t('assessments:printResults'),
                    icon: <PrinterIcon />,
                    hasAccess: ({ isStaffUserWithPermission }) =>
                      isStaffUserWithPermission(
                        'ps:1:printing_and_exporting:print_assessments',
                      ),
                    onClick: () =>
                      navigate('/printing/assessment', {
                        state: {
                          academicNamespaceId,
                          assessmentId: id,
                        },
                      }),
                  },
                ],
                publishedFrom
                  ? [
                      {
                        label: t('assessments:actions.editPublishDate'),
                        icon: <EditCalendarIcon />,
                        onClick: onOpen,
                        hasAccess: () => hasPermissionToPublishAssessment,
                      },
                      {
                        label: t('assessments:actions.unpublish'),
                        icon: <StopIcon />,
                        onClick: unpublish,
                        hasAccess: () => hasPermissionToPublishAssessment,
                      },
                    ]
                  : [
                      {
                        label: t('assessments:actions.publish'),
                        icon: <CheckmarkCircleIcon />,
                        onClick: onOpen,
                        hasAccess: () => hasPermissionToPublishAssessment,
                      },
                    ],
              ]
            : []
        }
      />
      <PublishAssessmentModal
        assessmentId={id}
        publishedFrom={publishedFrom}
        open={isOpen}
        onClose={onClose}
        isTermAssessment={isTermAssessment}
      />
    </>
  );
};
