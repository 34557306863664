import type { TextFieldProps } from '@mui/material';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { DatePicker, type DatePickerProps } from '../../components/date-picker';

export type RHFDatePickerProps<TField extends FieldValues> = {
  label?: string;
  datePickerProps?: Omit<DatePickerProps, 'onChange' | 'value' | 'renderInput'>;
  controlProps: UseControllerProps<TField>;
  inputProps?: Omit<TextFieldProps, 'variant'> & {
    variant?:
      | TextFieldProps['variant']
      | 'white-filled'
      | 'white-filled-outlined';
  };
};

export const RHFDatePicker = <TField extends FieldValues>({
  label,
  datePickerProps,
  controlProps,
  inputProps,
}: RHFDatePickerProps<TField>) => {
  const {
    field: { ref, onBlur, name, value, onChange },
    fieldState: { error },
  } = useController(controlProps);

  const { variant, ...restInputProps } = inputProps ?? {};

  return (
    <DatePicker
      {...datePickerProps}
      onChange={onChange}
      value={value ?? null}
      label={label}
      inputRef={ref}
      variant={variant}
      slotProps={{
        textField: {
          ...restInputProps,
          onBlur,
          name,
          error: !!error,
          helperText: error?.message,
        },
      }}
    />
  );
};
