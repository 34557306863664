import {
  FormControl,
  type FormControlLabelProps,
  type FormControlProps,
  FormHelperText,
  FormLabel,
  type FormLabelProps,
} from '@mui/material';
import { useId } from 'react';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import {
  RadioTabGroup,
  type RadioTabGroupProps,
  type RadioTabOption,
} from '../radio-tab-group';

export interface RHFRadioTabGroupProps<
  TField extends FieldValues,
  Option extends object | RadioTabOption,
  Key extends keyof Option,
> extends Omit<RadioTabGroupProps<Option, Key>, 'value' | 'onChange'> {
  label?: FormControlLabelProps['label'];
  id?: string;
  controlProps: UseControllerProps<TField>;
  containerProps?: FormControlProps;
  labelProps?: FormLabelProps;
}

export const RHFRadioTabGroup = <
  TField extends FieldValues,
  Option extends object | RadioTabOption,
  Key extends keyof Option,
>({
  id,
  label,
  containerProps,
  labelProps,
  controlProps,
  options,
  optionIdKey = 'value' as Key,
  optionTextKey = 'label' as keyof Option,
  disabled,
}: RHFRadioTabGroupProps<TField, Option, Key>) => {
  const autoId = useId();
  const radioId = id ?? autoId;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController(controlProps);

  return (
    <FormControl
      error={!!error}
      disabled={disabled ?? false}
      {...containerProps}
    >
      {label && (
        <FormLabel id={radioId} {...labelProps}>
          {label}
        </FormLabel>
      )}

      <RadioTabGroup
        value={value}
        onChange={onChange}
        optionIdKey={optionIdKey}
        optionTextKey={optionTextKey}
        options={options}
      />
      {error?.message && (
        <FormHelperText id={`${radioId}-helper`}>
          {error?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};
